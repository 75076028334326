import React, { useContext, useEffect } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function PostTypeSelector(props) {
  const { organization } = useContext(OrganizationContext);
  const { onChange } = props;
  const { visibilityStatuses = [] } = organization || {};

  const formattedLabel = (option) => {
    const { name, description } = option;
    return (
      <div className="select-option-wrapper">
        <div className="select-option-name">{name}</div>
        <div className="select-option-description">{description}</div>
      </div>
    );
  };
  useEffect(() => {
    if (props.value && !props.value.name) {
      onChange({ name: props.value });
    }
  }, []);

  return (
    <>
      <HsSelect
        label="Type"
        placeholder="Type"
        options={[
          {
            label: 'Type',
            options: [
              {
                name: 'Question',
              },
              {
                name: 'Case',
              },
            ],
          },
        ]}
        getOptionLabel={(option) => formattedLabel(option)}
        defaultValue={props.value}
        onChange={onChange}
        isClearable={false}
        {...props}
        // value={caseData.visibilityStatus}
      />
    </>
  );
}

export default PostTypeSelector;
