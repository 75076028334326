import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { strings } from '../../assets/strings/all';
import InviteCommentorModal from './InviteCommentorModal';

function InviteCommentorButton(props) {
  const { cases } = strings;
  const { uuid, commentors } = props;
  const [show, setShow] = useState();

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Button variant="outline-primary" onClick={() => toggleShow()}>
        {cases.invite_commenters}
      </Button>
      {show ? (
        <InviteCommentorModal
          {...props}
          type="archive"
          uuid={uuid}
          commentors={commentors}
          showNotes={true}
          show={show}
          onClose={() => toggleShow()}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default InviteCommentorButton;
