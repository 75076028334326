import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Col, Row, Button, Modal, Form, FormGroup } from 'react-bootstrap';
import { strings } from '../../../assets/strings/all';
import CaseUserListItem from '../../Case/components/CaseUserListItem';
import AutoNameSearchCompleteInput, {
  dummyUser,
} from '../../Form/autocomplete';
import {
  ADD_TEAMMEMBER,
  CREATE_TEAM,
  DELETE_TEAM,
  GET_USER_SEARCH,
  REMOVE_TEAMMEMBER,
} from '../query';
import ShouldDeleteTeamModal from './ShouldDeleteTeam.modal';

const TeamCreationModal = (props) => {
  const { open, close, onFinish, team } = props;
  const [shouldDelete, setShouldDelete] = useState(false);
  const [teamName, setTeamName] = useState(team !== undefined ? team : '');
  const [users, setUsers] = useState(team !== undefined ? team.users : []);
  const [pageLoading, setPageLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [country, setCountry] = useState('');
  const [addUser] = useMutation(ADD_TEAMMEMBER);
  const [removeUser] = useMutation(REMOVE_TEAMMEMBER);
  const [createTeam] = useMutation(CREATE_TEAM);
  const [deleteTeam] = useMutation(DELETE_TEAM);
  const deleteAction = async () => {
    await deleteTeam({
      variables: {
        teamId: team.id,
      },
    });
    onFinish();
    close();
  };
  useEffect(() => {
    if (!team) {
      setTeamName('');
      setUsers([]);
    }
  }, [open]);
  const countries = ['HK', 'IN', 'MY', 'SG', 'TH', 'TW'];
  const positive = async () => {
    if (teamName.length <= 5) {
      alert('team name should be longer than 5 characters');
      return;
    }
    if (users.length <= 1) {
      alert('you should atleast add two users to a team');
      return;
    }
    setPageLoading(true);
    await createTeam({
      variables: {
        memberArr: users.map((user) => user.id),
        name: teamName,
      },
    });
    setPageLoading(false);
    onFinish();
    close();
  };
  useEffect(() => {
    if (team) {
      setTeamName(team.name);
      setUsers(team.users);
    } else {
      setTeamName('');
      setUsers([]);
    }
  }, [team]);
  return (
    <>
      <Modal size="md" show={open} onHide={close} centered>
        <Modal.Header closeButton>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '1.2rem',
            }}
          >
            Create Team Panel
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {team && team.name ? (
            <>
              <label>
                Team Name : <strong>{team.name}</strong>
              </label>
              <div
                style={{
                  backgroundColor: 'slategray',
                  opacity: 0.4,
                  height: '1px',
                  width: '100%',
                  margin: '5px 0',
                }}
              />
            </>
          ) : (
            <>
              <label
                className="red"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Team Name
              </label>
              <div style={{ display: 'flex' }}>
                <input
                  value={teamName}
                  onChange={(ev) => {
                    setTeamName(ev.target.value);
                  }}
                  style={{
                    border: 'slategray',
                    borderStyle: 'solid',
                    padding: '8px',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    margin: '8px 0',
                    flex: 1,
                  }}
                  placeholder="Team name"
                />
              </div>
              <div
                style={{
                  backgroundColor: 'slategray',
                  opacity: 0.4,
                  height: '1px',
                  width: '100%',
                  margin: '5px 0',
                }}
              />
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <label
              style={{
                fontWeight: 'bold',
              }}
            >
              Add Team member
            </label>
            <div style={{ fontSize: '13px', width: '52px' }}>Country</div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <AutoNameSearchCompleteInput
              style={{
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
              }}
              onSelect={async (user) => {
                const foundUser = users.find((u) => user.id === u.id);
                if (!foundUser) {
                  setUsers([...users, user]);
                  if (team) {
                    await addUser({
                      variables: {
                        teamId: team.id,
                        members: [user.id],
                      },
                    });
                  }
                }
              }}
              country={country}
            />
            <select
              style={{
                width: '52px',
              }}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <option value={''}>All</option>
              <>
                {countries
                  .sort((textA, textB) =>
                    textA < textB ? -1 : textA > textB ? 1 : 0,
                  )
                  .map((e, idx) => (
                    <option key={idx} value={e}>
                      {e}
                    </option>
                  ))}
              </>
            </select>
          </div>
          <div
            className="team-panel"
            style={{
              overflowY: 'auto',
              height: '250px',
            }}
          >
            {users.length === 0 ? (
              <div>There are no users in this team yet</div>
            ) : (
              <>
                {users.map((user, idx) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <CaseUserListItem
                        border={false}
                        style={{ flex: 1 }}
                        {...{ user }}
                        newPage={true}
                      />
                      <div className="cnt">
                        <Button
                          onClick={async () => {
                            if (users.length < 3) {
                              alert('teams should consist of at least 2 users');
                              return;
                            }
                            setUsers(users.filter((_, ix) => ix !== idx));
                            if (team) {
                              await removeUser({
                                variables: {
                                  teamId: team.id,
                                  members: [users[idx].id],
                                },
                              });
                            }
                          }}
                          variant="outline-danger"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row style={{ width: '100%' }}>
            <Col>
              <Button
                variant="primary"
                onClick={async () => {
                  if (team === undefined) {
                    await positive();
                  } else {
                    onFinish();
                    close();
                  }
                }}
              >
                {team ? 'Save' : 'Create Team'}
              </Button>
            </Col>
            {team && (
              <Col>
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setShouldDelete(true);
                  }}
                >
                  Delete
                </Button>
              </Col>
            )}
            <Col>
              <Button variant="light" onClick={close}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <ShouldDeleteTeamModal
        open={shouldDelete}
        close={() => {
          setShouldDelete(false);
        }}
        removeTeam={deleteAction}
      />
    </>
  );
};

export default TeamCreationModal;
