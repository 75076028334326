import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { strings } from '../../../assets/strings/all';

const CallButton = (props) => {
  const { title, styleName } = props;
  const [open, setOpen] = useState(false);
  const { cases: caseString } = strings;

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className={styleName ? styleName : ''} onClick={openModal}>
        {title ? title : caseString.call}
      </Button>
      <Modal
        show={open}
        onHide={closeModal}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h6>{caseString.call}</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-description muted-text">
            {caseString.call_header}
          </div>
          <div className="modal-description muted-text">
            {caseString.call_description}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>{caseString.close}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CallButton;
