import React, { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import UserAnalyticsDetails from '../../components/User/UserAnalyticsDetails';
import UserFetch from '../../components/User/UserFetch';
import { UserContext } from '../Context/UserContext';
import Footer from '../Layout/Footer';

function UserProfile(props) {
  const { user } = useContext(UserContext);

  const renderChildView = (props) => {
    let childView = null;
    childView = {
      profile: UserFetch,
      analyticsProfile: UserAnalyticsDetails,
    }[props.view];

    if (!childView) {
      childView = null;
    }

    return childView;
  };

  const ChildView = renderChildView(props);

  console.log('HELLO WORLD');

  return (
    <>
      <Row className="hs-main-content">
        <Col md={12}>
          <Card className="auth-form-card">
            <Card.Body>
              <ChildView {...props} user={user} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default UserProfile;
