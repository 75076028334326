export const strings = {
  type_0: 'Private Group',
  type_2: 'Public Group',
  members: '{number} Members',
  member: '{number} Member',
  group_owner: 'Group Owner',
  leave_group: 'Leave Group',
  leave_group_title: 'Are you sure you want to leave the group?',
  leave_group_subtitle:
    'You won’t be able to post feeds & receive updates from this group',

  delete_group: 'Delete Group',
  share_group: 'Share Group',
  join: 'Join',
  request_to_join: 'Request to Join',
  private_group_message:
    'This group is private. You need to join to view details',
  withdraw: 'Withdraw',
  mutual_connections: 'Mutual connections',
  mutual_groups: 'Mutual groups',
  no_mutual_connections: 'No Mutual Connections',
  group: '{count} Group',
  groups: '{count} Groups',
  view_members: 'View members',
  view_owner: 'View Owner',
  edit_group_details: 'Edit group details',
  create_group: 'Create Group',
  update_group: 'Edit Group',
  group_logo: 'Group Logo',
  group_cover: 'Group Cover',
  upload_logo: 'Upload Logo',
  upload_cover: 'Upload Cover',
  group_name: 'Group Name',
  speciality: 'Speciality',
  tags: 'Tags',
  optional: 'Optional',
  add_tag: 'Add Tag',
  description: 'Description',
  mentions: 'Mentions',
  add_mention: 'Add Mention',
  select_privacy: 'Select Privacy',
  public_group: 'Public Group',
  public_group_desc:
    'Group appears in search results. All HCP Space users can join directly.',
  ms_public_group_desc:
    'Group appears in search results. All Microsite users can join directly.',
  private_group: 'Private Group',
  private_group_desc:
    'Group appears in search results. Members can send request to group owners to join.',
  create: 'Create',
  update: 'Update',
  cancel: 'Cancel',
  errors: {
    avatar_required: 'You need to upload group logo image',
    cover_required: 'You need to upload group cover image',
    name_required: 'Group name is required',
    name_min_length: 'Group name has to be min 3 characters',
    name_max_length: 'Group name cannot be more than 250 characters',
    description_required: 'Group description is required',
    description_min_length: 'Group description has to be min 3 characters',
    description_max_length:
      'Group description cannot be more than 2500 characters',
    speciality_id_required: 'Please choose a speciality',
    cover_uploading: 'Cover is being uploaded',
    avatar_uploading: 'Cover is being uploaded',
  },
  requested: 'Requested',
  invited: 'Invited',
  no_group: 'No Groups',
  all_members: 'All members',
  owner: 'Owner',
  manage: 'Manage',
  remove: 'Remove',
  about_group: 'About Group...',
  delete_group_confirmation: 'Are you sure you want to delete the group?',
};
