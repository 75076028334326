import { useMutation } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { strings } from '../../assets/strings/all';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import HsMentionInput from '../Common/Mention/HsMentionInput';
import CommentInputWrapper from './CommentInputWrapper';
import { CREATE_COMMENT } from './query';

function CommentBox(props) {
  console.log('commentBox', props);
  const {
    isReply,
    entityType,
    entityUuid,
    comment: { content = '', uuid, attachments = [] } = {},
    query = CREATE_COMMENT,
    setShowEdit,
  } = props;
  const { post: postString } = strings;
  const [commentContent, setCommentContent] = useState(content);
  const [attachmentArray, setAttachmentArray] = useState(attachments);
  const [showBox, setShowBox] = useState(true);

  const { setFeedback } = useContext(FeedbackContext);
  const [
    createComment,
    {
      error: createCommentErrors,
      loading: createCommentLoading,
      // data: createCommentData,
    },
  ] = useMutation(query, {
    onCompleted: (data) => {
      // setFeedback({ message: data.comment.message, variant: 'success' });
      setCommentContent('');
      setAttachmentArray([]);
      if (setShowEdit) {
        setShowEdit(false);
      }

      setShowBox(false);

      setTimeout(() => {
        setShowBox(true);
      }, 40);
    },
    update: (cache, { data }) => {
      const { updateData } = props;
      if (updateData && !setShowEdit) {
        let commentListData = cache.readQuery({
          query: updateData.query,
          variables: updateData.variables,
        });

        let newComment = data.comment.comment;
        let newCommentList = [
          newComment,
          ...commentListData.commentList.comments,
        ];

        // if (isReply) {
        //   newCommentList = [
        //     ...commentListData.commentList.comments,
        //     newComment,
        //   ];
        // }

        commentListData = {
          ...commentListData,
          commentList: {
            ...commentListData.commentList,
            comments: newCommentList,
          },
        };

        cache.writeQuery({
          query: updateData.query,
          variables: { ...updateData.variables },
          data: commentListData,
        });
        setCommentContent('');
      }
    },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];
      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
  });

  if (createCommentLoading && !isEmpty(createCommentErrors)) {
    // setErrors({});
  }

  return (
    <Form className="form-article">
      <Form.Group controlId={`comment-box`}>
        <Row className="row-small">
          <Col>
            <HsMentionInput
              caseUuid={props.caseUuid}
              value={commentContent}
              onChange={(value) => {
                setCommentContent(value);
              }}
            />
          </Col>
          <Col xs="auto">
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                const commentData = {
                  content: commentContent,
                  attachments: [],
                };
                if (attachmentArray) {
                  const commentAttachments = [];
                  attachmentArray.forEach((a) => {
                    commentAttachments.push({
                      url: a.url,
                      attachmentType: a.attachmentType,
                    });
                  });

                  commentData['attachments'] = commentAttachments;
                }

                const variables = {
                  commentData,
                };

                if (!props.update) {
                  variables.entityType = entityType;
                  variables.entityUuid = entityUuid;
                } else {
                  variables.uuid = uuid;
                }
                if (props.caseId) variables.caseId = props.caseId;
                if (props.variables.caseId)
                  variables.caseId = props.variables.caseId;
                createComment({
                  variables,
                });
              }}
              disabled={createCommentLoading || isEmpty(commentContent.trim())}
            >
              {isReply ? postString.post_reply : postString.post_comment}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <CommentInputWrapper
              hideText
              type="small"
              onChange={(value) => setAttachmentArray(value)}
              data={{ attachments: attachmentArray }}
              attachedId={
                props.updateData && props.updateData.variables
                  ? props.updateData.variables.entityUuid
                  : ''
              }
            />
          </Col>
        </Row>

        <Col xs={12}>
          <Row
            className="mt-1"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <span
              onClick={() => {
                const mouseEvent = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: false,
                });
                const id =
                  'attachment-comment' +
                  (props.updateData && props.updateData.variables
                    ? props.updateData.variables.entityUuid
                    : '');
                console.log('Searching for ', id);
                document.getElementById(id).dispatchEvent(mouseEvent);
              }}
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                fontSize: '.8rem',
              }}
            >
              <i className="icon-ico-upload-file" /> Add Attachment
            </span>
            <Form.Label className="comment-disclaimer">
              {postString.comment_disclaimer}
            </Form.Label>
          </Row>
        </Col>
      </Form.Group>
    </Form>
  );
}

export default CommentBox;
