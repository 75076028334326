import React from 'react';
import CommentList from '../Comment/CommentList';
import {
  GET_COMMENTS_FOR_MODERATION,
  GET_COMMENTS_COUNT_FOR_MODERATION,
} from '../Comment/query';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import TabHeaderContent from '../Common/TabHeaderContent';
import { strings } from '../../assets/strings/all';

function CommentReviewWrapper(props) {
  const {
    data: { uuid },
  } = props;

  const { review } = strings;

  return (
    <Tab.Container defaultActiveKey={'pending'}>
      <Row className="tab-inner">
        <Col>
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="pending">
                <TabHeaderContent
                  text={review.pending}
                  query={GET_COMMENTS_COUNT_FOR_MODERATION}
                  variables={{
                    uuid,
                    filters: {
                      commentStatusSlug: 'in-review',
                    },
                  }}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="approved">
                <TabHeaderContent
                  text={review.approved}
                  query={GET_COMMENTS_COUNT_FOR_MODERATION}
                  variables={{
                    uuid,
                    filters: {
                      commentStatusSlug: 'open',
                    },
                  }}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="rejected">
                <TabHeaderContent
                  text={review.rejected}
                  query={GET_COMMENTS_COUNT_FOR_MODERATION}
                  variables={{
                    uuid,
                    filters: {
                      commentStatusSlug: 'rejected',
                    },
                  }}
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Tab.Content>
        <Tab.Pane eventKey="pending" unmountOnExit={true} mountOnEnter={true}>
          <CommentList
            {...props}
            query={GET_COMMENTS_FOR_MODERATION}
            variables={{
              uuid,
              limit: 10,
              offset: 0,
              filters: {
                commentStatusSlug: 'in-review',
              },
            }}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="approved" unmountOnExit={true} mountOnEnter={true}>
          <CommentList
            {...props}
            query={GET_COMMENTS_FOR_MODERATION}
            variables={{
              uuid,
              limit: 10,
              offset: 0,
              filters: {
                commentStatusSlug: 'open',
              },
            }}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="rejected" unmountOnExit={true} mountOnEnter={true}>
          <CommentList
            {...props}
            query={GET_COMMENTS_FOR_MODERATION}
            variables={{
              uuid,
              limit: 10,
              offset: 0,
              filters: {
                commentStatusSlug: 'rejected',
              },
            }}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}

export default CommentReviewWrapper;
