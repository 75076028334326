import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AttachmentWrapper from '../Case/components/AttachmentWrapper';
import WebLinkWrapper from '../Case/components/WebLinkWrapper';
import CaseDetailHeader from '../Case/components/CaseDetailHeader';
import HsCaseSection from '../Common/HsCaseSection';
import HsHtmlRenderer from '../Common/HsHtmlRenderer';
import JournalDetailBottomAction from './JournalDetailBottomAction';
import JournalDetailsSideCard from './JournalDetailsSideCard';

function JournalDetails(props) {
  const {
    data: { description },
  } = props;
  return (
    <Row>
      <Col md={9}>
        <Card>
          <Card.Body>
            <CaseDetailHeader {...props} />
            <AttachmentWrapper {...props} />
            <WebLinkWrapper {...props} />
            <HsCaseSection title={'Description'}>
              <HsHtmlRenderer content={description} />
            </HsCaseSection>
            <JournalDetailBottomAction {...props} />

            {/* 
            

            <CaseFieldWrapper {...props} />

            <CaseBottom {...props} />

            <CaseDetailBottomAction {...props} /> */}
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <JournalDetailsSideCard {...props} />
      </Col>
    </Row>
  );
}

export default JournalDetails;
