import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { REPORT_COMMENT } from '../../Comment/query';

export const CommentReportModal = (props) => {
  const { open, close, onFinish, id } = props;
  const countries = ['HK', 'IN', 'MY', 'SG', 'TH', 'TW'];
  const [country, setCountry] = useState('screener');
  const [pvMutation] = useMutation(REPORT_COMMENT);
  const [loading, setLoading] = useState(false);
  return (
    <Modal size="md" show={open} onHide={close} centered>
      <Form
        onSubmit={async (ev) => {
          ev.preventDefault();
          setLoading(true);
          const value = await pvMutation({
            variables: {
              commentId: id,
              note: ev.target[0].value,
              country,
            },
          });
          if (value.data.reportComment.success) {
            onFinish(true);
          }
          setLoading(false);
        }}
      >
        <Modal.Header>
          <h6
            style={{
              color: 'slategray',
            }}
          >
            Report for an adverse event
          </h6>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              type="text"
              name="note"
              placeholder="Why do you think it's an adverse event?"
              className="text-muted"
            >
              {}
            </Form.Control>
            {/* <select
              style={{
                width: '52px',
              }}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <>
                {countries
                  .sort((textA, textB) =>
                    textA < textB ? -1 : textA > textB ? 1 : 0,
                  )
                  .map((e, idx) => (
                    <option key={idx} value={e}>
                      {e}
                    </option>
                  ))}
              </>
            </select> */}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ gap: '14px' }}>
          <Button type="submit" variant="primary" disabled={loading}>
            Submit
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => {
              onFinish(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
