import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { strings } from '../../assets/strings/all';
import HsHtmlRenderer from '../Common/HsHtmlRenderer';
import ProfilePictureInputWrapper from '../Profile/ProfilePictureInputWrapper';

function UserDetails(props) {
  const { user = {} } = props;
  const { profile } = strings;

  const { speciality, about, country } = user;

  return (
    <Row>
      <Col md={3}>
        <ProfilePictureInputWrapper viewOnly={true} {...{ userData: user }} />
      </Col>
      <Col md={9}>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="formLabel">{profile.full_name}</Form.Label>
              <div>{user.fullName}</div>
            </Form.Group>
          </Col>
        </Row>

        {speciality ? (
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.speciality}
                </Form.Label>
                <div> {speciality.name}</div>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {country && (
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">Country</Form.Label>
                <div> {country.name}</div>
              </Form.Group>
            </Col>
          </Row>
        )}
        {/* {profession ? (
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.profession}
                </Form.Label>
                <div> {profession.name}</div>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <></>
        )} */}

        {about ? (
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">{profile.about}</Form.Label>
                {/* <div className="justify-about"> {about}</div> */}
                <div className="justify-about">
                  <HsHtmlRenderer content={about} />
                </div>
              </Form.Group>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
}

export default UserDetails;
