import React, { useContext, useEffect } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function VisibilityStatusSelect(props) {
  const { organization } = useContext(OrganizationContext);
  const { onChange } = props;
  const { visibilityStatuses = [] } = organization || {};

  useEffect(() => {
    if (props.value && !props.value.name) {
      onChange({ name: props.value });
    }
  }, []);

  const formattedLabel = (option) => {
    const { name, description } = option;
    return (
      <div className="select-option-wrapper">
        <div className="select-option-name">{name}</div>
        <div className="select-option-description">{description}</div>
      </div>
    );
  };

  return (
    <>
      <HsSelect
        label="Visibility"
        placeholder="Visibility"
        options={[{ label: 'Visibility', options: visibilityStatuses }]}
        getOptionLabel={(option) => formattedLabel(option)}
        defaultValue={props.value}
        onChange={onChange}
        isClearable={false}
        {...props}
        // value={caseData.visibilityStatus}
      />
    </>
  );
}

export default VisibilityStatusSelect;
