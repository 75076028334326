import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Initialize from '../Helper/Initialize';
import Header from './Header';
import HsBreadcrumb from '../../components/Common/HsBreadcrumb';

export default function Master(props) {
  const [activeSidebar, setActiveSidebar] = useState(false);
  const changeActiveSidebar = () => {
    setActiveSidebar(!activeSidebar);
  };

  const { children } = props;

  return (
    <>
      <Initialize />
      <div
        className={`sdb-body-wrapper auth-body ${props.className || ''} ${
          activeSidebar ? 'sidebar-active' : ''
        }`}
      >
        <div
          id="sidebar"
          className={`left-sidebar ${activeSidebar ? 'active' : ''}`}
        ></div>
        <div className="main-content">
          <Container className="main-container">
            <Header
              changeActiveSidebar={changeActiveSidebar}
              activeSidebar={activeSidebar}
              {...props}
            />
            {props.active === 'completeProfile' ? (
              <></>
            ) : (
              <>
                {!window.location.pathname.includes('users') && (
                  <HsBreadcrumb {...props} />
                )}
              </>
            )}
            <div className="container-wrapper">
              {typeof children === 'object' && children.length > 1
                ? children.map((child) => {
                    return React.cloneElement(child, {
                      ...props,
                    });
                  })
                : React.cloneElement(children, {
                    ...props,
                  })}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
