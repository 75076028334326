import { useMutation } from '@apollo/client';
import { uniqueId } from 'lodash';
import React, { useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { UPDATE_CASE_FIELDS } from '../Case/query';
import HsFormInput from '../Form/HsFormInput';
import HsFormLabel from '../Form/HsFormLabel';
import CaseTemplateEditButton from './CaseTemplateEditButton';
import CaseTemplateFormBottom from './CaseTemplateFormBottom';

function CaseExtraFields(props) {
  const [extraFields, setExtraFields] = useState(props.extraFields || []);
  const { setFeedback } = useContext(FeedbackContext);

  const [updateCaseFields] = useMutation(UPDATE_CASE_FIELDS, {
    onCompleted: (data) => {
      setFeedback({
        message: 'Case Template Updated Successfully',
        variant: 'success',
      });
    },
  });

  const addExtraField = () => {
    setExtraFields([
      ...extraFields,
      ...[
        {
          uuid: uniqueId(),
          fieldType: { slug: 'textarea' },
          name: `Field ${extraFields.length + 1}`,
          slug: 'D3',
          isRequired: false,
          sortOrder: 11,
        },
      ],
    ]);
  };

  const updateExtraField = (extraField) => {
    const oldExtraFields = [...extraFields];
    const newExtraFields = oldExtraFields.map((oef) =>
      oef.uuid === extraField.uuid ? extraField : oef,
    );

    setExtraFields(newExtraFields);
  };

  const removeExtraField = (toBeDeleted, extraFields) => {
    const newFields = extraFields.filter((ef) => {
      return ef.uuid !== toBeDeleted.uuid;
    });

    setExtraFields(newFields);
  };

  // useEffect(() => {
  //   addExtraField();
  // });

  const swapArrayLocs = function (arr, index1, index2) {
    const temp = arr[index1];

    arr[index1] = arr[index2];
    arr[index2] = temp;
    return arr;
  };

  const onDragEnd = (result) => {
    const oldExtraFields = [...extraFields];
    const newExtraFields = swapArrayLocs(
      oldExtraFields,
      result.destination.index,
      result.source.index,
    );

    setExtraFields(newExtraFields);
  };

  const onSubmit = () => {
    const extraFieldsObj = extraFields.map((ef, index) => {
      return {
        name: ef.name,
        fieldTypeSlug: 'textarea',
        uuid: ef.uuid,
        isRequired: false,
        sortOrder: index,
        type: 'extra-details',
      };
    });

    updateCaseFields({ variables: { caseFields: extraFieldsObj } });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
              className="tab-list disabled-tab-list"
            >
              {extraFields.map((ef, index) => {
                // const efIndex = findIndex(caseData.extraDetails, ['name', ef.slug]);
                // const field = efIndex > -1 ? caseData.extraDetails[efIndex] : null;
                return (
                  <Draggable
                    key={ef.uuid}
                    draggableId={ef.uuid}
                    index={index}
                    // isDragDisabled={ef}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // style={getItemStyle(
                        //   snapshot.isDragging,
                        //   provided.draggableProps.style,
                        // )}
                        className={`tab-list-item${
                          snapshot.isDragging ? ' tab-list-item-dragging' : ''
                        }${ef ? ' tab-list-item-disabled' : ''}`}
                      >
                        <Row key={`${ef}-${index}-extra-details`}>
                          <Col xs="auto">
                            <div {...provided.dragHandleProps}>
                              <span className="icon-ico-ham-menu" />
                            </div>
                          </Col>
                          <Col>
                            <Form.Group controlId="formBasicEmail">
                              <Row>
                                <Col>
                                  <HsFormLabel field={ef} />
                                </Col>
                                <Col xs="auto">
                                  <CaseTemplateEditButton
                                    extraField={ef}
                                    updateExtraFieldData={updateExtraField}
                                  />
                                </Col>
                                <Col xs="auto">
                                  <Button
                                    variant="link"
                                    className="text-danger"
                                    onClick={() => {
                                      removeExtraField(ef, extraFields);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Col>
                              </Row>
                              <div name="disabled-field">
                                <HsFormInput
                                  {...{ field: ef }}
                                  name={ef.slug}
                                  height={100}
                                  disabled={true}
                                />
                              </div>{' '}
                              {/* {errors[ef.slug] && (
                              <Form.Control.Feedback type="invalid">
                                {errors[ef.slug]}
                              </Form.Control.Feedback>
                            )} */}
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Draggable>
                  // <Draggable>
                  //   <Row key={`${ef}-${index}-extra-details`}>
                  //     <Col>
                  //       <Form.Group controlId="formBasicEmail">
                  //         <HsFormLabel field={fieldArray[ef.slug]} />
                  //         <HsFormInput
                  //           {...{ field: fieldArray[ef.slug] }}
                  //           name={ef.slug}
                  //         />
                  //         {errors[ef.slug] && (
                  //           <Form.Control.Feedback type="invalid">
                  //             {errors[ef.slug]}
                  //           </Form.Control.Feedback>
                  //         )}
                  //       </Form.Group>
                  //     </Col>
                  //   </Row>
                  // </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        as="div"
        variant="outline-secondary"
        className="w100"
        onClick={() => addExtraField()}
      >
        Add New Field
      </Button>

      <CaseTemplateFormBottom onSubmit={() => onSubmit()} />
    </>
  );
}

export default CaseExtraFields;
