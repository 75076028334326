import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { getLongTime } from '../../common/functions';
import ProfilePictureInputWrapper from '../Profile/ProfilePictureInputWrapper';
import { strings } from '../../assets/strings/all';
import CustomDateTime from '../Common/CustomDateTime';
import { format, parseISO } from 'date-fns';

const UserAnalyticsProfile = (props) => {
  const {
    user,
    avgTimeToClose,
    openCases,
    resolvedCases,
    totalCases,
    totalComments,
    totalReminders,
    lastLogin,
    setVariables,
    variables,
  } = props;

  const { speciality } = user;
  const [dateState, setDateState] = useState({
    startDate: variables.startDate ? variables.startDate : null,
    endDate: variables.endDate ? variables.endDate : null,
  });

  const { users: userString, profile: profileString, analytics } = strings;

  const onDateChange = (date, type) => {
    const customDate = new Date(date);

    setDateState({
      ...dateState,
      [type]: customDate,
    });
  };

  return (
    <>
      <Row>
        <Col md={2}>
          <ProfilePictureInputWrapper viewOnly={true} {...{ userData: user }} />
        </Col>
        <Col md={9}>
          {/* <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profileString.title}
                </Form.Label>
                <div>{user.userTitle ? user.userTitle.name : ''}</div>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profileString.full_name}
                </Form.Label>
                <div>{user.fullName}</div>
              </Form.Group>
            </Col>
          </Row>

          {speciality ? (
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="formLabel">
                    {profileString.speciality}
                  </Form.Label>
                  <div> {speciality.name}</div>
                </Form.Group>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {/* {profession ? (
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="formLabel">
                    {profileString.profession}
                  </Form.Label>
                  <div> {profession.name}</div>
                </Form.Group>
              </Col>
            </Row>
          ) : (
            <></>
          )} */}
        </Col>
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <Col>{userString.content_engagements}</Col>
      </Row>

      <Row>
        <Col>
          <div className="analytics-overview-container">
            <div className="custom-date-box">
              <div className="custom-button-column">
                <span className="custom-button-label">{userString.from}</span>
                <div className="custom-time-column">
                  <CustomDateTime
                    data={
                      !dateState.startDate
                        ? null
                        : new Date(dateState.startDate)
                    }
                    onChange={(e) => {
                      onDateChange(e, 'startDate');
                    }}
                    value={dateState ? dateState['startDate'] : null}
                    name={'startDate'}
                    maxDate={dateState.endDate ? dateState.endDate : new Date()}
                    placeholder={'Select Date'}
                    required={true}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
              </div>
              <div className="custom-button-column">
                <span className="custom-button-label">{userString.to}</span>

                <div className="custom-time-column">
                  <CustomDateTime
                    data={
                      !dateState.endDate ? null : new Date(dateState.endDate)
                    }
                    onChange={(e) => {
                      onDateChange(e, 'endDate');
                    }}
                    value={dateState ? dateState['endDate'] : null}
                    name={'endDate'}
                    minDate={dateState.startDate ? dateState.startDate : null}
                    maxDate={new Date()}
                    placeholder={'Select Date'}
                    required={true}
                    dateFormat="dd-MMM-yyyy"
                  />
                </div>
              </div>
              <Button
                variant="primary"
                disabled={!(dateState.startDate && dateState.endDate)}
                onClick={() => {
                  setVariables({
                    ...variables,
                    startDate: dateState.startDate,
                    endDate: dateState.endDate,
                  });
                }}
              >
                {userString.select}
              </Button>
            </div>
            <Row>
              {/* TOTAL HCP REGISTERED */}
              <Col md={4} className="analytics-card-list-padding">
                <Card>
                  <Card.Body className="analytics-inner-card-body">
                    <Row as="div">
                      <Col className="analytics-card-hsimage-size-padding ">
                        <Row>
                          <Card.Text
                            as="div"
                            className="analytics-inner-card-header"
                          >
                            {analytics.total_cases_questions}
                          </Card.Text>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Card.Text className="analytics-inner-card-members">
                        {strings.formatString(
                          analytics.num_total_cases_questions,
                          {
                            num: totalCases,
                          },
                        )}
                      </Card.Text>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* ACTIVE HCP LIST */}
              <Col md={4}>
                <Card>
                  <Card.Body className="analytics-inner-card-body">
                    <Row as="div">
                      <Col className="analytics-card-hsimage-size-padding ">
                        <Row>
                          <Card.Text
                            as="div"
                            className="analytics-inner-card-header"
                          >
                            {analytics.resolved_cases_questions}
                          </Card.Text>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Card.Text className="analytics-inner-card-members">
                        {resolvedCases}
                      </Card.Text>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* TOTAL CASES */}
              <Col md={4}>
                <Card>
                  <Card.Body className="analytics-inner-card-body">
                    <Row as="div">
                      <Col className="analytics-card-hsimage-size-padding ">
                        <Row>
                          <Card.Text
                            as="div"
                            className="analytics-inner-card-header"
                          >
                            {analytics.total_open_cases}
                          </Card.Text>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Card.Text className="analytics-inner-card-members">
                        {openCases}
                      </Card.Text>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* AVERAGE TIME TO RESOLVE CASE */}
              <Col md={4}>
                <Card>
                  <Card.Body className="analytics-inner-card-body">
                    <Row as="div">
                      <Col className="analytics-card-hsimage-size-padding ">
                        <Row>
                          <Card.Text
                            as="div"
                            className="analytics-inner-card-header"
                          >
                            {analytics.avg_time_case_question_resolve}
                          </Card.Text>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Card.Text className="analytics-inner-card-members">
                        {getLongTime(avgTimeToClose)}
                      </Card.Text>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {user.isExpert ? (
                <>
                  {/* TOTAL COMMENTS */}
                  <Col md={4}>
                    <Card>
                      <Card.Body className="analytics-inner-card-body">
                        <Row as="div">
                          <Col className="analytics-card-hsimage-size-padding ">
                            <Row>
                              <Card.Text
                                as="div"
                                className="analytics-inner-card-header"
                              >
                                {analytics.total_comments}
                              </Card.Text>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Card.Text className="analytics-inner-card-members">
                            {totalComments}
                          </Card.Text>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* TOTAL REMINDERS */}
                  <Col md={4}>
                    <Card>
                      <Card.Body className="analytics-inner-card-body">
                        <Row as="div">
                          <Col className="analytics-card-hsimage-size-padding ">
                            <Row>
                              <Card.Text
                                as="div"
                                className="analytics-inner-card-header"
                              >
                                {analytics.reminders_sent}
                              </Card.Text>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Card.Text className="analytics-inner-card-members">
                            {totalReminders}
                          </Card.Text>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>

                  {/* LAST LOGIN */}
                  <Col md={4}>
                    <Card>
                      <Card.Body className="analytics-inner-card-body">
                        <Row as="div">
                          <Col className="analytics-card-hsimage-size-padding ">
                            <Row>
                              <Card.Text
                                as="div"
                                className="analytics-inner-card-header"
                              >
                                {analytics.last_login}
                              </Card.Text>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Card.Text className="analytics-inner-card-members">
                            {lastLogin
                              ? format(
                                  parseISO(lastLogin),
                                  'dd-MMM-yyyy hh:mm:ss:aa',
                                )
                              : 'No Data'}
                          </Card.Text>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserAnalyticsProfile;
