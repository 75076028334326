import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import HsLoader from './HsLoader';
import HtmlRenderer from './HtmlRenderer/HtmlRenderer';
import { strings } from '../../assets/strings/all';

const HsInfoPopup = (props) => {
  const { popUpStatus, title, content, closePopup } = props;
  const { auth: authString } = strings;

  return (
    <>
      <div className="terms-conditions-wrapper">
        <Modal show={popUpStatus} size="lg" className="confirmation-wrapper">
          <Modal.Title className="terms-conditions-title">{title}</Modal.Title>
          <Modal.Body className="terms-conditons-modal-content marginRemover">
            {content ? (
              <HtmlRenderer content={content} />
            ) : (
              <HsLoader type="initialize" />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={closePopup}>
              {authString.close}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default HsInfoPopup;
