import React from 'react';
import { Alert } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { strings } from '../../../assets/strings/all';
import {
  formatDiagnosisDate,
  formatPublishDateFull,
} from '../../../common/date-functions';
import HsCaseStatusIcon from '../../Common/HsCaseStatusIcon';
import HsCaseType from '../../Common/HsCaseType';
import HsPatientDataBadge from '../../Common/HsPatientDataBadge';
import { useContext } from 'react';

function CaseDetailHeader(props) {
  const { data } = props;
  const {
    title,
    caseStatus,
    caseType,
    patientStage,
    patientAge,
    patientInitalDianosisDate,
    caseId,
    createdAt,
    patientGender,
    visibilityStatus,
    caseStatus: { slug: caseStatusSlug },
    inquiryType,
    statusLog,
    caseTitle,
    race,
  } = data;
  const { action } = strings;

  return (
    <div className="case-top-header">
      {caseStatusSlug === 'rejected' ? (
        <Row>
          <Col>
            <Alert variant="warning">
              <div>Rejection Reason : </div>
              <div>{statusLog ? statusLog.notes : null}</div>
            </Alert>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {caseStatusSlug === 'close' ? (
        <Row>
          <Col>
            <Alert variant="info">
              <div className="resolution-title">{action.close.title}</div>
              <div className="resolution-body">
                {statusLog ? statusLog.notes : null}
              </div>
            </Alert>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {caseStatusSlug === 'archive' ? (
        <Row>
          <Col>
            <Alert variant="info">
              <div className="resolution-title">{action.archive.title}</div>
              <div className="resolution-body">
                {statusLog ? statusLog.notes : null}
              </div>
            </Alert>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row>
        <Col>
          <div className="mb-2">
            <HsCaseStatusIcon caseStatus={caseStatus} />
            <HsCaseType caseType={caseType} />
          </div>
        </Col>
        <Col xs="auto">
          <div className="muted-text muted-text-small">
            Created on {formatPublishDateFull(createdAt)}
          </div>
        </Col>
      </Row>
      <Row
        style={{
          color: '#2cb0e9',
        }}
        className="mb-2"
      >
        <Col>
          <div>{title}</div>
        </Col>
      </Row>
      <Row
        style={{
          color: '#2cb0e9',
        }}
        className="my-2"
      >
        <Col>
          <div
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
            className="mb-2"
          >
            <span>{inquiryType.name}: </span>
            <span>{caseTitle}</span>
          </div>
        </Col>
      </Row>
      <Row className="my-2 helper-text">
        <Col>
          <div>
            <span>{visibilityStatus.name}</span> <span> case/question</span>
          </div>
        </Col>
      </Row>
      {race.name !== 'NA' && (
        <Row className="helper-text">
          <Col>
            <div>
              <span>Ethnicity: </span>
              <span>{race.name}</span>
            </div>
          </Col>
        </Row>
      )}
      <Row className="mb-3 helper-text">
        <Col xs="auto">
          {caseId ? <div className="muted-text">Case ID: {caseId}</div> : <></>}
        </Col>
      </Row>
      <div className="patient-details-section">
        {patientInitalDianosisDate ? (
          <Row className="mb-3">
            <Col className="muted-text muted-text-small">
              Patient initially diagnosed on{' '}
              {formatDiagnosisDate(patientInitalDianosisDate)}
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {patientAge || patientStage || patientGender ? (
          <Row className="mb-3">
            <Col xs="auto">
              <Image src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAABXFBMVEUAAAAAAAAAAIBVVVVAQEAzMzMkSUkgQEA5OTkzM00nOzszM0QwQEArOUcsN0MnO0UvOUIrOUAsOEQrPEEtPEEpO0ApOkItOUEsO0MsOkIrOUErOUMsOkEsOUMsOUIsO0EqOUIqO0ErOkMrOUErOkIrO0IrOkIrOkEqOUMqOkIrO0ErOkMrOkIrOUIqO0EsOkIqOkIrOkIsOUMrO0IrOkIsOkIrOkErOUIrOkIsOkIrO0ErOkIsO0ErOUEqOkMsOkIrOkIrO0MrO0IrOkEsO0IrO0IsOkIrOkMrOkMrOkIrOkIrOkIrOUErOkIrOkErOkIrOkIrOkIrOkIsOkIrOkIrOkIrOkIrOkIrOUIrOkIrOkIrOkIrOkIrOkIrOUErOkIrO0IrOkIrOkIrOkIrOkIrOkIrOkIrOkIrOUIrOkIrOkIrOkIrOkIrOkIrOkIrOkIrOkIrOkIrOkL///+cp3guAAAAcnRSTlMAAQIDBAUHCAkKDQ8QEhcaGyQpLzM4Pj9FRkdIS0xRUlVWWFpgZGVmZ21xcnd4eXt/g4aHiIyNjpCSlJaYnJ2en6GlqKqur7C0ubq9v8LDxcnKzM3O0NLU1dja29zd3uDn6uzv8PHy8/T19vf4+vv8/f4aobgGAAAAAWJLR0RzQQk9zgAAAShJREFUGBmdwWcjQmEYgOH7UAqZ2ZQdoqxjk5M9MyKjbDKKOs///+C8b/gBrot/aU1kUyEDrS/5etxFSWsxZe5JBGVQEtMnEkRLpCpgNecBjIdtg/LDK7SsCQTED9TJADBqe1FSe8CE7QNcHwvA2j1aSKzARD6OMlswAwt2FM2I5sSOV6F4FovyNeXih8fv41eVv5I/LeGgm5KyQLjN4IclIqfVKBUHIrJdjhaUiLczP4MSLfR6hiSENv8AbCZRdg+Bi3W0WAaw0ih3K8DWGVosA1hpHO7CODD3jBbLAFYaR4P0AyNSgxLLAFYaR7s0A13ShLJyC8SvcQxLLdAoPSiRzzpclzs4zDcD8NpjKPVPN+aR3YFj43FMeV9G8++/nHejLEnJJP/wDVn4N4f534u2AAAAAElFTkSuQmCC" />
              {patientGender && patientGender.name !== 'NA' && (
                <HsPatientDataBadge text={patientGender.name} />
              )}
              {patientAge && (
                <HsPatientDataBadge text={`${patientAge} yrs old`} />
              )}
              {patientStage && <HsPatientDataBadge text={patientStage} />}
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CaseDetailHeader;
