import React from 'react';
import { Switch } from 'react-router-dom';
import CreateCase from '../Case/CreateCase';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import CaseView from '../Case/CaseView';
import Dashboard from '../Dashboard/Dashboard';
import ShareForm, { ShareView } from '../../components/CaseForm/shareForm';
import CaseSearch from '../Case/search';

export default function CaseRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={`${match.path}/`}
        component={(props) => (
          <Dashboard
            view="cases"
            viewType="allCases"
            active="cases"
            {...props}
          />
        )}
      />

      <AuthenticatedRoute
        exact
        path={`${match.path}/search`}
        component={(props) => (
          <CaseSearch breadcrumb={true} view="case" type="view" {...props} />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/following`}
        component={(props) => (
          <Dashboard
            view="cases"
            viewType="followingCases"
            active="cases"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/my`}
        component={(props) => (
          <Dashboard
            view="cases"
            viewType="myCases"
            active="cases"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/share/:id`}
        component={(props) => <ShareView {...props} />}
      />

      <AuthenticatedRoute
        exact
        path={`${match.path}/create`}
        component={(props) => (
          <CreateCase view="cases" active="cases" {...props} />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:case_slug`}
        component={(props) => (
          <CaseView
            breadcrumb={true}
            view="case"
            active="cases"
            type="view"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:case_slug/review`}
        component={(props) => (
          <CaseView view="case" active="cases" type="edit" {...props} />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:case_slug/review/comments`}
        component={(props) => (
          <CaseView
            view="case"
            viewType="reviewComments"
            active="cases"
            type="reviewComments"
            {...props}
          />
        )}
      />
      <AuthenticatedRoute
        exact
        path={`${match.path}/:case_slug/edit`}
        component={(props) => (
          <CaseView view="case" active="cases" type="edit" {...props} />
        )}
      />
    </Switch>
  );
}
