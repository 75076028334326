import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PanelMember from './PanelMember';

const PanelData = (props) => {
  const { panel } = props;
  const panelMember = panel.PanelMember;
  const show = panelMember.length === 0 ? false : true;

  return (
    <>
      {show ? (
        <>
          <h6 className="panel-title">{panel.name}</h6>
          <Row
            className="panel-card-container"
            style={{
              gridRowGap: '20px',
            }}
          >
            {panelMember.map((item, idx) => (
              <Col key={idx} xs={6} md={4} lg={4}>
                <PanelMember user={item} />
              </Col>
            ))}
          </Row>
        </>
      ) : null}
    </>
  );
};

export default PanelData;
