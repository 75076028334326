import React from 'react';
import HsSelect from '../../Form/HsSelect';

const HsAccountTypeFilter = (props) => {
  const { onChange, type } = props;
  const allUserTypes = [
    {
      name: 'All',
      slug: 'all',
    },
    {
      name: 'Verified',
      slug: 'verified',
    },
    {
      name: 'Unverified',
      slug: 'unverified',
    },
  ];

  let defaultValue = null;

  if (type !== 'select') {
    defaultValue = allUserTypes[0];
  }

  return (
    <>
      <HsSelect
        label="User Type"
        placeholder="User Type"
        options={[{ label: 'User Type', options: allUserTypes }]}
        // defaultIcon="icon-ico-hide"
        defaultValue={defaultValue}
        onChange={onChange}
        isClearable={false}
        {...props}
        // value={caseData.visibilityStatus}
      />
    </>
  );
};

export default HsAccountTypeFilter;
