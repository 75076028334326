import React, { useContext } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function CountrySelect(props) {
  let {
    organization: { countries = [] },
  } = useContext(OrganizationContext);

  let selectedValue = null;
  let myCountries = countries.slice();

  myCountries.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
  if (myCountries) {
    countries.map((c) => {
      if (c === props.value) {
        selectedValue = c;
      }
      return true;
    });
  }
  return (
    <HsSelect
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.iso3}
      placeholder="Start Typing for suggestions"
      options={myCountries.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })}
      {...props}
      value={selectedValue}
    />
  );
}

export default CountrySelect;
