import gql from 'graphql-tag';

export const ADD_TEAMMEMBER = gql`
  mutation ($teamId: Int!, $members: [Int]!) {
    editTeam(action: ADD, members: $members, teamId: $teamId, name: "") {
      success
    }
  }
`;
export const REMOVE_TEAMMEMBER = gql`
  mutation ($teamId: Int!, $members: [Int]!) {
    editTeam(action: REMOVE, members: $members, teamId: $teamId, name: "") {
      success
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation ($memberArr: [Int]!, $name: String!) {
    createTeam(members: $memberArr, name: $name) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation ($teamId: Int!) {
    removeTeam(teamId: $teamId) {
      success
    }
  }
`;
export const SEARCH_TEAM_BYNAME = gql`
  query ($name: String!) {
    serachTeam(name: $name) {
      id
      name
      users {
        id
        firstName
        lastName
        avatar
        fullName
        username
        speciality {
          name
        }
        association
        about
      }
    }
  }
`;

export const MY_TEAMS = gql`
  query {
    getTeams {
      id
      name
      users {
        id
        firstName
        avatar
        fullName
        username
        speciality {
          name
        }
        association
        about
      }
    }
  }
`;

export const SHARE_CASE_FIELDS = gql`
  query {
    getOrganization {
      sharingFields {
        name
        type
        slug
        sortOrder
        isRequired
        uuid
        fieldType {
          name
          slug
        }
      }
    }
  }
`;
export const SHARE_CASE_REQUEST = gql`
  mutation (
    $caseId: String!
    $fields: [CaseExtraInput]
    $attachments: [AttachmentInput]
  ) {
    shareCase(caseId: $caseId, fields: $fields, attachments: $attachments) {
      success
      case {
        id
        slug
      }
    }
  }
`;
export const GET_USER_SEARCH = gql`
  query (
    $offset: Int
    $limit: Int
    $queryString: String
    $exclude: [String]
    $uuid: String
    $country: String
    $shouldExclude: Boolean
  ) {
    getCaseCoownerSuggestions(
      offset: $offset
      limit: $limit
      queryString: $queryString
      uuid: $uuid
      exclude: $exclude
      country: $country
      shouldExclude: $shouldExclude
    ) {
      users {
        id
        firstName
        lastName
        fullName
        avatar
        username
        speciality {
          name
        }
        association
        about
      }
    }
  }
`;
