import React from 'react';
import { Col, Row } from 'react-bootstrap';
import HsImage from '../Common/HsImage';
import { strings } from '../../assets/strings/all';

const LicenseItem = (props) => {
  const { license } = props;
  const { common } = strings;
  return (
    <>
      <div className="license-border">
        <Row className="license-file">
          <Col md={4}>
            <HsImage style={{ height: '100%' }} src={license.thumbnail} />
          </Col>

          <Col md={8} style={{ textAlign: 'center' }}>
            <div className="muted-text oneLineDes">{license.fileName}</div>
            <div>
              <a
                className="muted-text"
                href={license.url}
                rel="noreferrer"
                target="_blank"
              >
                {common.view}
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LicenseItem;
