import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import HeaderNoAuth from '../../components/Common/HeaderNoAuth';
import HsAlert from '../../components/Common/HsAlert';
import HsImage from '../../components/Common/HsImage';
import NotificationBell from '../../components/Notification/NotificationBell';
import UserProfileDropdown from '../../components/Profile/UserProfileDropdown';
import { FeedbackContext } from '../Context/FeedbackContext';
import { UserContext } from '../Context/UserContext';
import { OrganizationContext } from '../Context/OrganizationContext';
import HeaderMenu from './HeaderMenu';
import CaseSearchComponent from '../../components/Form/autocomplete/caseSearch';
import { useQuery } from '@apollo/client';
import { GET_USER_ROLE } from '../Auth/query';

export default function Header(props) {
  const { user, logout } = useContext(UserContext);
  const { organization: { logo } = {} } = useContext(OrganizationContext);
  const { feedback = {} } = useContext(FeedbackContext);
  if (isEmpty(user)) {
    return <HeaderNoAuth {...props} />;
  }
  const { avatar, active } = user;

  return (
    <>
      {/* {user && user.uuid ? <HsNotificationPop {...props} /> : <></>} */}

      <Navbar
        bg="dark"
        variant="light"
        className="main-content-navbar navbar-header"
        id="header"
      >
        <Row className="navbar-header-wrapper">
          <Col>
            <Nav>
              <Nav.Item as="div">
                <Row>
                  <Col>
                    <Navbar.Brand as={Link} to="/cases" className="nav-link">
                      <HsImage
                        className="header-logo"
                        src={'/api/static/cavi-white.png'}
                      />
                    </Navbar.Brand>
                  </Col>
                </Row>
              </Nav.Item>
            </Nav>
            {!isEmpty(feedback) ? <HsAlert feedback={feedback} /> : <></>}
          </Col>
          <Nav>
            <Nav.Item>
              <CaseSearchComponent />
            </Nav.Item>
          </Nav>
          {props.active === 'completeProfile' ? (
            <>
              <Col xs="auto">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <span
                    onClick={logout}
                    style={{ color: 'white', cursor: 'pointer' }}
                  >
                    Log Out
                  </span>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col xs="auto">
                <HeaderMenu {...props} />
              </Col>
              <Col xs="auto">
                <Nav>
                  {
                    <Nav.Item
                      as="div"
                      className={`notification-menu notification-count ${
                        active === 'notifications' ? 'active' : ''
                      }`}
                    >
                      <>
                        {!isEmpty(user) ? (
                          <NotificationBell user={user} />
                        ) : (
                          <></>
                        )}
                        {/* <HsNotificationPop {...props} /> */}
                      </>
                    </Nav.Item>
                  }
                  <Nav.Item as="div" className="profile-nav-dropdown-wrapper">
                    <NavDropdown
                      title={
                        <div className="profile-image">
                          <HsImage
                            src={
                              avatar ? avatar : '/api/static/placeholder.svg'
                            }
                          />
                        </div>
                      }
                      alignRight
                    >
                      <div>
                        <div className="profile-nav-dropdown ">
                          <UserProfileDropdown />
                        </div>
                      </div>
                    </NavDropdown>
                  </Nav.Item>
                </Nav>
              </Col>
            </>
          )}
        </Row>
      </Navbar>
    </>
  );
}
