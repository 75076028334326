import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { formatCommentDate } from '../../common/date-functions';
import CommentFeedback from './CommentFeedback';
import CommentList from './CommentList';
import Dropdown from 'react-bootstrap/Dropdown';
import { strings } from '../../assets/strings/all';
import DeleteCommentModal from './DeleteCommentModal';
import HsDescription from '../Common/HtmlRenderer/HsDescription';
import CommentBox from './CommentBox';
import { UPDATE_COMMENT } from './query';
import HsGallery from '../Common/HsGallery';
import { hasPermission, userHasPermission } from '../../common/functions';
import permissionList from '../../common/permissions';
import { UserContext } from '../../containers/Context/UserContext';
import HsOutsideAlerter from '../Common/HsOutsideAlerter';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

function CommentItem(props) {
  const { user } = useContext(UserContext);

  const { commentData, isReply, canComment } = props;
  const {
    content,
    likesCount,
    commentsCount,
    createdAt,
    createdBy,
    uuid,
    attachments,
    permissionArray,
    caseStatus,
    statusLog,
    isExpert,
  } = commentData;

  const [showReplies, setShowReplies] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { post } = strings;

  const { DELETE_SELF_COMMENTS, EDIT_SELF_COMMENTS, REVIEW_COMMENTS } =
    permissionList;

  const canDeleteComment = hasPermission(permissionArray, DELETE_SELF_COMMENTS);
  const canEditComment = hasPermission(permissionArray, EDIT_SELF_COMMENTS);
  const canReviewComment = userHasPermission(user, REVIEW_COMMENTS);

  const showOptions = canDeleteComment || canEditComment;

  const toggleCommentBox = () => {
    setShowEdit(false);
    setShowReplies(false);
  };

  const rejectedComment = caseStatus.slug === 'rejected';

  // {
  //   canReviewComment && caseStatusSlug === 'rejected' ? (
  //     'This comment was rejected'
  //   ) : (
  //     <></>
  //   );
  // }
  return (
    <HsOutsideAlerter
      {...{
        onClickOutside: toggleCommentBox,
      }}
    >
      <div className="comment-item-wrapper">
        <div className={`comment-item ${isExpert ? 'expert' : 'not-expert'}`}>
          <Row>
            <Col>
              {rejectedComment ? (
                <Alert variant="warning">
                  <b>{post.reject_comment_title}</b>
                  <div>{statusLog ? statusLog.notes : null}</div>
                </Alert>
              ) : (
                <></>
              )}

              <Row className="row-small">
                <Col xs="auto">
                  <span className="user-image">
                    <Image src={createdBy.avatar} />
                  </span>
                </Col>
                <Col>
                  <Row className="row-small">
                    <Col xs="auto" className="user-name">
                      <Link to={`/users/${createdBy.username}`}>
                        {createdBy.fullName}
                      </Link>
                    </Col>
                    <Col xs="auto">
                      {isExpert ? (
                        <div className="expert-badge"> {post.expert} </div>
                      ) : null}
                    </Col>

                    <Col></Col>
                    <Col xs="auto" className="comment-time">
                      {formatCommentDate(createdAt)}
                    </Col>
                  </Row>
                  <div className="user-speciality">
                    {createdBy.speciality.name}
                  </div>
                </Col>
                {showOptions ? (
                  <Col xs="auto">
                    <Dropdown className="no-caret" alignRight={false}>
                      <Dropdown.Toggle
                        variant="link"
                        id={`${uuid}-comment-options`}
                      >
                        <span className="icon-ico-options" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {canDeleteComment ? (
                          <Dropdown.Item
                            href="#!"
                            className="text-12-medium"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowDeleteModal(true);
                            }}
                          >
                            {isReply ? post.delete_replyy : post.delete_comment}
                          </Dropdown.Item>
                        ) : (
                          <></>
                        )}

                        {canEditComment ? (
                          <Dropdown.Item
                            href="#!"
                            className="text-12-medium"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowEdit(true);
                            }}
                          >
                            {isReply ? post.edit_replyy : post.edit_comment}
                          </Dropdown.Item>
                        ) : (
                          <></>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
          {!showEdit ? (
            <>
              <Row className="comment-content">
                <Col>
                  <HsDescription description={content} />
                </Col>
              </Row>

              <HsGallery attachments={attachments} />
              <CommentFeedback
                {...props}
                {...{
                  setShowReplies,
                  likesCount,
                  commentsCount,
                  isReply,
                  canReviewComment,
                  caseStatus,
                }}
              />
            </>
          ) : (
            <>
              <CommentBox
                {...props}
                comment={commentData}
                query={UPDATE_COMMENT}
                update={true}
                setShowEdit={setShowEdit}
              />
            </>
          )}
        </div>
        {showReplies ? (
          <div className="comment-list-wrapper reply-list-wrapper">
            <CommentList
              caseUuid={props.caseUuid}
              isReply={true}
              entityType="comments"
              entityUuid={uuid}
              variables={{
                entityType: 'comments',
                entityUuid: uuid,
                limit: 5,
                offset: 0,
                caseId: props.variables.caseId,
              }}
              canComment={canComment}
            />
          </div>
        ) : (
          <></>
        )}
        {showDeleteModal ? (
          <DeleteCommentModal
            closeModal={() => setShowDeleteModal(false)}
            isReply={props.isReply}
            show={true}
            variables={{ uuid }}
            fetchMoreCommentsAfterDelete={props.fetchMoreCommentsAfterDelete}
            updateData={() => {}}
            // props.updateData
          />
        ) : (
          <></>
        )}
      </div>
    </HsOutsideAlerter>
  );
}

export default CommentItem;
