import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import HsDropzone from '../Form/HsDropzone';
import HsFileBox from '../Gallery/HsFileBox';

function CommentInputWrapper(props) {
  const {
    data: { attachments = [] },
  } = props;

  const [removedFiles, setRemovedFiles] = useState([]);

  const addToRemovedFiles = (fileKey) => {
    setRemovedFiles([...removedFiles, fileKey]);
  };

  const updateFileObj = (files) => {
    const keys = Object.keys(files);
    const attachments = keys.map((key) => {
      const file = files[key];
      return {
        url: file.url || file.file.preview,
        attachmentType: 'media',
        file,
      };
    });
    props.onChange(attachments);
  };

  return (
    <>
      <HsDropzone
        {...props}
        type="small"
        updateFileObj={updateFileObj}
        addToRemovedFiles={addToRemovedFiles}
        removedFiles={removedFiles}
        acceptedFileFormats="image/jpeg,image/jpg,video/mp4,application/pdf,image/png"
        attachedId={props.attachedId}
      />

      <Row className="attachment-row">
        {attachments &&
          attachments.map((attachment) => (
            <Col md={2} className="attachment-col">
              <div className="attachment-col-wrapper squareWrapper">
                <div className="squareContent">
                  <div className="remove-attachment">
                    <span
                      style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '.8rem',
                        position: 'relative',
                        top: '-1px',
                      }}
                      className="icon-ico-remove"
                      onClick={() => {
                        const fileKey = attachment.file
                          ? attachment.file.file
                            ? attachment.file.file.uuid ||
                              attachment.file.file.url
                            : attachment.file.url
                          : attachment.url;

                        return addToRemovedFiles(fileKey);
                      }}
                    >
                      x
                    </span>
                  </div>
                  {attachment.fileType === 'image' ? (
                    <Image
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                      }}
                      fluid={true}
                      src={attachment.url}
                    />
                  ) : (
                    <HsFileBox file={attachment} type={'thumbnail'} />
                  )}
                </div>
                {attachment.file ? (
                  <ProgressBar
                    now={attachment.file.percentage}
                    label={`${
                      attachment.file.state === 'uploaded'
                        ? 'Uploaded'
                        : `${attachment.file.state} ${attachment.file.percentage}%`
                    }`}
                    variant={
                      attachment.file.state === 'uploaded'
                        ? 'success'
                        : 'primary'
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
}

export default CommentInputWrapper;
