import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { strings } from '../../../assets/strings/all';
import { urlIsValid } from '../../../common/functions';
import { isEqual, find, isEmpty } from 'lodash';
import { useContext } from 'react';
import { FeedbackContext } from '../../../containers/Context/FeedbackContext';

function WebLinkInputWrapper(props) {
  const { setFeedback } = useContext(FeedbackContext);

  const {
    data: { links: dataLinks = [] },
    onChange,
  } = props;

  const [link, setLink] = useState('');
  const [isValidLink, setIsValidLink] = useState(false);
  const [links, setLinks] = useState(dataLinks);

  useEffect(() => {
    const isValidUrl = urlIsValid(link);
    if (isValidUrl !== isValidLink) setIsValidLink(isValidUrl);
  }, [link, isValidLink]);

  useEffect(() => {
    if (!isEqual(dataLinks, links)) onChange(links);
  }, [links, onChange, dataLinks]);

  const addWebLink = () => {
    if (!isEmpty(find(links, { url: link }))) {
      setFeedback({ variant: 'danger', message: 'Link already added' });
      return;
    }

    const pattern = /^https:\/\/.*/;
    let newLinkObj;
    if (pattern.test(link)) {
      newLinkObj = { url: link, attachmentType: 'link' };
    } else {
      newLinkObj = { url: 'https://' + link, attachmentType: 'link' };
    }
    setLinks([...links, newLinkObj]);
    setLink('');
  };

  const removeWebLink = (index) => {
    const newLinkArray = [...links];
    newLinkArray.splice(index, 1);
    setLinks(newLinkArray);
  };

  const { common } = strings;
  return (
    <Form.Group>
      <Form.Label className="formLabel">{common.web_links}</Form.Label>
      <Row>
        <Col>
          <Form.Control
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault(); // Prevent form submission or TextInput value change
                console.log('Enter key is disabled in this input!');
              }
            }}
            onChange={(e) => setLink(e.target.value)}
            value={link}
          />
        </Col>
        <Col xs="auto">
          <Button onClick={() => addWebLink()} disabled={!isValidLink}>
            Add
          </Button>
        </Col>
      </Row>
      <ul className="web-link-list">
        {links &&
          links.map((link, index) => (
            <li className="web-link-list-item">
              <Row className="row-small">
                <Col xs="auto">
                  <div className="remove-attachment">
                    <span
                      onClick={() => removeWebLink(index)}
                      style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '.8rem',
                        position: 'relative',
                        top: '-1px',
                      }}
                      className="icon-ico-remove"
                    >
                      x
                    </span>
                  </div>
                </Col>
                <Col>
                  <a target="_BLANK" href={link.url} rel="noreferrer">
                    {link.url}
                  </a>
                </Col>
              </Row>
            </li>
          ))}
      </ul>
    </Form.Group>
  );
}

export default WebLinkInputWrapper;
