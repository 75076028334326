import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { strings } from '../../assets/strings/all';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import JournalPicker from '../Journal/JournalPicker';
import { ADD_REMOVE_CASE_JOURNALS } from './query';

function AddCaseJournalModal(props) {
  const { show, uuid, onClose, journals: journalsArray = [] } = props;
  const { journals: journalsString, common } = strings;

  const { setFeedback } = useContext(FeedbackContext);

  const [journals, setJournals] = useState(journalsArray);

  const [addRemoveJournal] = useMutation(ADD_REMOVE_CASE_JOURNALS, {
    onCompleted: (data) => {
      setFeedback({ message: data.journals.message, variant: 'success' });
      onClose();
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const journalUuids = [];

            journals.map((journal) => {
              journalUuids.push(journal.uuid);
              return true;
            });

            const variables = { journalUuids, uuid };

            addRemoveJournal({ variables });
          }}
        >
          <Modal.Body>
            <h6>{journalsString.attach_journals}</h6>
            <p className="modal-description muted-text">
              {journalsString.attach_journals_desc}
            </p>

            <JournalPicker
              isMulti={true}
              label=""
              onChange={(e) => setJournals(e)}
              value={journals}
            />
          </Modal.Body>
          <Modal.Footer>
            <Row className="row-small">
              <Col>
                <Button variant="light" onClick={() => onClose()}>
                  {common.cancel}
                </Button>
              </Col>
              <Col>
                <Button
                  // disabled={showNotes && !notes}
                  variant="primary"
                  type="submit"
                >
                  {common.save}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddCaseJournalModal;
