import { Pie } from 'react-chartjs-2/dist';
const beautifulColors = [
  '#FF6B6B',
  '#87CEEB',
  '#E6E6FA',
  '#008000',
  '#800080',
  '#008080',
  '#DAA520',
  '#DA70D6',
  '#40E0D0',
  '#B76E79',
  '#191970',
  '#FFB7C5',
];
function makeColorDarker(hexColor, factor) {
  const strippedHex = hexColor.slice(1); // Remove the '#' symbol
  const r = parseInt(strippedHex.substring(0, 2), 16);
  const g = parseInt(strippedHex.substring(2, 4), 16);
  const b = parseInt(strippedHex.substring(4, 6), 16);

  const newR = Math.round(r * factor);
  const newG = Math.round(g * factor);
  const newB = Math.round(b * factor);

  const clamp = (value) => Math.min(255, Math.max(0, value));

  const darkerHex = `#${clamp(newR).toString(16)}${clamp(newG).toString(
    16,
  )}${clamp(newB).toString(16)}`;
  return darkerHex;
}

const PieChart = (props) => {
  const { source } = props;
  if (source === undefined || source.length === 0)
    return (
      <div>
        <strong>No Data</strong>
      </div>
    );
  const data = {
    labels: source.map((e) => e.name),
    datasets: [
      {
        data: source.map((e) => e.count),
        backgroundColor: beautifulColors,
        hoverBackgroundColor: beautifulColors.map((e) =>
          makeColorDarker(e, 0.4),
        ),
      },
    ],
  };
  const total = source.map((e) => e.count).reduce((acc, val) => acc + val);
  const percentages = source.map((e) => ((e.count / total) * 100).toFixed(2));

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = data.datasets[0];
            const label = data.labels[tooltipItem.dataIndex];
            const value = dataset.data[tooltipItem.dataIndex];
            const percentage = percentages[tooltipItem.dataIndex];
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <>
      <Pie
        style={{
          width: '40vw',
          height: '40vw',
        }}
        data={data}
        options={options}
      />
    </>
  );
};
export default PieChart;
