import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import HsMediaSlider from './HsMediaSlider';

function HsMediaModal(props) {
  const { files, openModal } = props;
  const [showSlider, setShowSlider] = useState(false);

  window.onpopstate = async () => {
    if (openModal) {
      props.setOpenModal(false);
    }
  };

  // const images = files.map((f) => {
  //   return { original: f.url, thumbnail: f.url };
  // });

  return (
    <>
      <Modal
        size={'xl'}
        show={openModal}
        onEntered={(e) => {
          setShowSlider(true);
        }}
        onHide={() => props.setOpenModal(false)}
        className="full-modal media-modal"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title as="div">
            {/* <HsImage className="avatarImage mr-3" src={user.avatar_image} />
            <span className="user-name">{user.full_name}</span> */}
            <label
              className="icon-ico-remove close"
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                fontSize: '.9rem',
                position: 'relative',
                top: '-1px',
                left: '1px',
              }}
              onClick={() => props.setOpenModal(false)}
            >
              x
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="media-modal-body">
          {showSlider && (
            <HsMediaSlider
              files={files}
              type="all"
              microsite={props.microsite}
            />
          )}
          {/* <ImageGallery
            items={images}
            thumbnailPosition={'bottom'}
            useBrowserFullscreen={false}
          /> */}
        </Modal.Body>
        {files.length > 0 && files[0].downloadable === true ? (
          <Modal.Footer></Modal.Footer>
        ) : (
          ''
        )}
      </Modal>
    </>
  );
}

export default HsMediaModal;
