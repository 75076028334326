import { isEmpty } from 'lodash';

export const verifyJournalInputData = (journaleData, fieldArray) => {
  const errors = {};

  delete errors['title'];
  if (isEmpty(journaleData.title)) {
    const error = `${`Title`} is Required`;
    errors['title'] = error;
  }

  delete errors['visibilityStatus'];
  if (isEmpty(journaleData.visibilityStatus)) {
    const error = `${`Visibility Status`} is Required`;
    errors['visibilityStatus'] = error;
  }

  delete errors['caseType'];
  if (isEmpty(journaleData.caseType)) {
    const error = `${`Journal Type`} is Required`;
    errors['caseType'] = error;
  }

  delete errors['description'];
  if (isEmpty(journaleData.description)) {
    const error = `${`Description`} is Required`;
    errors['description'] = error;
  }

  return errors;
};
