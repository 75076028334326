import { useMutation } from '@apollo/client';
import { isEmpty, pick } from 'lodash';
import React, { useContext, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { OrganizationContext } from '../../containers/Context/OrganizationContext';
import AttachmentInputWrapper from '../CaseForm/components/AttachmentInputWrapper';
import CaseTitle from '../CaseForm/components/CaseTitle';
import WebLinkInputWrapper from '../CaseForm/components/WebLinkInputWrapper';
import JournalTypeFilter from '../Common/Filter/CaseTypeFilter';
import VisibilityStatusSelect from '../Common/Filter/VisibilityStatusSelect';
import HsFormInput from '../Form/HsFormInput';
import { CREATE_JOURNAL, journalInput, UPDATE_JOURNAL } from '../Journal/query';
import JournalFormBottomAction from '../JournalForm/JournalFormBottomAction';
import { verifyJournalInputData } from './functions';

function JournalForm(props) {
  const { organization } = useContext(OrganizationContext);
  const { setFeedback } = useContext(FeedbackContext);

  const { journalFields = [] } = organization;

  const { type } = props;
  const initialJournalData = { ...props.journalData };
  const [journalData, setJournalData] = useState(initialJournalData);
  const [numClick, setNumClick] = useState(0);

  const [errors, setErrors] = useState({});
  const history = useHistory();

  const [
    createJournal,
    {
      // error: createJournalErrors,
      loading: createJournalLoading,
      // data: createJournalData,
    },
  ] = useMutation(type === 'edit' ? UPDATE_JOURNAL : CREATE_JOURNAL, {
    onCompleted: (data) => {
      history.push(`/journals/${data.journal.journal.slug}`);
    },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
  });

  if (!organization) return <></>;

  const updateJournalData = (name, value) => {
    const newJournalData = { ...journalData, [name]: value };
    setJournalData(newJournalData);

    if (type === 'edit' || numClick > 0) {
      const errorObj = verifyJournalInputData(newJournalData);

      setErrors(errorObj);
      if (!isEmpty(errorObj)) {
        return false;
      }
    }
  };
  const saveOrPublishJournal = (statusSlug) => {
    if (numClick === 0) setNumClick(1);
    let journalInputData = { ...journalData };

    journalInputData.caseStatusSlug = statusSlug;

    const errors = verifyJournalInputData(journalInputData);

    if (!isEmpty(errors)) {
      setErrors(errors);
      return false;
    }

    if (journalData['visibilityStatus']) {
      journalInputData.visibilityStatusSlug =
        journalData['visibilityStatus'].slug;
      delete journalInputData['visibilityStatus'];
    }

    if (journalData['journalTitle']) {
      journalInputData.title = journalData.journalTitle;
      delete journalInputData['journalTitle'];
    }

    if (journalData['caseType']) {
      journalInputData.caseTypeSlug = journalData['caseType'].slug;
      delete journalInputData['caseType'];
    }

    if (journalData['attachments']) {
      const journalAttachments = [];
      journalInputData['attachments'].forEach((a) => {
        journalAttachments.push({
          url: a.url,
          attachmentType: a.attachmentType,
        });
      });

      journalInputData['attachments'] = journalAttachments;
    }

    if (journalData['links']) {
      const journalLinks = [];
      journalInputData['links'].forEach((a) => {
        journalLinks.push({ url: a.url, attachmentType: a.attachmentType });
      });

      journalInputData['links'] = journalLinks;
    }

    const journalFinalInput = pick(journalInputData, journalInput);

    const variables = { journalData: journalFinalInput };

    if (journalData.uuid) {
      variables.uuid = journalData.uuid;
    }

    createJournal({ variables });
  };

  const hasJournalFields = (field) => {
    return true;
  };

  // if (type === 'edit') {
  //   const { EDIT_MY_JOURNALS_BASIC_DETAILS } = permissionList;
  //   const { permissionArray } = journalData;
  //   const hasEditPermission = hasPermission(
  //     permissionArray,
  //     EDIT_MY_JOURNALS_BASIC_DETAILS,
  //   );

  //   if (!hasEditPermission) {
  //     setFeedback({ variant: 'danger', message: 'You are not allowed' });
  //     history.push(`/journals/${journalData.slug}`);
  //   }
  // } else {
  //   const { permissionArray } = user;

  //   const { CREATE_JOURNALS } = permissionList;

  //   const hasCreatePermission = hasPermission(permissionArray, CREATE_JOURNALS);
  //   if (!hasCreatePermission) {
  //     setFeedback({ variant: 'danger', message: 'You are not allowed' });
  //     history.push(`/journals`);
  //   }
  // }

  return (
    <Card>
      <Card.Body>
        <Form className="form-article">
          {hasJournalFields('journalTitle') ? (
            <Row className="case-title-wrapper">
              <Col md={8}>
                <CaseTitle
                  {...{ journalFields }}
                  type="journal"
                  data={journalData}
                  onChange={(e) => updateJournalData('title', e.target.value)}
                />
                {errors['title'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['title']}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className="journal-detail-wrapper">
            {hasJournalFields('visibilityStatus') ? (
              <Col md={3}>
                <Form.Group controlId="formBasicEmail">
                  <VisibilityStatusSelect
                    onChange={(e) => updateJournalData('visibilityStatus', e)}
                    value={journalData.visibilityStatus}
                  />
                  {errors['visibilityStatus'] && (
                    <Form.Control.Feedback type="invalid">
                      {errors['visibilityStatus']}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            ) : (
              <></>
            )}

            {hasJournalFields('caseType') ? (
              <Col md={3}>
                <Form.Group controlId="formBasicEmail">
                  <JournalTypeFilter
                    onChange={(e) => updateJournalData('caseType', e)}
                    value={journalData.caseType}
                    type="select"
                  />
                  {errors['caseType'] && (
                    <Form.Control.Feedback type="invalid">
                      {errors['caseType']}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            ) : (
              <></>
            )}
          </Row>

          <Row>
            <Col>
              {journalData.visibilityStatus &&
              journalData.visibilityStatus.slug === 'public' ? (
                <Row>
                  <Col>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Check
                        className="hs-check muted-text"
                        type="checkbox"
                        onChange={(e) =>
                          updateJournalData(
                            'isModerationEnabled',
                            e.target.checked,
                          )
                        }
                        id="isModerationEnabled"
                        label="Enable Moderation"
                        checked={journalData.isModerationEnabled}
                        custom={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <AttachmentInputWrapper
                  {...{ data: journalData }}
                  onChange={(value) => updateJournalData('attachments', value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <WebLinkInputWrapper
                {...{ data: journalData }}
                onChange={(value) => updateJournalData('links', value)}
              />
            </Col>
          </Row>

          <Row>
            {hasJournalFields('description') ? (
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="formLabel">Description</Form.Label>
                  <HsFormInput
                    {...{
                      field: { fieldType: { slug: 'textarea' } },
                      name: 'description',
                    }}
                    onChange={(name, value) => {
                      updateJournalData('description', value);
                    }}
                    intitValue={journalData.description}
                  />
                  {errors['description'] && (
                    <Form.Control.Feedback type="invalid">
                      {errors['description']}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Form>
      </Card.Body>
      <JournalFormBottomAction
        journalData={journalData}
        saveOrPublishJournal={saveOrPublishJournal}
        errors={errors}
        loading={createJournalLoading}
      />
    </Card>
  );
}

export default JournalForm;
