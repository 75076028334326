import React from 'react';
import { Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { forceRelative } from '../../common/functions';

const PanelMember = (props) => {
  const { user } = props;

  return (
    <div className="panel-card">
      <div className="panel-member-container">
        <div className="panel-member-avatar">
          <Image
            className="image"
            fluid
            roundedCircle
            src={
              user.avatar
                ? forceRelative(user.avatar)
                : '/api/static/placeholder.svg'
            }
          />
        </div>

        <Col className="panel-member-body">
          <div className="title">
            <Link to={`/users/${user.username}`}>
              {user.fullName || user.email}
            </Link>
          </div>

          <div className="subtitle muted-text">
            {user.speciality ? user.speciality.name : null}
          </div>
          <div className="subtitle muted-text">
            {user.association ? user.association : null}
          </div>
        </Col>
      </div>
      <div>
        {user.about && (
          <div className="mt-1  muted-text">
            {user.about.slice(0, 70)}
            <Link className="linker" to={`/users/${user.username}`}>
              {' '}
              Read more...
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default PanelMember;
