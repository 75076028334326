export const strings = {
  presenter_access:
    'You are logged in as a presenter.Please {link} to open the webinar in new screen to avail microphone and camera access.',
  webinar: 'Webinar',
  webinars_by: "Webinar's by",
  presenter_details: "Presenter's Details",
  ongoing: 'Ongoing',
  live_now: 'Live now',
  live_webinars: 'Live Webinars',
  upcoming_webinars: 'Upcoming Webinars',
  recommended_webinars: 'Recommended Webinars',
  previous_webinars: 'Previous Webinars',
  starts_in: 'Starts in',
  no_webinars: 'No webinars',
  join_webinar: 'Join Webinar',
  by_clicking: 'By Clicking Register you agree to',
  tnc: 'Terms and Conditions',
  no_webinars_message: 'No live webinars to show',
  create_webinar: 'Create a Webinar',
  new_webinar: 'New Webinar',
  edit_webinar: 'Edit Webinar',
  cover_image: 'Cover Image',
  presenter: 'Presenter(s)',
  webinar_title: 'Webinar Title',
  title_placeholder: 'Enter Title',
  timezone: 'Time Zone',
  duration: 'Duration (in minutes)',
  webinar_type: 'Webinar Type',
  location: 'Webinar Location',
  address: 'Enter Address',
  start_time: 'Start Time',
  delete_webinar: 'Delete Webinar',
  cancel: 'Cancel',
  save: 'Save',
  create: 'Create',
  end_time: 'End Time',
  source: 'Source',
  source_placeholder: 'Paste URL link here',
  description: 'About Webinar',
  desc_placeholder: 'Write here...',
  payment_method: 'Payment Method',
  time_placeholder: 'Select date & time',
  errors: {
    cover_required: 'You need to upload webinar cover image',
    cover_uploading: 'Cover is being uploaded',
    title_required: 'Webinar Title is required',
    title_min_length: 'Webinar title has to be min 3 characters',
    title_max_length: 'Webinar title cannot be more than 250 characters',
    timezone_id_required: 'Please select the respective timezone',
    privacy_required: 'Privacy is required',
    invalid_time:
      'Start time should be at least one hour later than current time',
    source_url: 'Source should be valid URL',
    sub_url_required: 'Source should be enter',
    start_time_required: 'Start Time should not be empty',
    source_max_limit: 'Source cannot be more than 300 characters',
    description_required: 'Webinar description is required',
    description_min_length: 'Webinar description has to be min 3 characters',
    description_max_length:
      'Webinar description cannot be more than 2500 characters',
    presenter_name_required: 'Please enter presenter name',
    presenter_about_required: 'Please enter about presenter',
    presenter_required: 'Please select presenters',
    presenter_avatar_required: 'Please upload presenter avatar',
  },
  presenter_name: 'Presenter Name',
  about_presenter: 'About Presenter',
  add: 'Add',
  presenter_name_placeholder: 'Enter presenter Name here...',
  presenter_str: 'Presenter',
  see_all: 'See All',
  webinars: 'Webinars',
  click_here: 'Click Here',
  load_more: 'Load More',
  hours_ago: ' hours ago',
  hour_ago: 'an hour ago',
  days_ago: ' days ago',
  days_to_go: 'days to go',
  previous: 'Previous',
  recommended: 'Recommended',
  remove: 'Remove',
  add_presenter: 'Add Presenter',
  remove_presenter_alert: 'Are you sure you want to remove this presenter?',
  remove_registrant_alert: 'Are you sure you want to remove this registrant?',
  webinar_detail: 'Webinar Details',
  presenters: 'Presenters',
  manage_registrants: 'Manage Registrants',
  export_member: 'Download Microsite Member',
  upload_registrant: 'Upload Registrant',
  manage_webinar: 'Manage Webinar',
  copy_link: 'Copy Link',
  copied: 'Copied',
  find_remove_participants: 'Find & remove participants',
  upload_registrant_list: 'Upload Participants List',
  no_registrant_title: 'No Registrant',
  no_registrant_para:
    'For add participants please upload .xlsx or .xls file and press upload button',
  public: 'Public',
  private: 'Private',
  closed: 'Closed',
  open: 'Open',
  public_desc: 'Available to all users of HCP Space',
  private_desc: 'Available only to the users of microsite',
  closed_desc:
    'Available only to selected group of user. (These users can be uploaded in Manage Registrants Tab)',
  open_desc:
    'Available to all users of HCP Space and outside HCP Space as well. There will a seperate link for reigstration for non HCP Space Users.',
};
