import { useMutation } from "@apollo/client";
import { isEmpty } from "lodash";
import React, { useContext, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { strings } from "../../assets/strings/all";
import { storeDataToLocalStorage } from "../../common/functions";
import { VERIFY_EMAIL } from "../../containers/Auth/query";
import { FeedbackContext } from "../../containers/Context/FeedbackContext";
import HsLoader from "../Common/HsLoader";

const VerifyEmail = (props) => {
  const { auth: authString } = strings;
  const { setFeedback } = useContext(FeedbackContext);
  const { token } = props.match.params;

  const [verifyEmail, { loading, error, data }] = useMutation(VERIFY_EMAIL, {
    variables: { token },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: "danger" });
      }
    },

    onCompleted: (data) => {
      const { verifyEmail } = data;

      setTimeout(async () => {
        await storeDataToLocalStorage("token", verifyEmail.token, true);
        setFeedback({});
        props.history.replace(`/cases`);
      }, 50);
    },
  });

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  if (loading && isEmpty(data)) return <HsLoader type="initialize" />;

  if (error) {
    return <p>Error ...</p>;
  }

  return (
    <>
      {/* <Header /> */}
      <div className="no-auth-body">
        <Row className="auth-form-parent">
          <Col className="auth-form-col">
            <div className="auth-form-title">{authString.verify_email}</div>
            <div className="muted-text">{authString.verify_email_success}</div>
            <div className="signin-option-wrapper">
              <div className="btn-outer">
                <Button as={Link} to="/cases" variant="primary">
                  {authString.back_to_home}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VerifyEmail;
