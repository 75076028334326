import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function HsCaseSection(props) {
  const { children, title } = props;

  return (
    <Row className='section-wrapper'>
      <Col>
        <div className='section-title'>{title}</div>
        <div className='section-description'>{children}</div>
      </Col>
    </Row>
  );
}
export default HsCaseSection;
