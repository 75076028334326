import { isEmpty } from 'lodash';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { strings } from '../../../assets/strings/all';
import { hasCasePermission } from '../../../common/functions';
import permissionList from '../../../common/permissions';
import { CaseConfigContext } from '../../../containers/Context/CaseContext';
import { UserContext } from '../../../containers/Context/UserContext';
// import FollowButton from "../../Common/ActionButton/FollowButton";
import CoownerCard from '../SideCard/CoownerCard';
import JournalListCard from '../SideCard/JournalListCard';
import PanelMemberCard from '../SideCard/PanelMemberCard';
import CaseUserListItem from './CaseUserListItem';
import TeamViewCard from './teams.card';
import { RoleContext } from '../../../containers/Context/RoleContext';
function CaseDetailSideCard(props) {
  const { config, setConfig } = useContext(CaseConfigContext);
  const {
    // data: { primaryConsultants, uuid, isFollowing },
    data: { primaryConsultants, commentors, createdBy, caseStatus, userId },
    isPrivate,
    data,
  } = props;
  const { user } = useContext(UserContext);
  const { role } = useContext(RoleContext);
  const shouldAddTeam = userId === user.id;
  const { cases } = strings;
  return (
    <>
      {shouldAddTeam && caseStatus.slug === 'draft' && (
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Check
                className="hs-check muted-text"
                type="checkbox"
                onChange={(e) => {
                  setConfig({
                    ...config,
                    shouldAddPanel: !config.shouldAddPanel,
                  });
                }}
                id="includeExperts"
                label="Request a Review by our Experts Panel"
                checked={config.shouldAddPanel}
                style={{
                  fontSize: '18px',
                }}
                custom={true}
              />
            </Form.Group>
            <div
              style={{
                color: 'darkgree',
                fontSize: '12px',
                marginBottom: '9px',
                wordWrap: 'break-word',
              }}
            >
              {`By ticking this box our expert panel will be reviewing your Case/Question`}
            </div>
          </Col>
        </Row>
      )}
      <Card className="case-detail-side-card">
        <Card.Body>
          {primaryConsultants.length > 0 ? (
            <div className="case-side-user-wrapper">
              <div className="case-side-user-wrapper-title">
                {cases.case_owner}
              </div>
              <ListGroup variant="flush">
                {primaryConsultants.map((user, index) => (
                  <CaseUserListItem
                    key={index}
                    {...{ user }}
                    type="primaryConsultant"
                  />
                ))}
              </ListGroup>
            </div>
          ) : (
            <div className="case-side-user-wrapper">
              <div className="case-side-user-wrapper-title">
                {cases.case_owner}
              </div>
              <ListGroup variant="flush">
                <CaseUserListItem
                  {...{ user: createdBy }}
                  type="primaryConsultant"
                />
              </ListGroup>
            </div>
          )}
        </Card.Body>
      </Card>

      <JournalListCard {...props} />
      <CoownerCard {...props} />
      {shouldAddTeam && caseStatus.slug === 'draft' && <TeamViewCard />}
      {!isEmpty(commentors) && isPrivate && <PanelMemberCard {...props} />}

      {/* <Card className="case-detail-side-card">
        <Card.Body>
          <FollowButton {...{ uuid, isFollowing, type: 'side' }} />
        </Card.Body>
      </Card> */}
    </>
  );
}

export default CaseDetailSideCard;
