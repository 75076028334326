import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import { strings } from '../../assets/strings/all';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { UPDATE_LICENSE_STATUS } from './query';

const LicenseVerificationModal = (props) => {
  const { open, close, type, uuid } = props;
  const { cases: caseString } = strings;
  const { setFeedback } = useContext(FeedbackContext);

  const [updateLicenseStatus] = useMutation(UPDATE_LICENSE_STATUS, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      const { success, message } = data.updateLicenseStatus;
      if (success) {
        setFeedback({ message: message, variant: 'success' });
      }
    },
  });

  return (
    <Modal size="md" show={open} onHide={close} centered>
      <Modal.Header closeButton>
        <h6>{type === 'unverify' ? 'Unverify License' : 'Verify License'}</h6>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-description muted-text">
          {type === 'unverify'
            ? 'Are you sure you want to unverify this license'
            : 'Are you sure you want to verify this license'}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Row className="row-small">
          <Col>
            <Button variant="light" onClick={close}>
              {caseString.cancel}
            </Button>
          </Col>
          <Button
            variant="primary"
            onClick={() => {
              updateLicenseStatus({
                variables: {
                  uuid,
                  type,
                },
              });
              close();
            }}
          >
            {type === 'verify'
              ? 'Verify License'
              : type === 'unverify'
              ? 'Unverify License'
              : null}
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default LicenseVerificationModal;
