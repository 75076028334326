import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import HsOutsideAlerter from '../../Common/HsOutsideAlerter';
import { strings } from '../../../assets/strings/all';
import { useEffect } from 'react';

const CaseTitle = (props) => {
  const { cases, journals } = strings;

  const {
    type = 'case',
    data: { caseTitle },
  } = props;

  const [showInput, setShowInput] = useState(false);
  const [titleText, setCaseTitleText] = useState(
    type === 'journal' ? journals.title : cases.title,
  );

  useEffect(() => {
    if (caseTitle !== titleText) {
      setCaseTitleText(caseTitle);
    }
  }, [props, titleText, caseTitle]);

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  return (
    <Form.Group controlId="formBasicEmail">
      <HsOutsideAlerter {...{ onClickOutside: toggleInput }}>
        <Form.Control
          type="text"
          placeholder={cases.title_placeholder}
          name="caseTitle"
          value={caseTitle}
          {...props}
        />
      </HsOutsideAlerter>
    </Form.Group>
  );
};

export default CaseTitle;
