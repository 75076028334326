import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import AppComments from '../../containers/Comment/AppComments';
import CaseListWrapper from '../CaseList/CaseListWrapper';
import ScreenerFilters from '../CaseList/Filters/ScreenerFilters';
import { GET_CASES } from '../CaseList/query';
import TabHeaderContent from '../Common/TabHeaderContent';

const ScreenerDashboard = (props) => {
  return (
    <Col>
      <Card>
        <Card.Body>
          <AppComments {...props} />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ScreenerFilters {...props} />
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ScreenerDashboard;
