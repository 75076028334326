import React from 'react';
import { components } from 'react-select';
const MenuList = (props) => {
  const newProps = {
    ...props,
    className: `hs-dropdown-menu-inner`,
  };
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 200,
        background: 'white',
        boxShadow: '1px 1px 8px #969696',
        minWidth: 'auto',
      }}
    >
      <components.MenuList {...newProps} />
    </div>
  );
};

export default MenuList;
