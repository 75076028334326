import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import HsImage from '../HsImage';
import Row from 'react-bootstrap/Row';

class HsMentionSuggestionItem extends Component {
  render() {
    const { entry, highlightedDisplay } = this.props;
    const { avatar, speciality } = entry;
    return (
      <Row className="mention-user-wrapper">
        <Col xs="auto" className="user-image">
          <HsImage className="avatar-image" src={avatar} />
        </Col>
        <Col>
          <div className="user-name">{highlightedDisplay}</div>
          <div className="speciality-name">{speciality}</div>
        </Col>
      </Row>
    );
  }
}

export default HsMentionSuggestionItem;
