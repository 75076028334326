import React from "react";
import Badge from "react-bootstrap/Badge";

function HsCaseType(props) {
  const { caseType = {}, loading } = props;

  const name = caseType && caseType.name ? caseType.name : "";

  return loading ? <Badge variant="dark" className="case-type-loader shimmer-loader"></Badge> : <Badge variant="primary">{name}</Badge>;
}
export default HsCaseType;
