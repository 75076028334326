export const strings = {
  home: 'Home',
  cases: 'Cases/Questions',
  review: 'Review',
  journals: 'Journals',
  ':user_slug': 'User Details',
  ':case_slug': 'Case Details',
  ':journal_slug': 'Journal Details',
  already_have_account: 'Already have an account?',
  dont_have_account: "Don't have an account?",
  signin_now: 'Sign in now',
  signup_now: 'Sign up now!',
  analytics: 'Analytics',
  admin_dashboard: 'Admin Dashboard',
  expert_dashboard: 'Expert Dashboard',
  contact_us: 'Technical Support',
};
