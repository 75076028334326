import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { findIndex, isEmpty, isInteger, pick } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router';
import {
  getOtherDetailFields,
  hasField,
  hasPermission,
} from '../../common/functions';
import permissionList from '../../common/permissions';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { OrganizationContext } from '../../containers/Context/OrganizationContext';
import { UserContext } from '../../containers/Context/UserContext';
import Footer from '../../containers/Layout/Footer';
import Master from '../../containers/Layout/Master';
import { verifyCaseInputData } from '../Case/functions';
import {
  caseInput,
  CREATE_CASE,
  extraDetailsInput,
  UPDATE_CASE,
} from '../Case/query';
import CaseTypeFilter from '../Common/Filter/CaseTypeFilter';
import VisibilityStatusSelect from '../Common/Filter/VisibilityStatusSelect';
import HsLoader from '../Common/HsLoader';
import RaceSelect from '../Common/Filter/raceSelector';
import HsFormInput from '../Form/HsFormInput';
import HsFormLabel from '../Form/HsFormLabel';
import HsRadioGroup from '../Form/HsRadioGroup';
import HsSelect from '../Form/HsSelect';
import CaseFormBottomAction from './CaseFormBottomAction';
import AttachmentInputWrapper from './components/AttachmentInputWrapper';
import CaseTitle from './components/CaseTitle';
import WebLinkInputWrapper from './components/WebLinkInputWrapper';
import PostTypeSelector from '../Common/Filter/postType';
import GenderSelect from '../Common/Filter/genderSelector';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function CaseForm(props) {
  const { organization } = useContext(OrganizationContext);
  const { user } = useContext(UserContext);
  const { setFeedback } = useContext(FeedbackContext);

  const { caseFields = [], sharingFields = [] } = organization;
  const isShared =
    props.caseData !== undefined && props.caseData.originalCaseId;
  const fieldArray = !isShared
    ? Object.assign({}, ...caseFields.map((c) => ({ [c.slug]: c })))
    : Object.assign({}, ...sharingFields.map((c) => ({ [c.slug]: c })));
  const fieldNames = Object.keys(fieldArray);
  let extraFields = getOtherDetailFields(caseFields) || [];
  if (isShared) extraFields = getOtherDetailFields(sharingFields);

  const { type } = props;
  const initialCaseData = { ...props.caseData };
  if (isEmpty(initialCaseData.patientGender)) {
    initialCaseData.inquiryType = { name: 'Case' };
    initialCaseData.visibilityStatus = { name: 'Public', slug: 'public' };
  }

  const [caseData, setCaseData] = useState(initialCaseData);
  const [errors, setErrors] = useState({});
  const [numClick, setNumClick] = useState(0);

  const history = useHistory();

  const [
    createCase,
    {
      // error: createCaseErrors,
      loading: createCaseLoading,
      // data: createCaseData,
    },
  ] = useMutation(type === 'edit' ? UPDATE_CASE : CREATE_CASE, {
    onCompleted: (data) => {
      history.push(`/cases/${data.case.case.slug}`);
    },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
  });
  const [isDisabled, setIsDisabled] = useState(
    !isEmpty(errors) || createCaseLoading,
  );
  useEffect(() => {
    setIsDisabled(!isEmpty(errors) || createCaseLoading);
  }, [errors, createCaseLoading]);

  useEffect(() => {
    if (
      caseData.inquiryType.name === 'Question' &&
      (caseData['race'] ||
        caseData['patientAge'] !== null ||
        (caseData['patientGender'] &&
          caseData['patientGender']['slug'] !== 'NA'))
    ) {
      setCaseData({
        ...caseData,
        race: undefined,
        patientGender: { slug: 'NA', name: 'NA' },
        patientAge: null,
      });
    }
  }, [caseData]);

  if (isEmpty(organization) || isEmpty(user))
    return <HsLoader type="initialize" />;

  const updateCaseData = (name, value) => {
    const newCaseData = { ...caseData, [name]: value };

    if (
      newCaseData.visibilityStatus &&
      newCaseData.visibilityStatus.slug === 'private'
    ) {
      newCaseData.isModerationEnabled = false;
    }

    setCaseData(newCaseData);

    if (type === 'edit' || numClick > 0) {
      const errorObj = verifyCaseInputData(newCaseData, fieldArray);
      console.log('errorObj', errorObj);
      setErrors(errorObj);
      if (!isEmpty(errorObj)) {
        return false;
      }
    }
  };

  const updateDynamicFields = (name, value) => {
    const newCaseData = { ...caseData };
    let extraFields = newCaseData.extraDetails
      ? [...newCaseData.extraDetails]
      : [];
    const newExtraField = {
      name,
      value,
    };

    const extraFieldIndex = findIndex(extraFields, ['name', name]);

    if (extraFieldIndex > -1) extraFields[extraFieldIndex] = newExtraField;
    else extraFields.push(newExtraField);
    updateCaseData('extraDetails', extraFields);
  };

  const saveOrPublishCase = (statusSlug) => {
    if (numClick === 0) setNumClick(1);

    let caseInputData = { ...caseData };

    caseInputData.caseStatusSlug = statusSlug;

    const errors = verifyCaseInputData(caseInputData, fieldArray);
    if (!isEmpty(errors)) {
      console.log('logged errors', errors);
      setErrors(errors);
      return false;
    }

    if (caseData['visibilityStatus']) {
      caseInputData.visibilityStatusSlug = caseData['visibilityStatus'].slug;
      delete caseInputData['visibilityStatus'];
    }

    if (caseData['caseType']) {
      caseInputData.caseTypeSlug = caseData['caseType'].slug;
      delete caseInputData['caseType'];
    }
    if (caseData['patientGender']) {
      caseInputData.patientGenderSlug = caseData['patientGender'].slug;
      delete caseInputData['patientGender'];
    }
    if (caseData['race']) {
      caseInputData.race = caseData['race'].name;
    }
    if (caseData['inquiryType']) {
      caseInputData.inquiryType = caseData['inquiryType'].name;
    }
    if (caseData['inquiryType'] === 'Question') {
      caseInputData['patientGender'] = 'NA';
      caseInputData['race'] = undefined;
      caseInputData['patientAge'] = '';
    }
    if (caseData['extraDetails']) {
      const caseDataExtraDetails = [];
      caseInputData['extraDetails'].map((ed) => {
        const caseFinalInput = {};
        Object.keys(ed).map((edi, index) => {
          if (extraDetailsInput.indexOf(edi) > -1) {
            caseFinalInput[edi] = ed[edi];
          }
          return true;
        });
        caseDataExtraDetails.push(caseFinalInput);
        return true;
      });

      caseInputData['extraDetails'] = caseDataExtraDetails;
    }

    if (caseData['attachments']) {
      const caseAttachments = [];
      caseInputData['attachments'].forEach((a) => {
        caseAttachments.push({
          url: a.url,
          attachmentType: a.attachmentType,
        });
      });

      caseInputData['attachments'] = caseAttachments;
    }

    if (caseData['secondaryConsultants']) {
      const userUuids = [];
      const userEmails = [];
      caseData['secondaryConsultants'].map((user) => {
        if (user.uuid) {
          userUuids.push(user.uuid);
        } else if (user.email) {
          userEmails.push(user.email);
        }
        return true;
      });
      caseInputData['userUuids'] = userUuids;
      caseInputData['userEmails'] = userEmails;
    }

    if (caseData['links']) {
      const caseLinks = [];
      caseInputData['links'].forEach((a) => {
        caseLinks.push({ url: a.url, attachmentType: a.attachmentType });
      });

      caseInputData['links'] = caseLinks;
    }
    const caseFinalInput = pick(caseInputData, caseInput);

    const variables = { caseData: caseFinalInput };

    if (caseData.uuid) {
      variables.uuid = caseData.uuid;
    }
    createCase({ variables });
  };

  const hasCaseFields = (field) => {
    return hasField(fieldNames, field);
  };

  if (type === 'edit') {
    const { EDIT_MY_CASES_BASIC_DETAILS } = permissionList;
    const { permissionArray } = caseData;
    const hasEditPermission = hasPermission(
      permissionArray,
      EDIT_MY_CASES_BASIC_DETAILS,
    );

    if (!hasEditPermission) {
      setFeedback({ variant: 'danger', message: 'You are not allowed' });
      history.push(`/cases/${caseData.slug}`);
    }
  } else {
    const { permissionArray } = user;

    const { CREATE_CASES } = permissionList;

    const hasCreatePermission = hasPermission(permissionArray, CREATE_CASES);
    if (!hasCreatePermission) {
      setFeedback({ variant: 'danger', message: 'You are not allowed' });
      history.push(`/cases`);
    }
  }
  if (caseData.inquiryType && caseData.inquiryType.name === 'Question')
    extraFields = extraFields.filter(
      (e) => e.slug !== 'patient-medical-history',
    );
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Master {...props}>
        <Card className="case-form-card">
          <Card.Body>
            <Form className="form-article">
              {!isShared && (
                <>
                  <Row className="case-title-wrapper">
                    <Col md={6}>
                      <PostTypeSelector
                        onChange={(e) => {
                          if (e.name === 'Question') {
                            setCaseData({
                              ...caseData,
                              patientGender: { slug: 'NA', name: 'NA' },
                              patientAge: undefined,
                              inquiryType: e,
                            });
                          } else {
                            updateCaseData('inquiryType', e);
                          }
                        }}
                        value={caseData.inquiryType}
                      />
                      {errors['inquiryType'] && (
                        <Form.Control.Feedback type="invalid">
                          {errors['title']}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                    <Col md={6}>
                      <CaseTitle
                        {...{ caseFields }}
                        data={caseData}
                        onChange={(e) =>
                          updateCaseData('caseTitle', e.target.value)
                        }
                      />
                      {errors['caseTitle'] && (
                        <Form.Control.Feedback type="invalid">
                          {errors['caseTitle']}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Row>
                  <Row className="case-detail-wrapper">
                    {hasCaseFields('visibilityStatus') ? (
                      <Col md={3}>
                        <Form.Group
                          className="constrainer"
                          controlId="formBasicEmail"
                        >
                          <VisibilityStatusSelect
                            onChange={(e) => {
                              updateCaseData('visibilityStatus', e);
                            }}
                            value={caseData.visibilityStatus}
                          />
                          {errors['visibilityStatus'] && (
                            <Form.Control.Feedback type="invalid">
                              {errors['visibilityStatus']}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {hasCaseFields('caseType') ? (
                      <Col md={3}>
                        <Form.Group controlId="formBasicEmail">
                          <CaseTypeFilter
                            onChange={(e) => updateCaseData('caseType', e)}
                            value={caseData.caseType}
                            type="select"
                          />
                          {errors['caseType'] && (
                            <Form.Control.Feedback type="invalid">
                              {errors['caseType']}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {hasCaseFields('caseId') ? (
                      <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                          <HsFormLabel field={fieldArray['caseId']} />
                          <HsFormInput
                            name="caseId"
                            {...{ field: fieldArray['caseId'] }}
                            onChange={(name, value) => {
                              updateCaseData(name, value);
                            }}
                            value={caseData.caseId}
                          />
                          {errors['caseId'] && (
                            <Form.Control.Feedback type="invalid">
                              {errors['caseId']}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>

                  <Row>
                    <Col>
                      {/* {caseData.visibilityStatus &&
                  caseData.visibilityStatus.slug === 'public' ? (
                    <Row>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Check
                            className="hs-check muted-text"
                            type="checkbox"
                            onChange={(e) =>
                              updateCaseData(
                                'isModerationEnabled',
                                e.target.checked,
                              )
                            }
                            id="isModerationEnabled"
                            label="Enable Moderation"
                            checked={caseData.isModerationEnabled}
                            custom={true}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )} */}
                    </Col>
                  </Row>
                  {caseData.inquiryType &&
                    caseData.inquiryType.name === 'Case' && (
                      <Row>
                        <Col xs={12}>
                          <Form.Label className="formLabel">
                            {'Patient’s Basic Info'}
                          </Form.Label>
                        </Col>
                        {hasCaseFields('patientGender') ? (
                          <Col md={2}>
                            <GenderSelect
                              onChange={(e) => {
                                updateCaseData('patientGender', e);
                              }}
                              value={caseData.patientGender}
                            />
                          </Col>
                        ) : (
                          <></>
                        )}
                        {hasCaseFields('patientAge') ? (
                          <Col>
                            <Form.Group controlId="formBasicEmail">
                              <HsFormInput
                                {...{
                                  field: fieldArray['patientAge'],
                                  name: 'patientAge',
                                }}
                                onChange={(name, value) => {
                                  const intValue = parseInt(value);
                                  const patientAge = isInteger(intValue)
                                    ? intValue > 0 && intValue < 151
                                      ? intValue
                                      : caseData.patientAge
                                    : '';
                                  updateCaseData('patientAge', patientAge);
                                }}
                                value={caseData.patientAge}
                              />
                              {errors['patientAge'] && (
                                <Form.Control.Feedback type="invalid">
                                  {errors['patientAge']}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col>
                          <RaceSelect
                            onChange={(e) => {
                              updateCaseData('race', e);
                            }}
                            value={caseData.race}
                          />
                          {errors['race'] && (
                            <Form.Control.Feedback type="invalid">
                              {errors['race']}
                            </Form.Control.Feedback>
                          )}
                        </Col>
                        {hasCaseFields('patientStage') ? (
                          <Col>
                            <Form.Group controlId="formBasicEmail">
                              <HsFormInput
                                {...{ field: fieldArray['patientStage'] }}
                                onChange={(name, value) =>
                                  updateCaseData('patientStage', value)
                                }
                                value={caseData.patientStage}
                              />
                              {errors['patientStage'] && (
                                <Form.Control.Feedback type="invalid">
                                  {errors['patientStage']}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        ) : (
                          <></>
                        )}
                        {hasCaseFields('patientInitalDianosisDate') ? (
                          <Col>
                            <Form.Group controlId="formBasicEmail">
                              <HsFormInput
                                {...{
                                  field:
                                    fieldArray['patientInitalDianosisDate'],
                                }}
                                value={caseData['patientInitalDianosisDate']}
                                onChange={(date) =>
                                  updateCaseData(
                                    'patientInitalDianosisDate',
                                    format(date, 'yyyy-MM-dd'),
                                  )
                                }
                                maxDate={new Date()}
                              />
                              {errors['patientInitalDianosisDate'] && (
                                <Form.Control.Feedback type="invalid">
                                  {errors['patientInitalDianosisDate']}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    )}
                </>
              )}

              <Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <AttachmentInputWrapper
                      {...{ data: caseData }}
                      onChange={(value) => updateCaseData('attachments', value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <WebLinkInputWrapper
                    {...{ data: caseData }}
                    onChange={(value) => updateCaseData('links', value)}
                  />
                </Col>
              </Row>

              {extraFields.map((ef, index) => {
                const efIndex = findIndex(initialCaseData.extraDetails, [
                  'name',
                  ef.slug,
                ]);
                const field =
                  efIndex > -1 ? initialCaseData.extraDetails[efIndex] : null;
                return (
                  <Row key={`${ef}-${index}-extra-details`}>
                    <Col>
                      <Form.Group controlId="formBasicEmail">
                        <HsFormLabel field={fieldArray[ef.slug]} />
                        <HsFormInput
                          {...{ field: fieldArray[ef.slug] }}
                          name={ef.slug}
                          onChange={(name, value) =>
                            updateDynamicFields(name, value)
                          }
                          value={field ? field.value : ''}
                        />
                        {errors[ef.slug] && (
                          <Form.Control.Feedback type="invalid">
                            {errors[ef.slug]}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                );
              })}
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                xs={12}
              >
                <Button
                  as={Link}
                  to={caseData.slug ? `/cases/${caseData.slug}` : `/cases`}
                  variant="secondary"
                  style={{
                    marginRight: 45,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    let flag = false;
                    if (caseData['attachments']) {
                      caseData['attachments'].map((attachment) => {
                        if (!attachment.url.includes('static')) {
                          flag = true;
                        }
                      });
                    }
                    if (flag) {
                      alert('some images are still uploading...');
                      return;
                    }
                    saveOrPublishCase(
                      caseData.caseStatus &&
                        caseData.caseStatus.slug === 'returned'
                        ? 'screening'
                        : 'draft',
                    );
                  }}
                  variant="primary"
                  type="submit"
                >
                  {caseData.caseStatus &&
                  caseData.caseStatus.slug === 'returned'
                    ? 'Submit for review'
                    : 'Save as draft'}
                </Button>
              </Col>
            </Form>
          </Card.Body>
          {/* <CaseFormBottomAction
            caseData={caseData}
            saveOrPublishCase={saveOrPublishCase}
            errors={errors}
            loading={createCaseLoading}
          /> */}
        </Card>
      </Master>

      <div
        style={{
          height: 200,
        }}
      />
      {/* 
      <div className="footer-margin">
        <Footer />
      </div> */}
    </div>
  );
}

export default CaseForm;
