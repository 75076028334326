// setTimeout(function () {
//   ctx.hideMessage();
// }, 3000);
import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';

export default function HsAlert(props) {
  const { feedback, setFeedback } = useContext(FeedbackContext);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setFeedback({});
    }, 5000);

    return () => {
      clearTimeout(timer1);
    };
  }, [feedback, setFeedback]);

  return (
    <>
      {!isEmpty(feedback) ? (
        <Alert
          className="header-message-alert alert-fixed"
          key={`message`}
          variant={feedback.variant}
          dismissible={true}
          onClose={async () => {
            setFeedback({});
          }}
        >
          {feedback.message}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
