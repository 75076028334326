import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { hasPermission, userHasPermission } from '../../../common/functions';
import permissionList from '../../../common/permissions';
import { UserContext } from '../../../containers/Context/UserContext';
import FollowButton from '../../Common/ActionButton/FollowButton';

function CaseCardBottomAction(props) {
  const {
    caseData: {
      uuid,
      slug,
      isFollowing,
      permissionArray,
      caseStatus: { slug: caseStatusSlug },
    },
    showButton,
    hovered,
  } = props;

  const { user } = useContext(UserContext);

  const { EDIT_MY_CASES_BASIC_DETAILS, PUBLISHED_CASE_REVIEW } = permissionList;
  const hasEditPermission = hasPermission(
    permissionArray,
    EDIT_MY_CASES_BASIC_DETAILS,
  );
  const hasReviewPermission = userHasPermission(user, PUBLISHED_CASE_REVIEW);
  return (
    <>
      {showButton === 'following' && isFollowing ? (
        hovered ? (
          <FollowButton
            uuid={uuid}
            className="no-br"
            addDataAfterRemove={props.addDataAfterRemove}
            isFollowing={isFollowing}
          />
        ) : (
          <Button variant="link" as={Link} to={`/cases/${slug}/edit`}>
            Following
          </Button>
        )
      ) : (
        <></>
      )}

      {showButton === 'reviewComments' ? (
        <Button
          variant="primary"
          as={Link}
          to={`/cases/${slug}/review/comments`}
        >
          Review Comments
        </Button>
      ) : (
        <></>
      )}

      {showButton === 'edit' && hasEditPermission ? (
        <Button variant="link" as={Link} to={`/cases/${slug}/edit`}>
          Edit
        </Button>
      ) : (
        <></>
      )}
      {showButton === 'review' &&
      hasReviewPermission &&
      caseStatusSlug === 'in-review' ? (
        <Button
          variant={!hovered ? 'link' : 'primary'}
          as={Link}
          to={`/cases/${slug}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Review
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}

export default CaseCardBottomAction;
