import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { getCsrfToken } from '../../common/functions';
import HtmlRenderer from '../../components/Common/HtmlRenderer/HtmlRenderer';
import { GET_PRIVACY_POLICY } from '../Auth/query';

const AccountDeletion = (props) => {
  // const [getPrivacy, { data }] = useLazyQuery(GET_PRIVACY_POLICY);

  // useEffect(() => {
  //   getPrivacyPolicy();
  // }, []);

  // async function getPrivacyPolicy() {
  //   const res = await getCsrfToken();

  //   if (res) {
  //     getPrivacy();
  //   }
  // }

  return (
    <div className="terms-conditions-wrapper confirmation-wrapper">
      <>
        <h4 class="terms-conditions-title text-center-alignment text-center">
          {'Account deletion'}
        </h4>
        <br />
        <div className="terms-conditons-modal-content">
          <div>
            To delete your account, you can send an email to the{' '}
            <a
              className="linker"
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                window.open('mailto:cavi@iqvia.com');
              }}
            >
              Technical support team
            </a>{' '}
            requesting the deletion of your account. Please make sure to include
            your account details such as username and email address in the
            email. You can also mention the reason for deleting the account if
            you wish to. Once the support team receives your email, they will
            verify your account details and delete your account. Please note
            that deleting your account will result in the loss of all data and
            content in that account, like cases, files, and photos. You won’t be
            able to use the CAVi application. If you have any further questions,
            please feel free to ask.
          </div>
        </div>
      </>
    </div>
  );
};

export default AccountDeletion;
