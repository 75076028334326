import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useMemo } from 'react';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
const TransitionBar = (props) => {
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    borderWidth: 1,
    barPercentage: 0.9,
    barThickness: 20,
    maxBarThickness: 28,
    categoryPercentage: 0.5,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Case State Transition Bar',
      },
    },
  };
  const { data: TransitionData } = props;
  const data = {
    labels: Object.keys(TransitionData),
    datasets: [
      {
        label: 'Average Hours',
        data: Object.values(TransitionData).map((e) => e.timeDiff / e.count),
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Bar color
      },
    ],
  };
  return <Bar width={'100%'} options={options} data={data} />;
};

export default TransitionBar;
