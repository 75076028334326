import { useMutation } from '@apollo/client';
import { pick } from 'lodash';
import React, { useContext } from 'react';
import CaseTemplateForm from './CaseTemplateForm';
import { UPDATE_PROFILE, userInput } from '../User/query';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';

function CaseTemplateManger(props) {
  const { user } = props;
  const { setFeedback } = useContext(FeedbackContext);

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      setFeedback({ variant: 'success' });
    },
  });

  const onSubmit = (userObj) => {
    const userInputData = { ...userObj };

    const {
      // profession,
      userTitle,
      speciality,
    } = userInputData;

    // if (profession) {
    //   userInputData.professionSlug = profession.slug;
    // }
    if (speciality) {
      userInputData.specialitySlug = speciality.slug;
    }
    if (userTitle) {
      userInputData.userTitleSlug = userTitle.slug;
    }
    const userData = pick(userInputData, userInput);

    updateProfile({ variables: { userData } });
  };

  // if (loading && !isEmpty(errors)) {
  //   setErrors({});
  // }

  // const errorObj = parseGraphqlErrors(signInErrors);

  // if (errorObj.errors && !isEqual(errorObj.errors, errors)) {
  //   setErrors(errorObj.errors);
  // }
  // if (errorObj.message && !isEqual(errorObj.message, message)) {
  //   setMessage(errorObj.message);
  // }

  return <CaseTemplateForm user={user} onSubmit={onSubmit} loading={loading} />;
}

export default CaseTemplateManger;
