export const strings = {
  webinars: 'Webinars',
  events: 'Events',
  groups: 'Groups',
  people: 'People',
  microsites: 'Microsites',
  articles: 'Articles',
  cases: 'Cases',
  jobs: 'Jobs',
  all: 'All',
  no_results: 'No results found',
  no_result_message: 'We couldn’t find any results for',
  news: 'News',
  conference_proceeding: 'Conference Proceedings',
};
