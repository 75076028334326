import React from 'react';
import HsAsyncSelect from '../Form/HsAsyncSelect';
import { GET_JOURNALS } from './query';

function JournalPicker(props) {
  const getResultData = (data) => {
    return data.journalList.journals;
  };

  return (
    <HsAsyncSelect
      query={GET_JOURNALS}
      getResultData={getResultData}
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.uuid}
      placeholder="Start Typing for suggestion"
      {...props}
    />
  );
}

export default JournalPicker;
