import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { strings } from './../../assets/strings/all';
const { analytics } = strings;

class HsTooltip extends Component {
  renderTooltip = (props) => {
    const { type } = this.props;
    let text = {
      total_hcp: analytics.total_hcp_tooltip,
      active_hcp: analytics.active_hcp_tooltip,
      new_case: analytics.newcases_hcp_tooltip,
      pending: analytics.pending_tooltip,
      returned: analytics.returned_tooltip,
      open: analytics.open_tooltip,
      close: 'Resolved entities',
    }[type];
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  };

  render() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={this.renderTooltip}
      >
        <span>
          <i style={{ lineHeight: '1.5' }} className="icon-ico-info1" />
        </span>
      </OverlayTrigger>
    );
  }
}

export default HsTooltip;
