import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getUserToken } from '../../common/functions';
import AuthConsumer from '../Context/AuthContext';
import { UserContext } from '../Context/UserContext';
import { isEmpty } from 'lodash';

export const AuthenticatedRoute = ({
  component,
  exact = false,
  path,
  isAuthenticated,
}) => {
  const { user, shouldRedirect } = useContext(UserContext);
  console.log('authenticating route');
  return (
    <AuthConsumer>
      {(ctx) => {
        const checkForUser = getUserToken();
        const route = `${window.location.pathname}${window.location.search}`;

        if (!checkForUser) {
          if (shouldRedirect) {
            return (
              <Redirect
                to={`/signin?redirect_to=${encodeURIComponent(route)}`}
              />
            );
          }
          return <Redirect to={`/signin`} />;
        } else if (!user || isEmpty(user)) {
          return (
            <Redirect
              to={`/setting-up?redirect_to=${encodeURIComponent(route)}`}
            />
          );
        } else if (user.needPasswordReset) {
          return <Redirect to={`/profile/${user.username}/set-password`} />;
        } else if (
          !(
            user.isEmailVerified &&
            user.isPhoneVerified &&
            user.isLicenseVerified
          )
        ) {
          /* } else if (!user.hasStarted) {  */
          return <Redirect to={`/profile/${user.username}/complete-profile`} />;
        }

        return (
          <Route
            exact={exact}
            path={path}
            render={(props) =>
              React.createElement(component, { ...props }, isAuthenticated)
            }
          />
        );
      }}
    </AuthConsumer>
  );
};

export const ProfileCompletionRoute = ({
  component,
  exact = false,
  path,
  isAuthenticated,
}) => {
  const { user, logout } = useContext(UserContext);
  const checkForUser = getUserToken();
  const route = window.location.pathname;
  if (!checkForUser) {
    return <Redirect to={`/signin?redirect_to=${encodeURIComponent(route)}`} />;
  } else if (!user || isEmpty(user)) {
    return (
      <Redirect to={`/setting-up?redirect_to=${encodeURIComponent(route)}`} />
    );
  } else if (user.needPasswordReset) {
    return <Redirect to={`/profile/${user.username}/set-password`} />;
    // } else if (user.hasStarted) {
  } else if (
    user.isEmailVerified &&
    user.isPhoneVerified &&
    user.isLicenseVerified
  ) {
    return <Redirect to={`/cases`} />;
  }
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        React.createElement(component, { ...props }, isAuthenticated)
      }
    />
  );
};

export const PasswordResetRoute = ({
  component,
  exact = false,
  path,
  isAuthenticated,
}) => {
  const { user, shouldRedirect } = useContext(UserContext);

  const checkForUser = getUserToken();
  const route = window.location.pathname;

  const { needPasswordReset } = user;

  if (!needPasswordReset) {
    if (!checkForUser) {
      if (shouldRedirect) {
        return (
          <Redirect to={`/signin?redirect_to=${encodeURIComponent(route)}`} />
        );
      }
      return <Redirect to={`/signin`} />;
    } else if (!user || isEmpty(user)) {
      return (
        <Redirect to={`/setting-up?redirect_to=${encodeURIComponent(route)}`} />
      );
    } else if (!user.needPasswordReset) {
      return <Redirect to={`/cases`} />;
    }
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        React.createElement(component, { ...props }, isAuthenticated)
      }
    />
  );
};

export const BeforeAuthRoute = ({ component, exact = false, path }) => (
  <AuthConsumer>
    {(ctx) => {
      const checkForUser = getUserToken();

      const { search = '' } = window.location;

      if (checkForUser) {
        return <Redirect to={`/cases${search}`} />;
      }
      return (
        <Route
          exact={exact}
          path={path}
          new={'erere'}
          render={(props) => React.createElement(component, props)}
        />
      );
    }}
  </AuthConsumer>
);

const { object, bool, string } = PropTypes;

AuthenticatedRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  location: object,
};
