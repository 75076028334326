import React from 'react';
import Master from '../Layout/Master';
import Profile from './Profile';

function ProfileWrapper(props) {
  return (
    <Master {...props}>
      <Profile {...props} />
    </Master>
  );
}

export default ProfileWrapper;
