import { useApolloClient } from '@apollo/client';
import { isEmpty } from 'lodash';
import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { GET_USERS_FOR_MENTION } from '../../User/query';
import HsMentionSuggestionItem from './HsMentionSuggestionItem';
import MentionStyle from './MentionStyle';

function HsMentionInput(props) {
  const getResultData = (data) => {
    return data.userList.users;
  };

  const { value, caseUuid } = props;

  const client = useApolloClient();

  const searchData = async (e, callback) => {
    let { query = GET_USERS_FOR_MENTION, variables = { uuid: caseUuid } } = {
      ...props,
    };

    // if (isEmpty(e)) return [];

    variables = { ...variables, queryString: e, limit: 5 };

    const response = await client.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    });

    const { data } = response;
    const result = getResultData(data);

    let options = [];
    if (!isEmpty(result)) {
      options = result.map((option) => {
        return {
          id: option.username,
          display: option.fullName,
          avatar: option.avatar,
          // speciality: option.speciality.trans_name,
        };
      });
    }

    return callback(options);
  };

  const suggestion = (entry, search, highlightedDisplay, index, focused) => {
    return (
      <HsMentionSuggestionItem
        {...{ entry, search, highlightedDisplay, index, focused }}
      />
    );
  };

  return (
    <MentionsInput
      id="comment-textbox"
      value={value}
      onChange={(event, newValue, newPlainTextValue, mentions) => {
        const inputText = newValue;
        props.onChange(inputText);
      }}
      className="hs-mention"
      placeholder="Write a comment..."
      style={MentionStyle}
    >
      <Mention
        trigger="@"
        renderSuggestion={suggestion}
        data={searchData}
        markup="@{{__id__||__display__}}"
        appendSpaceOnAdd={true}
      />
    </MentionsInput>
  );
}

export default HsMentionInput;
