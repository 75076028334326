import React, { useEffect, useState } from 'react';
import { Container, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CaseTypeFilter from '../../Common/Filter/CaseTypeFilter';
import HsSort from '../../Common/Filter/HsSort';
import HsButtonToolbar from '../../Common/HsButtonToolbar';
import CaseList from '../CaseList';
import CaseListWrapper from '../CaseListWrapper';
import { GET_SCREENER_CASES } from '../query';

function ScreenerFilters(props) {
  const [radioValue, setRadioValue] = useState('PENDING');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);
  const radios = [
    { name: 'Pending', value: 'PENDING' },
    { name: 'Screened', value: 'SCREENED' },
  ];
  return (
    <Container className="px-4">
      <Row xs={12} sm={8} className="mb-4">
        <ToggleButtonGroup type="radio" name="radio">
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={radioValue === radio.value ? 'primary' : 'outline-dark'}
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => {
                setRadioValue(e.currentTarget.value);
                setLoading(true);
              }}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Row>
      <Col>
        {!loading && (
          <CaseList
            variables={{ isScreened: radioValue === 'SCREENED' }}
            customGetter={GET_SCREENER_CASES}
          />
        )}
      </Col>
    </Container>
  );
}

export default ScreenerFilters;
