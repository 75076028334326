import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PieChart from '../Common/PieChart';
import { Row } from 'react-bootstrap';

const ChartAnalytics = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  return (
    <Row
      className="px-2 flex"
      style={{
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          width: '33%',
          textAlign: 'center',
          display: 'inline',
        }}
      >
        <strong className="py-2"># Comments Per country</strong>
        <PieChart source={props.commentAna} />
      </div>
      <div
        style={{
          width: '33%',
          textAlign: 'center',
          display: 'inline',
        }}
      >
        <strong className="py-2"> # Cases Per country</strong>
        <PieChart source={props.caseAna} />
      </div>
      <div
        style={{
          width: '33%',
          textAlign: 'center',
          display: 'inline',
        }}
      >
        <strong className="py-2"> Case/Question View by user's country</strong>
        <PieChart source={props.userCaseViewAna} />
      </div>
    </Row>
  );
};

export default ChartAnalytics;
