import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CaseList from './CaseList';
import AllCaseFilter from './Filters/AllCaseFilter';
import MyCaseFilter from './Filters/MyCaseFilter';
import ReviewCaseFilter from './Filters/ReviewCaseFilter';

function CaseListWrapper(props) {
  const { type, showButton } = props;
  const [variables, setVariables] = useState(props.variables || {});

  const updateFilter = (filter) => {
    setVariables({
      ...variables,
      filters: { ...variables.filters, ...filter },
    });
  };

  return (
    <div>
      {type === 'myCases' ? (
        <MyCaseFilter {...{ updateFilter, variables }} />
      ) : type === 'allCases' ? (
        <AllCaseFilter {...{ updateFilter, variables }} />
      ) : type === 'reviewCases' ? (
        <ReviewCaseFilter {...{ updateFilter, variables }} />
      ) : (
        <></>
      )}

      <Row>
        <Col>
          <CaseList {...{ variables, showButton }} />
        </Col>
      </Row>
    </div>
  );
}

export default CaseListWrapper;
