import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import HsLoader from "../../components/Common/HsLoader";

function ProfileFormBotttom(props) {
  const { errors, loading } = props;

  const [isDisabled, setIsDisabled] = useState(!isEmpty(errors) || loading);

  useEffect(() => {
    setIsDisabled(!isEmpty(errors) || loading);
  }, [errors, loading]);

  return (
    // <div className="case-fixed-footer">
    //   <div className="case-fixed-footer-wrapper">
    //     <div className="case-fixed-footer-inner">
    //       <Row>
    //         <Col>
    //           <Row className="justify-end">
    //             <Col xs="auto">
    //               <Button variant="primary" type="submit" disabled={isDisabled}>
    //                 {loading ? <HsLoader /> : 'Update'}
    //               </Button>
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     </div>
    //   </div>
    // </div>
    <Row>
      <Col>
        <Row className="justify-end">
          <Col xs="auto">
            <Button variant="primary" type="submit" disabled={isDisabled}>
              {loading ? <HsLoader /> : "Update"}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ProfileFormBotttom;
