import React, { createContext, useState } from 'react';
import {
  clearLocalStorage,
  deleteCookie,
  getCsrfToken,
} from '../../common/functions';
import { useApolloClient, useMutation } from '@apollo/client';
import { SIGNOUT } from '../Auth/query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [user, setUser] = useState({});
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const client = useApolloClient();
  const history = useHistory();
  // const [token, setToken] = useState({});
  const clearUserData = () => {
    clearLocalStorage();
    deleteCookie('csrf-token');
    deleteCookie('session');
    deleteCookie('session.sig');
    client.clearStore();
    setUser({});
  };

  const [signout] = useMutation(SIGNOUT, {
    onCompleted: () => {
      clearUserData();
    },
    onError: () => {
      clearUserData();
    },
  });

  const logout = async () => {
    setShouldRedirect(false);
    const res = await getCsrfToken();
    if (res) {
      await signout();
    } else {
      clearUserData();
    }
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, logout, shouldRedirect, setShouldRedirect }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
