import React, { useContext, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { strings } from '../../assets/strings/all';
import { Link } from 'react-router-dom';
import { getQueryParameter, validatePassword } from '../../common/functions';
import { RESET_PASSWORD } from '../../containers/Auth/query';
import { useMutation } from '@apollo/client';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';

const ResetPassword = (props) => {
  const { auth: authString } = strings;
  const [showPassword, setShowPassword] = useState(false);
  const { setFeedback } = useContext(FeedbackContext);
  const [errors, setErrors] = useState({});
  const [numClick, setNumClick] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [inputState, setInputState] = useState({
    password: '',
    confirmPassword: '',
  });

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];
      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      const { success } = data.resetPassword;
      if (success) {
        setShowSuccess(true);
      }
    },
  });

  async function validateForm() {
    const errorsObj = {};
    const { errors: errorString } = authString;

    if (!inputState.password || inputState.password.trim() === '') {
      errorsObj.password = errorString.password_required;
    } else if (
      !inputState.confirmPassword ||
      inputState.confirmPassword.trim() === ''
    ) {
      errorsObj.password = errorString.password_required;
    } else if (inputState.confirmPassword !== inputState.password) {
      errorsObj.password = errorString.password_mismatch;
    } else {
      const error = validatePassword(inputState.password);
      if (error.length !== 0) {
        errorsObj.password = error;
      }
    }

    setErrors(errorsObj);

    return Object.keys(errorsObj).length === 0;
  }

  const handleShowPassword = (show) => {
    setShowPassword(show);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputState({
      ...inputState,
      [name]: value,
    });

    if (numClick > 0) {
      validateForm();
    }
  };

  const onSubmit = async (resetPassword) => {
    if (await validateForm()) {
      setErrors({});
      setNumClick(1);
      const token = getQueryParameter('token');
      resetPassword({
        variables: {
          password: inputState.password,
          token,
        },
      });
    }
  };

  return showSuccess ? (
    <Row className="auth-form-parent">
      <Col>
        <Form noValidate>
          <div className="auth-form-title m-0">{authString.reset_password}</div>
          <div className="mb-3 mt-3">
            <Form.Label>{authString.password_reset_success}</Form.Label>
          </div>

          <div className="text-right auth-form-action-button">
            <Link to="/signin" className="btn btn-light mr-3">
              {authString.back_to_signin}
            </Link>
          </div>
        </Form>
      </Col>
    </Row>
  ) : (
    <Row className="auth-form-parent">
      <Col>
        <Form
          noValidate
          onSubmit={async (event) => {
            event.preventDefault();
            onSubmit(resetPassword);
          }}
        >
          <div className="auth-form-title m-0">{authString.reset_password}</div>
          <div className="mb-3">
            <Form.Label>{authString.password_help_text}</Form.Label>
          </div>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>{authString.new_password}</Form.Label>
            <InputGroup>
              <Form.Control
                name="password"
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                placeholder={authString.enter_password_more_than}
                value={inputState.password}
                onChange={handleChange}
                aria-describedby="basic-addon1"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  id="basic-addon1"
                  onClick={() => {
                    handleShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <span className="icon-ico-view" />
                  ) : (
                    <span className="icon-ico-hide" />
                  )}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>{authString.retype_new_password}</Form.Label>
            <InputGroup>
              <Form.Control
                name="confirmPassword"
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                placeholder={authString.retype_your_password}
                value={inputState.confirmPassword}
                onChange={handleChange}
                aria-describedby="basic-addon1"
              />
              <InputGroup.Append>
                <InputGroup.Text
                  id="basic-addon1"
                  onClick={() => {
                    handleShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <span className="icon-ico-view" />
                  ) : (
                    <span className="icon-ico-hide" />
                  )}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              {errors && errors.password && errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="text-right auth-form-action-button">
            <Link to="/signin" className="btn btn-light mr-3">
              {authString.cancel}
            </Link>
            <Button
              variant="primary"
              type="submit"
              disabled={
                loading ||
                inputState.password.trim() === '' ||
                inputState.confirmPassword.trim() === ''
              }
            >
              {!loading ? (
                authString.update_password
              ) : (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {authString.updating}
                </>
              )}
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
