export const strings = {
  views: 'Views',
  share: 'Share',
  share_count: 'Shares',
  edit: 'Edit',
  title: 'News',
  news: 'News',
  similar_news: 'Similar News',
  add_news: 'Add News',
  publish: 'Published',
  published: 'Publish',
  cover_image: 'Cover Image',
  news_title: 'News Title',
  brief: 'Brief',
  tags: 'Tags (Add tags to help us show you relevant search results)',
  url: 'Source',
  optional: 'Optional',
  summary: 'Summary',
  title_placeholder: 'Enter title',
  brief_placeholder: 'Write here...',
  tags_placeholder: 'Add tags',
  url_placeholder: 'Paste URL link here',
  time_taken: 'Time Taken To Read (Optional)',
  time_placeholder: 'Enter time in minutes',
  speciality: 'Speciality',
  add_tag: 'Add Tag',
  no_news: 'No News',
  mentions: 'Mentions',
  add_mention: 'Add Mention',
  no_news_message: 'No News Found.',
  errors: {
    cover_required: 'You need to upload cover image',
    title_required: 'News Title is required',
    url_required: 'News url is required',
    country_required: 'Country is required',
    title_min_length: 'News title has to be min 3 characters',
    title_max_length: 'News title cannot be more than 250 characters',
    brief_required: 'News Brief is required',
    brief_min_length: 'News Brief has to be min 3 characters',
    brief_max_length: 'News Brief cannot be more than 250 characters',
    duration_number: 'Time should always be a number',
    duration_min: 'Time should be greater than zero',
    duration_max: 'Time should not be greater than 1000',
    url: 'Source should be valid URL',
    url_max_limit: 'Source cannot be more than 300 characters',
    description_required: 'News description is required',
    description_min_length: 'News description has to be min 3 characters',
    description_max_length:
      'News description cannot be more than 2500 characters',
    speciality_id_required: 'Please choose a speciality',
    cover_uploading: 'Cover is being uploaded',
  },
  published_ago: 'Published {date}',
  submitted_ago: 'Submitted {date}',
  edit_news: 'Edit News',
  delete_draft: 'Delete Draft',
  source: 'Source',
  discard_changes: 'Discard Changes',
  delete_news: 'Delete News',
  saving_changes_confirmation:
    'Are you sure you want to go back without saving changes?',
  saving_changes_confirmation_desc:
    'You have unsaved changes. You will lose all recent changes and this action cannot be reversed.',
  cancel: 'Cancel',
  share_news: 'Share News',
  regional_case: 'Regional Case1',
  published_two_years_ago: 'Published 2 years ago',
  save_news_confirm: 'Are you sure you want to go back without saving changes?',
  save_news_confirm_para:
    'You have unsaved changes. You will lose all recent changes and this action cannot be reversed.',
  delete_news_confirm: 'Are you sure you want to delete this news?',
  delete_news_confirm_para:
    'Click on delete only if you are sure that you want to delete this news. This action cannot be reversed.',
};
