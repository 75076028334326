import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AuthConsumer from '../Context/AuthContext';

export default function AuthMaster(props) {
  return (
    <AuthConsumer>
      {(ctx) => {
        return (
          <Row className="sdb-body-wrapper no-auth-body">
            <Col md={6} sm={9} xs={12} className="right-side-content">
              {props.children({ ctx })}
            </Col>
          </Row>
        );
      }}
    </AuthConsumer>
  );
}
