import React from 'react';
import NotificationListWrapper from '../../components/Notification/NotificationListWrapper';
import Master from '../Layout/Master';

function NotificationWrapper(props) {
  return (
    <Master className="case-wrapper" {...props}>
      <NotificationListWrapper {...props} />
    </Master>
  );
}

export default NotificationWrapper;
