import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import SignInOptions from '../../containers/Auth/SignInOptions';
import { strings } from '../../assets/strings/all';
import { useMutation } from '@apollo/client';
import { SEND_OTS_LINK } from '../../containers/Auth/query';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { isEmpty } from 'lodash';

const OneTimeSignIn = (props) => {
  const { auth: authString } = strings;
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const { setFeedback } = useContext(FeedbackContext);

  const [sendOtsLink, { loading }] = useMutation(SEND_OTS_LINK, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];
      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      const { success } = data.sendOtsLink;
      if (success) {
        setShowSuccess(true);
      }
    },
  });

  async function validateForm() {
    const errorsObj = {};
    const { errors: errorString } = authString;
    //eslint-disable-next-line
    let epression =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var regex = new RegExp(epression);

    if (isEmpty(email)) {
      errorsObj.email = errorString.email_required;
    } else if (!email.match(regex)) {
      errorsObj.email = errorString.incorrect_user;
    }

    await setErrors(errorsObj);

    return Object.keys(errorsObj).length === 0;
  }

  const onSubmit = async (sendOtsLink) => {
    if (await validateForm()) {
      sendOtsLink({
        variables: {
          email: email,
        },
      });
    }
  };

  return showSuccess ? (
    <div>
      <Row className="auth-form-parent">
        <Col>
          <Form
            noValidate
            onSubmit={async (event) => {
              event.preventDefault();
              onSubmit(sendOtsLink);
            }}
          >
            <div className="auth-form-title">
              {authString.ots_title}
              <Form.Label className="mt-3">
                {strings.formatString(authString.ots_success, {
                  email: email,
                })}
              </Form.Label>
            </div>
            <SignInOptions active="otl" />
          </Form>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Row className="auth-form-parent">
        <Col>
          <Form
            noValidate
            onSubmit={async (event) => {
              event.preventDefault();
              onSubmit(sendOtsLink);
            }}
          >
            <div className="auth-form-title">
              {authString.ots_title}
              <Form.Label className="mt-3">{authString.ots_label}</Form.Label>
            </div>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{authString.email_address}</Form.Label>
              <Form.Control
                type="text"
                placeholder={authString.email_placeholder}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              {errors && errors.email ? errors.email : ''}
            </Form.Control.Feedback>
            <div className="text-right">
              <div className="ots-action-btn-container">
                <div>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loading || isEmpty(email)}
                  >
                    {authString.send_link}
                  </Button>
                </div>
              </div>
            </div>
            <SignInOptions active="otl" />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default OneTimeSignIn;
