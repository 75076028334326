import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DicomModal = (props) => {
  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Dicom Viewer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <iframe
          style={{
            height: '100%',
            aspectRatio: '1/1',
            border: 'none',
          }}
          src={props.url}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DicomModal;
