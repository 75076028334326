export const strings = {
  hcp_space_title: 'IQVIA HCP Space',
  show_more_comments: 'Show more comments',
  open_app: 'Open in App',
  load_more: 'Load More',
  other: 'other',
  show_more: '... More',
  show_less: 'Less',
  invite_members: 'Invite New',
  invite: 'Invite',
  invite_new_members: 'Invite New Member(s)',
  search_people: 'Search Members',
  copy_link: 'Copy link',
  share_link: 'Share link',
  share_invitation_link: 'Share invitation link',
  share_profile: 'Share Profile',
  share_profilee: 'Share',
  close: 'Cancel',
  to: 'To',
  recent: 'Recent',
  view_previous_reply: 'View previous reply',
  name: 'Name',
  message: 'Message',
  mutual_connections: 'Mutual Connections',
  received: 'Received',
  sent: 'Sent',
  sort_by: 'Sort by',
  registered: 'Registered',
  connecting: 'Connecting',
  register: 'Register',
  join: 'Join',
  play: 'Play',
  ended: 'Ended',
  members: 'Members',
  posted_at: 'Posted at',
  see_all: 'See all',
  people: 'People',
  jobs: 'Jobs',
  webinars: 'Webinars',
  events: 'Events',
  groups: 'Groups',
  cases: 'Cases',
  articles: 'Articles',
  showing_counts: 'Showing {count} counts',
  showing_results: 'Showing {result} results',
  showing_result: 'Showing {result} result',
  published_at: 'Published at',
  processing: 'Processing',
  cover: 'Cover',
  edit_details: 'Edit basic details',
  edit: 'Edit',
  cover_image: 'Cover Image',
  avatar_image: 'Avatar Image',
  logo_image: 'Logo Image',
  zoom: 'Zoom',
  straighten: 'Straighten',
  change_image: 'Change Image',
  save: 'Save',
  drag_to_reposition: 'Drag to reposition image',
  share_article: 'Share {name}',
  remove_connection: 'Remove Connection',
  add_tags: 'Add Tags',
  new: 'New',
  mentions: 'Mentions',
  add_mention: 'Add Mention',
  search_groups: 'Search groups',
  search_people_conn: 'Search People',
  all_specialities: 'All Specialities',
  all_countires: 'All Countires',
  no_cases: 'No Cases',
  no_articles: 'No Articles',
  no_searchdata: 'No results found',
  no_searchData_msg: 'We couldn’t find any results for',
  select_webinar: 'Select All Webinar',
  select_any_webinar: 'Select any Webinar',
  select_any_group: 'Select any Group',
  select_group: 'Select All Group',
  share: 'Share',
  sponsored: 'Sponsored',
  fname: 'First Name',
  lname: 'Last Name',
  recommended: 'Recommended',
  latest: 'Latest',
  popular: 'Popular',
  days: 'Last 7 Days',
  last_a_month: 'Last 1 Month',
  last_three_months: 'Last 3 Months',
  open: 'Open',
  view: 'View',
  create: 'Create',
  update: 'Update',
  // edit: 'Edit Form',
  remove: ' Remove',
  manage: 'Manage',
  delete: 'Delete',
  cancel: 'Cancel',
  no_content: 'There is no content here.',

  attachments: 'Attachments',
  upload_attachments: 'Upload Attachments',
  web_links: 'Web Links',
  view_previous_replies: 'View Previous Replies',
  view_more_comments: 'View More Comments',
  dropzone_title: 'Select one or more files from your computer',
  dropzone_subtitle: 'or simply Drag & Drop files here to upload files',
  dropzone_subtitle_help: '(Only MP4, PDF, JPEG, JPG, PNG, DCM are supported)',
  dropzone_disclaimer:
    'Please ensure that patient confidentiality is not being compromised.',
  footer_text:
    'A speciality discussion board (the “Platform”), developed by IQVIA contains facts, views, independent opinions, statements, and recommendations of verified Platform’s users and other third parties’ materials. You understand that the information and materials available through the Platform are for informational purposes only and are not intended to constitute professional advice, diagnosis or treatment, or to substitute your professional judgment. We do not represent or endorse the accuracy, completeness or reliability of any information or material displayed, uploaded or distributed through the Platform. You assume the full risk and responsibility for the use of the information and materials you obtain from or through the Platform. ',
  signin_disclaimer:
    'IQVIA is wholly intended for the sole use and benefit of licensed healthcare professionals only residing in select countries',
  continue: 'Continue',
};
