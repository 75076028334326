import { isEmpty } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import AnalyticsUserListItem from './AnalyticsUserListItem';
import NoData from '../Common/NoData';
import { strings } from '../../assets/strings/all';

const AdminUserOnboarding = (props) => {
  const { loading, error, data, fetchMoreUser } = props;
  const { profile, common } = strings;

  if (loading || error || isEmpty(data)) {
    return 'Loading';
  }

  const { getAnalyticsUserList: dataObj } = data;
  const { hasMore = false, analyticsUsers = [], totalCount = 0 } = dataObj;

  if (totalCount === 0) {
    return <NoData type="user" message={profile.no_profile} />;
  }

  return (
    <>
      {analyticsUsers &&
        analyticsUsers.map((item, idx) => (
          <Row key={item.uuid}>
            <Col>
              <AnalyticsUserListItem {...item} />
            </Col>
          </Row>
        ))}
      <Row style={{ justifyContent: 'center', cursor: 'pointer' }}>
        {hasMore ? (
          <div className="mt-4 mb-4 text-center">
            <span
              onClick={(e) => {
                e.preventDefault();
                fetchMoreUser(20, analyticsUsers.length);
              }}
              variant="outline-primary"
            >
              {common.load_more}
            </span>
          </div>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};

export default AdminUserOnboarding;
