import { useMutation } from "@apollo/client";
import { pick } from "lodash";
import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { strings } from "../../assets/strings/all";
import HsCheckboxToggle from "../../components/Form/HsCheckboxToggle";
import { FeedbackContext } from "../../containers/Context/FeedbackContext";
import { notificationSettingInput, UPDATE_PROFILE } from "../../components/User/query";

function NotificationSetting(props) {
  const { user } = props;

  const [userData, setUserData] = useState(user);

  const { setFeedback } = useContext(FeedbackContext);

  const { profile: profileString } = strings;

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: "danger" });
      }
    },
    onCompleted: (data) => {
      const { user } = data;
      setFeedback({ variant: "success", message: user.message });
    },
  });

  const onSubmit = (userObj) => {
    const userInputData = { ...userObj };

    const userData = pick(userInputData, notificationSettingInput);

    updateProfile({ variables: { userData } });
  };

  // if (loading && !isEmpty(errors)) {
  //   setErrors({});
  // }

  // const errorObj = parseGraphqlErrors(signInErrors);

  // if (errorObj.errors && !isEqual(errorObj.errors, errors)) {
  //   setErrors(errorObj.errors);
  // }
  // if (errorObj.message && !isEqual(errorObj.message, message)) {
  //   setMessage(errorObj.message);
  // }

  const updateUserData = (name, value) => {
    const userObj = { ...userData };
    userObj[name] = value;
    onSubmit({ [name]: value });
    setUserData(userObj);
  };

  return (
    <Form>
      <Row className="mb-3 mt-2">
        <Col>
          <h5 className="auth-form-title">{profileString.notification_settings}</h5>
        </Col>
      </Row>
      <Row className="notification-setting-row">
        <Col>
          <div className="notification-setting-row-text">{profileString.email_alerts}</div>
          {user.email ? <div className="muted-text muted-text-small">{user.email}</div> : <></>}
        </Col>
        <Col md="auto">
          <HsCheckboxToggle
            checked={userData.emailEnabled}
            onToggle={(value) => {
              updateUserData("emailEnabled", value);
            }}
          />
        </Col>
      </Row>
      <Row className="notification-setting-row">
        <Col>
          <div className="notification-setting-row-text">{profileString.sms_alerts}</div>
          {user.phoneNumber ? <div className="muted-text muted-text-small">{user.phoneNumber}</div> : <></>}
        </Col>
        <Col md="auto">
          <HsCheckboxToggle
            checked={userData.smsEnabled}
            onToggle={(value) => {
              updateUserData("smsEnabled", value);
            }}
          />
        </Col>
      </Row>
      <Row className="notification-setting-row">
        <Col>
          {" "}
          <div className="notification-setting-row-text">{profileString.push_notifications}</div>
        </Col>
        <Col md="auto">
          <HsCheckboxToggle
            checked={userData.pnEnabled}
            onToggle={(value) => {
              updateUserData("pnEnabled", value);
            }}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default NotificationSetting;
