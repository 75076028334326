import React from 'react';
import Master from '../Layout/Master';
import UserProfile from './UserProfile';

function UserProfileWrapper(props) {
  return (
    <Master {...props}>
      <UserProfile {...props} />
    </Master>
  );
}

export default UserProfileWrapper;
