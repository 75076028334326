import React, { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function UserTitleSelect(props) {
  const {
    organization: { userTitles },
  } = useContext(OrganizationContext);
  const { onChange } = props;
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    const dr =
      userTitles &&
      userTitles.find((obj) => {
        return obj.slug === 'dr';
      });
    if (dr) {
      onChange(dr);
      setSelectedValue(dr);
    }
  }, [userTitles]);

  return (
    <>
      <HsSelect
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.slug}
        placeholder="Title"
        options={userTitles}
        {...props}
        value={props.value}
      />
    </>
  );
}

export default UserTitleSelect;
