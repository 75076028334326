// import image_400 from '../../images/400_image.png';
// import image_403 from '../../images/403_image.png';
// import image_404 from '../../images/404_image.png';
// import image_500 from '../../images/500_image.png';

// export const strings = {
//   400: {
//     title: "Bad Request",
//     desc: 'There was problem with your request',
//     ecode: "Error code :",
//     imageurl: image_400
//   },
//   401: {
//     title: "Unauthorized",
//     desc: 'Sorry, your request could not be processed',
//     ecode: "Error code :",
//     imageurl: image_403,
//     btn1_text: "Back",
//     btn2_text: "Retry",
//   },
//   403: {
//     title: "Access Forbidden",
//     desc: 'The page you were trying to react is absolutly forbidden for some reason',
//     ecode: "Error code :",
//     imageurl: image_403
//   },
//   404: {
//     title: "Oops!",
//     desc: `We can't seem to find the page you're looking for`,
//     ecode: "Error code :",
//     imageurl: image_404
//   },
//   500: {
//     title: "Uh...Error 500!",
//     desc: `Something went wrong at our end. Don't worry, it's not you - it's us. Sorry about that.`,
//     ecode: "Error code :",
//     imageurl: image_500
//   }
// };
