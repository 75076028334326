import React from 'react';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import UserProfileWrapper from '../Profile/UserProfileWrapper';
import { AuthenticatedRoute } from './AuthenticatedRoute';

export default function UserRoutes(props) {
  const { match } = props;
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={`${match.path}`}
        component={(props) => <Redirect to="/cases" />}
      />

      <AuthenticatedRoute
        exact
        path={`${match.path}/:user_slug`}
        component={(props) => (
          <UserProfileWrapper
            breadcrumb={true}
            view="profile"
            viewType="profile"
            active="profile"
            {...props}
          />
        )}
      />
    </Switch>
  );
}
