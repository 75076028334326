import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React from 'react';
import JournalDetails from '../Journal/JournalDetails';
import { GET_JOURNAL } from '../Journal/query';
import JournalForm from './JournalForm';

function JournalFetch(props) {
  const { type, match } = props;
  const { journal_slug } = match.params;
  const query = GET_JOURNAL;
  const variables = { slug: journal_slug };
  const { loading, error, data } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
  });

  if (loading || error || isEmpty(data)) {
    return 'Loading';
  }

  const { journalData } = data;

  return type === 'edit' ? (
    <JournalForm {...props} journalData={journalData} />
  ) : (
    <JournalDetails {...props} data={journalData} />
  );
}

export default JournalFetch;
