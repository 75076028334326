import { useMutation } from '@apollo/client';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { formatLikeCalendar } from '../../common/date-functions';
import HsImage from '../Common/HsImage';
import HsDescription from '../Common/HtmlRenderer/HsDescription';
import { MARK_NOTIFICATION_AS_READ } from './query';

export default function NotificationItem(props) {
  const { notificationData } = props;
  let { text, data, description, image, isRead, createdAt, uuid } =
    notificationData;

  const [markNotificationRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    variables: { uuid },
    onCompleted: (data) => {},
  });

  if (!text || !data) {
    return <></>;
    // return <>{JSON.stringify(notificationData)}</>;
  }
  // const dataKeys = Object.keys(data);

  let splittedText = text.split(' ');

  const renderableText = splittedText.map((t) => {
    const key = t.replace('{', '').replace('}', '');
    const dataObj = data[key];
    if (dataObj) {
      const { title, url } = dataObj;
      let dataAnchor = <Link to={url}>{title}</Link>;
      if (!url) {
        dataAnchor = <span>{title}</span>;
      }
      t = <>{dataAnchor} </>;
    } else {
      t = `${t} `;
    }
    return t;
  });
  const onHover = () => {
    // if (!isRead) {
    //   markNotificationRead();
    // }
  };

  return (
    <Row
      className={`notification-item row-small ${
        isRead ? 'notification-read-item' : 'notification-unread-item'
      }`}
      onMouseEnter={onHover}
      onClick={() => {
        markNotificationRead();
      }}
    >
      <Col xs="auto">
        <HsImage
          className="avatarImage"
          roundedCircle
          src={image}
          // alt={notificationData.fromClients.full_name}
        />
      </Col>
      <Col className="notification-item-detail">
        <div>
          <div className="notification-item-text">{renderableText}</div>
          <div className="notification-item-content">
            <HsDescription description={description} />
          </div>

          <div className="notification-item-date">
            {formatLikeCalendar(createdAt)}
          </div>
        </div>
      </Col>
    </Row>
  );
}
