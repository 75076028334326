import { ApolloProvider } from '@apollo/react-hooks';
import 'core-js/features/array/includes';
import 'core-js/features/map';
import 'core-js/features/object/assign';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/string/pad-start';
import 'raf/polyfill';
import React from 'react';
import { render } from 'react-dom';
import 'unfetch/polyfill';
import apolloClient from './apolloSetup';
import './assets/stylesheets/main.scss';
import './common/prototypes';
import HsGlobalPopup from './components/Common/HsGlobalPopup';
import FeedbackContextProvider from './containers/Context/FeedbackContext';
import IdleTimerContextProvider from './containers/Context/IdleTimerContext';
import NotificationCountContextProvider from './containers/Context/NotificationContext';
import OrganizationContextProvider from './containers/Context/OrganizationContext';
import RoleProvider from './containers/Context/RoleProvider';
import UserContextProvider from './containers/Context/UserContext';
import { GetUserRole } from './containers/Helper/Initialize';
import Routes from './containers/Route/Routes';
import Footer from './containers/Layout/Footer';

const App = () => (
  <ApolloProvider client={apolloClient}>
    <>
      <FeedbackContextProvider>
        <OrganizationContextProvider>
          <UserContextProvider>
            <NotificationCountContextProvider>
              <IdleTimerContextProvider>
                <HsGlobalPopup />
                <Routes></Routes>
                <Footer />
              </IdleTimerContextProvider>
            </NotificationCountContextProvider>
          </UserContextProvider>
        </OrganizationContextProvider>
      </FeedbackContextProvider>
    </>
  </ApolloProvider>
);

render(<App />, document.getElementById('sdb-root'));
