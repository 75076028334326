import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../../common/functions';
import permissionList from '../../../common/permissions';
import { UserContext } from '../../../containers/Context/UserContext';

function AddCaseButton(props) {
  const { user } = useContext(UserContext);
  const { permissionArray } = user;

  const { CREATE_CASES } = permissionList;

  const hasCreatePermission = hasPermission(permissionArray, CREATE_CASES);

  if (!hasCreatePermission) return <></>;

  return (
    <>
      <Button variant="primary" as={Link} to="/cases/create">
        Add Case /Question
      </Button>
    </>
  );
}

export default AddCaseButton;
