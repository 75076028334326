import React from 'react';
import CaseForm from '../../components/CaseForm/CaseForm';

function CreateCase(props) {
  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault(); // Prevent form submission or TextInput value change
      console.log('Enter key is disabled in this input!');
    }
  };
  return (
    <CaseForm
      {...props}
      handleKeyPress={handleKeyPress}
      caseData={{ attachments: [] }}
    />
  );
}

export default CreateCase;
