import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'lodash';
import React from 'react';
import { useRef } from 'react';

function RichTextEditor(props) {
  const { initValue = '', disabled = false } = props;
  const editorRef = useRef(null);

  const handleEditorChange = debounce((content, editor) => {
    if (editorRef.current) {
      props.onChange(editorRef.current.getContent());
    }
  }, 100);

  return (
    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      apiKey="3s8ecrg44urb2paewihybde95v3y2sp5cb6jfnqkywv43yay"
      disabled={disabled}
      init={{
        height: props.height || 300,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: `undo redo | formatselect | bold italic backcolor |
             alignleft aligncenter alignright alignjustify | 
             bullist numlist outdent indent | removeformat | help`,
      }}
      onEditorChange={handleEditorChange}
      initialValue={initValue}
      // {...props}
    />
  );
}

export default RichTextEditor;
