import { useApolloClient } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Control from './Select/Control';
import GroupHeading from './Select/GroupHeading';
import IndicatorsContainer from './Select/IndicatorsContainer';
import Menu from './Select/Menu';
import MenuList from './Select/MenuList';
import MultiValueContainer from './Select/MultiValueContainer';
import MultiValueLabel from './Select/MultiValueLabel';
import MultiValueRemove from './Select/MultiValueRemove';
import Option from './Select/Option';
import Placeholder from './Select/Placeholder';
import SingleValue from './Select/SingleValue';
import ValueContainer from './Select/ValueContainer';

const HsAsyncSelect = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const { country } = props;
  const client = useApolloClient();
  useEffect(() => {
    setReload(true);
  }, [country]);
  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);
  const searchData = async (e) => {
    let { query, variables = {}, value, country } = props;

    if (value) {
      const excludedUsers = value.map((v) => v.uuid);
      variables.excluded = excludedUsers;
    }
    // if (isEmpty(e)) return [];

    variables = { ...variables, queryString: e };
    const { loading, data } = await client.query({
      query,
      variables: {
        ...variables,
        country,
      },
      fetchPolicy: 'network-only',
    });

    if (loading) {
      setIsLoading(true);
      return handleUsersSearch;
    }

    const result = props.getResultData(data);

    return handleUsersSearch(result);
  };
  const handleUsersSearch = (data) => {
    let options = [];
    if (!isEmpty(data)) {
      options = data.map((option) => {
        return option;
      });
    }

    return options;
  };

  // const { options } = props;
  if (reload) return <div>...</div>;
  return (
    <AsyncCreatableSelect
      // options={options}
      components={{
        Control,
        Option,
        IndicatorsContainer,
        Menu,
        MenuList,
        GroupHeading,
        ValueContainer,
        Placeholder,
        SingleValue,
        MultiValueContainer,
        MultiValueLabel,
        MultiValueRemove,
      }}
      isSearchable={true}
      isClearable={false}
      isMulti
      getOptionLabel={(option) => `${option.name}`}
      getOptionValue={(option) => option.slug}
      loadOptions={async (e) => {
        const myOptions = await searchData(e.toLowerCase());
        return myOptions;
      }}
      defaultOptions
      cacheOptions
      isLoading={isLoading}
      isValidNewOption={(val) => {
        return false;
      }}
      createOptionPosition="first"
      formatCreateLabel={(inputVal) => `Add ${inputVal}`}
      getNewOptionData={(val) => ({ email: val, fullName: val })}
      {...props}
    />
  );
};

export default HsAsyncSelect;
