import React from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';

const DateTimeComponent = (props) => {
  return (
    <>
      <Form.Group className='m-0 react-date-select-input'>
        <Form.Control
          type='text'
          placeholder={props.placeholder}
          onClick={props.onClick}
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
        />
      </Form.Group>
      <span
        className='licence-form-calender btn btn-black'
        onClick={!props.disabled ? props.onClick : null}
      >
        <span className='icon icon-ico-date' />
      </span>
    </>
  );
};

const HsDateTime = (props) => {
  return (
    <DatePicker
      customInput={<DateTimeComponent />}
      className={props.className}
      selected={props.data}
      showMonthDropdown
      showYearDropdown
      dateFormat={
        props.showTimeSelect ? 'dd-MMM-yyyy, hh:mm aa' : 'dd-MMM-yyyy'
      }
      value={props.value !== undefined ? props.value : null}
      name={props.name}
      placeholderText={props.placeholder}
      disabled={props.disabled}
      minDate={props.minDate}
      maxDate={props.maxDate}
      onChange={props.onChange}
      {...props}
    />
  );
};

export default HsDateTime;
