import React from "react";

const HsStar = (props) => {
  const changeGrade = (e) => {
    props.changeGradeIndex(e.target.value);
  };

  return (
    <label className="star">
      <input type="radio" name="rating" id={props.grade} value={props.index} className="star-radio-input" onClick={changeGrade} />
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={props.className}
      >
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
      </svg>
    </label>
  );
};

export default HsStar;
