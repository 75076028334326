import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { hasPermission, userHasPermission } from '../../common/functions';
import permissionList from '../../common/permissions';
import { UserContext } from '../../containers/Context/UserContext';
import ActionButton from '../Common/ActionButton/ActionButton';

function JournalDetailBottomAction(props) {
  const {
    data: {
      uuid,
      slug,
      caseStatus: { slug: caseStatusSlug },
      permissionArray,
    },
  } = props;

  const {
    REVIEW_JOURNALS,
    EDIT_MY_JOURNALS,
    ARCHIVE_MY_JOURNALS,
    UNARCHIEVE_MY_JOURNALS,
  } = permissionList;

  const { user } = useContext(UserContext);
  const hasReviewPermission = userHasPermission(user, REVIEW_JOURNALS);

  const hasEditPermission = hasPermission(permissionArray, EDIT_MY_JOURNALS);

  const hasClosePermission = false;
  const hasReOpenPermission = false;

  // const hasClosePermission = hasPermission(permissionArray, CLOSE_MY_CASE);
  // const hasReOpenPermission = hasPermission(permissionArray, RE_OPEN_MY_CASE);
  const hasArchivePermission = hasPermission(
    permissionArray,
    ARCHIVE_MY_JOURNALS,
  );
  const hasUnArchivePermission = hasPermission(
    permissionArray,
    UNARCHIEVE_MY_JOURNALS,
  );

  return (
    <div className="case-fixed-footer">
      <div className="case-fixed-footer-wrapper">
        <Button
          variant="primary"
          as={Link}
          to={`/cases`}
          className="back-btn no-br"
        >
          <span className="icon-ico-back" />
        </Button>
        <div className="case-fixed-footer-inner">
          <Row>
            <Col>
              <Row className="justify-end">
                {(caseStatusSlug === 'draft' ||
                  caseStatusSlug === 'open' ||
                  caseStatusSlug === 'rejected') &&
                hasEditPermission ? (
                  <Col xs="auto">
                    <Button
                      as={Link}
                      to={`/journals/${slug}/edit`}
                      variant="primary"
                      uuid={uuid}
                    >
                      Edit
                    </Button>
                  </Col>
                ) : (
                  <></>
                )}
                {caseStatusSlug === 'in-review' && hasReviewPermission ? (
                  <>
                    <Col xs="auto">
                      <ActionButton
                        entityType="journals"
                        type="approved"
                        variant="primary"
                        uuid={uuid}
                      />
                    </Col>
                    <Col xs="auto">
                      <ActionButton
                        entityType="journals"
                        type="rejected"
                        variant="outline-primary"
                        uuid={uuid}
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {caseStatusSlug === 'open' ? (
                  <>
                    {hasArchivePermission ? (
                      <Col xs="auto">
                        <ActionButton
                          entityType="journals"
                          type="archive"
                          variant="outline-danger"
                          uuid={uuid}
                        />
                      </Col>
                    ) : (
                      <></>
                    )}
                    {hasReOpenPermission ? (
                      <Col xs="auto">
                        <ActionButton
                          entityType="journals"
                          type="reopen"
                          variant="primary"
                          uuid={uuid}
                        />
                      </Col>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {caseStatusSlug === 'open' && hasClosePermission ? (
                  <Col xs="auto">
                    <ActionButton
                      entityType="journals"
                      type="close"
                      variant="primary"
                      uuid={uuid}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                {caseStatusSlug === 'archive' && hasUnArchivePermission ? (
                  <Col xs="auto">
                    <ActionButton
                      entityType="journals"
                      type="unarchive"
                      variant="primary"
                      uuid={uuid}
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default JournalDetailBottomAction;
