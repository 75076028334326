import React, { useContext, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import CaseDetailBottomAction from "./CaseDetailBottomAction";
import AttachmentWrapper from './components/AttachmentWrapper';
import CaseBottom from './components/CaseBottom';
import CaseDetailHeader from './components/CaseDetailHeader';
import CaseFieldWrapper from './components/CaseFieldWrapper';
import WebLinkWrapper from './components/WebLinkWrapper';
import CaseDetailSideCard from './components/CaseDetailSideCard';
import { useMutation } from '@apollo/client';
import { UPDATE_CASE_VIEW, UPDATE_ENTITY_FEEDBACK_STATUS } from './query';
import CaseDetailTopAction from './CaseDetailTopAction';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { useHistory } from 'react-router-dom';
import CommentPopup from './components/CommentPopup';
import { UserContext } from '../../containers/Context/UserContext';
import { CaseConfigContext } from '../../containers/Context/CaseContext';
import ScreenerActionCard from '../CaseForm/screener.action';
import RoleProvider from '../../containers/Context/RoleProvider';
import CaseReturnReasons from './components/caseReturnReasons';
import { Button } from 'react-bootstrap';
import ShouldShareModal from '../CaseForm/components/shouldShareModal';
import SharedWrapper from './components/SharedWrapper';
import DicomModal from '../CaseForm/components/dicomModal';
function CaseDetails(props) {
  const { viewType, data } = props;
  const { setFeedback } = useContext(FeedbackContext);
  const { user } = useContext(UserContext);
  const [dicomView, setDicomView] = useState({
    open: false,
    url: '',
  });
  const [popup, setPopup] = useState(false);
  const isPrivate = props.data.visibilityStatus.slug === 'private';
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({
    shouldAddPanel: true,
    teams: [],
  });
  // const shouldReview =
  //CHECK IF FIRST COMMENT IS FROM EXPERT
  const [expertComment, setExpertComment] = useState();

  const history = useHistory();
  let popupTimer;

  const [updateView] = useMutation(UPDATE_CASE_VIEW);
  useEffect(() => {
    updateView({
      variables: {
        uuid: data.uuid,
      },
    });

    return () => {
      clearTimeout(popupTimer);
    };
  }, []);

  useEffect(() => {
    if (expertComment) {
      popupTimer = setTimeout(() => {
        setPopup(true);
      }, 30000);
    }
  }, [expertComment]);
  const [publishCase] = useMutation(UPDATE_ENTITY_FEEDBACK_STATUS, {
    onCompleted: (data) => {
      history.push(`/cases/${data.feedback.case.slug}`);
    },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
  });

  const submit = () => {
    publishCase({
      variables: {
        entityUuid: data.uuid,
        statusSlug: 'screening',
        entityType: 'cases',
        teams: config.teams,
        shouldAddPanel: config.shouldAddPanel,
      },
    });
  };

  const scrollToComment = () => {
    const element = document.getElementById('comment-textbox');
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - window.innerHeight / 2;
    window.scrollTo({ top: 0, left: middle, behavior: 'smooth' });
    setTimeout(() => {
      element.focus();
    }, 1000);
  };
  const sharedAttachments = props.data.attachments.filter(
    (e) => e.caseSubmitionType === 'SHARING',
  );
  const dicomAttachments = props.data.attachments.filter((e) =>
    e.url.includes('dicom'),
  );
  return (
    <RoleProvider>
      <CaseConfigContext.Provider value={{ config, setConfig }}>
        <Row>
          <Col md={viewType !== 'reviewComments' ? 9 : 12}>
            <Card>
              <Card.Body>
                {(data.caseStatus.slug === 'returned' ||
                  data.caseStatus.slug === 'screening') && (
                    <>
                      <CaseReturnReasons {...props} />
                    </>
                  )}
                {user.id === data.userId &&
                  viewType !== 'reviewComments' &&
                  (data.caseStatus.slug === 'draft' ||
                    data.caseStatus.slug === 'close' ||
                    data.caseStatus.slug === 'returned' ||
                    data.caseStatus.slug === 'archive' ||
                    (data.caseStatus.slug === 'open' &&
                      user.id === data.userId)) ? (
                  <>
                    <CaseDetailTopAction {...props} publishCase={submit} />
                  </>
                ) : (
                  <></>
                )}
                {(data.caseStatus.slug === 'open' ||
                  data.caseStatus.slug === 'screening' ||
                  data.caseStatus.slug === 'returned') && (
                    <ScreenerActionCard {...props} />
                  )}
                <CaseDetailHeader {...props} />
                {viewType !== 'reviewComments' ? (
                  <>
                    {props.data.originalCaseId && (
                      <>
                        <AttachmentWrapper
                          {...props}
                          data={{
                            ...props.data,
                            attachments: sharedAttachments,
                          }}
                        />
                        <SharedWrapper {...props} />
                        <div
                          style={{
                            height: 1,
                            backgroundColor: '#d2d2d2',
                            width: '100%',
                            margin: '10px 0',
                          }}
                        />
                      </>
                    )}
                    <AttachmentWrapper
                      {...props}
                      data={{
                        ...props.data,
                        attachments: props.data.attachments.filter(
                          (e) =>
                            e.caseSubmitionType === 'NEW' &&
                            !e.url.includes('dicom'),
                        ),
                      }}
                    />
                    {dicomAttachments.map((e) => (
                      <>
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <div
                            onClick={(ev) => {
                              setDicomView({
                                url: e.url,
                                open: true,
                              });
                            }}
                            onScroll={(ev) => {
                              ev.preventDefault();
                            }}
                            style={{
                              height: '100%',
                              width: '100%',
                              cursor: 'pointer',
                              opacity: 0,
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              aspectRatio: '1/1',
                            }}
                          ></div>
                          <iframe
                            onClick={() => { }}
                            style={{
                              width: '250px',
                              aspectRatio: '1/1',
                              border: 'none',
                              cursor: 'pointer',
                            }}
                            src={e.url}
                          />
                        </div>
                      </>
                    ))}
                    <WebLinkWrapper {...props} />
                    <CaseFieldWrapper {...props} />
                  </>
                ) : (
                  <></>
                )}
                {isPrivate &&
                  (user.isExpert === true || user.id === data.userId) &&
                  (data.caseStatus.slug === 'open' ||
                    data.caseStatus.slug === 'close') && (
                    <Row className="my-2">
                      <Col
                        style={{
                          display: 'flex',
                          gap: '8px',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          textAlign: 'center',
                          width: '240px',
                        }}
                      >
                        <Button
                          style={{ fontSize: '18px', width: '240px' }}
                          variant="outline-primary"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          Make this case/question public
                        </Button>
                        <p
                          style={{
                            fontSize: '10px',
                            width: '240px',
                          }}
                        >
                          By clicking this button, this case may be duplicated
                          and shared with all registered users.
                        </p>
                      </Col>
                    </Row>
                  )}
                <div id="case-bottom-container">
                  <CaseBottom setExpertComment={setExpertComment} {...props} />
                </div>
                {/* {viewType !== "reviewComments" ? <CaseDetailBottomAction {...props} /> : <></>} */}
              </Card.Body>
            </Card>
          </Col>
          {viewType !== 'reviewComments' ? (
            <Col md={3}>
              <CaseDetailSideCard {...props} isPrivate={isPrivate} />
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <ShouldShareModal
          open={open}
          close={() => {
            setOpen(false);
          }}
          slug={data.slug}
        />
        <DicomModal
          {...dicomView}
          onClose={() => {
            setDicomView({
              open: false,
              url: '',
            });
          }}
        />
        {data.caseStatus.slug === 'open' && popup && !user.isExpert ? (
          <div className="conversation-popup">
            <CommentPopup
              uuid={data.uuid}
              caseStatusSlug={data.caseStatus.slug}
              permissionArray={data.permissionArray}
              scrollToComment={scrollToComment}
            />
          </div>
        ) : (
          <></>
        )}
      </CaseConfigContext.Provider>
    </RoleProvider>
  );
}

export default CaseDetails;
