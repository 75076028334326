import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PanelData from './PanelData';
import { GET_PANEL_USERS } from './query';
import { strings } from '../../assets/strings/all';

const ExpertPanel = (props) => {
  const [showPanel, setShowPanel] = useState(false);
  const { loading, data, error } = useQuery(GET_PANEL_USERS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    data &&
      data.panelData.panels.map((item) => {
        if (item.PanelMember.length > 0) {
          setShowPanel(true);
        }
        return null;
      });
  }, [data]);

  const { users: userString } = strings;

  if (loading || error || isEmpty(data)) {
    return 'Loading';
  }

  const {
    panelData: { panels },
  } = data;

  return (
    <>
      {showPanel ? (
        <Card className="expert-card">
          <Card.Body>
            <Row>
              <Col>
                <Card.Title as="h6">{userString.expert_panel}</Card.Title>
              </Col>
            </Row>
            <Row>
              <Col>
                {panels &&
                  panels.map((item, idx) => (
                    <PanelData key={idx} panel={item} />
                  ))}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
};

export default ExpertPanel;
