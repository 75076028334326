import React, { useContext, useState } from 'react';
import { Card, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import { strings } from '../../assets/strings/all';
import AdminAnalytics from './AdminAnalytics';
import AdminUserOnboarding from './AdminUserOnboarding';
import { GET_ANALYTICS_USER_LIST } from './query';
import { useQuery } from '@apollo/client';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import HsAccountTypeFilter from '../Common/Filter/HsAccountTypeFilter';
import HsCountryFilter from '../Common/Filter/HsCountryFilter';
import { debounce } from 'lodash';

const AdminDashboard = (props) => {
  const { activeTab, setActiveTab } = props;
  const { header } = strings;
  const { setFeedback } = useContext(FeedbackContext);
  const [state, setState] = useState({
    filterString: '',
    userType: '',
  });
  const variables = {
    limit: 20,
    offset: 0,
  };

  const { loading, error, data, fetchMore, refetch } = useQuery(
    GET_ANALYTICS_USER_LIST,
    {
      variables,
      fetchPolicy: 'network-only',
      onError: ({ networkError, graphQLErrors }) => {
        const error = graphQLErrors && graphQLErrors[0];

        if (error) {
          setFeedback({ message: error.message, variant: 'danger' });
        }
      },
    },
  );

  const fetchMoreUser = (limit, offset, filterVariables) =>
    fetchMore({
      query: GET_ANALYTICS_USER_LIST,
      variables: { limit, offset, ...state },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        let userList = {
          ...fetchMoreResult,
          getAnalyticsUserList: {
            ...fetchMoreResult.getAnalyticsUserList,
            analyticsUsers: [
              ...prev.getAnalyticsUserList.analyticsUsers,
              ...fetchMoreResult.getAnalyticsUserList.analyticsUsers,
            ],
          },
        };
        return userList;
      },
    });

  const handleSearchChange = debounce(async (name, value) => {
    const searchVariables = {
      ...state,
      [name]: value,
    };

    setState({
      ...searchVariables,
    });

    refetch({
      ...searchVariables,
    });
  }, 1000);

  const handleFilterChange = debounce(async (name, value) => {
    const filterVariables = {
      ...state,
      [name]: value.slug,
    };

    setState({
      ...filterVariables,
    });

    refetch({
      ...filterVariables,
    });
  }, 300);

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Card.Title as="h6">{header.admin_dashboard}</Card.Title>
              </Col>
            </Row>
            <Tab.Container
              id="admin-tab-container"
              defaultActiveKey={activeTab}
              activeKey={activeTab}
            >
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link
                    onClick={() => setActiveTab('overview')}
                    eventKey="overview"
                    className="admin-tab-item"
                  >
                    Overview
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    onClick={() => setActiveTab('user-onboarding')}
                    eventKey="user-onboarding"
                    className="admin-tab-item"
                  >
                    User Onboarding
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {/* PANELS */}
              <Tab.Content>
                <Tab.Pane
                  eventKey="overview"
                  className="analytics-overview-admin"
                >
                  {activeTab === 'overview' ? (
                    <AdminAnalytics {...props} />
                  ) : (
                    <></>
                  )}
                </Tab.Pane>

                <Tab.Pane
                  eventKey="user-onboarding"
                  className="analytics-overview-admin"
                >
                  {activeTab === 'user-onboarding' ? (
                    <>
                      <Row>
                        <Col>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Control
                              name="filterString"
                              type="text"
                              placeholder={'Search'}
                              onChange={(e) =>
                                handleSearchChange(
                                  e.target.name,
                                  e.target.value,
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={'auto'} style={{ minWidth: '100px' }}>
                          <HsAccountTypeFilter
                            onChange={(value) =>
                              handleFilterChange('userType', value)
                            }
                          />
                        </Col>
                        <Col xs={'auto'} style={{ minWidth: '100px' }}>
                          <HsCountryFilter
                            onChange={(value) =>
                              handleFilterChange('countryId', value)
                            }
                          />
                        </Col>
                      </Row>
                      {data &&
                        data.getAnalyticsUserList &&
                        data.getAnalyticsUserList.total !== 0 && (
                          <Row className="my-4">
                            There are {data.getAnalyticsUserList.total} users on
                            the platform
                          </Row>
                        )}
                      {data &&
                        data.getAnalyticsUserList &&
                        data.getAnalyticsUserList.total === 0 && (
                          <Row className="my-4">No users found</Row>
                        )}
                      <AdminUserOnboarding
                        loading={loading}
                        error={error}
                        data={data}
                        fetchMoreUser={fetchMoreUser}
                        isAdmin={true}
                        {...props}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminDashboard;
