import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { strings } from '../../assets/strings/all';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import SelectUser from '../User/SelectUser';
import { ADD_REMOVE_CASE_SEONDARY_CONSULTATNTS } from './query';

function AddCaseUserModal(props) {
  const {
    show,
    uuid,
    onClose,
    secondaryConsultants = [],
    canAddNewUser,
  } = props;
  const { cases, common } = strings;

  const countries = ['HK', 'IN', 'MY', 'SG', 'TH', 'TW'];
  const { setFeedback } = useContext(FeedbackContext);

  const [users, setUsers] = useState(secondaryConsultants);
  const [country, setCountry] = useState('');
  const [addRemoveUser] = useMutation(ADD_REMOVE_CASE_SEONDARY_CONSULTATNTS, {
    onCompleted: (data) => {
      setFeedback({ message: data.users.message, variant: 'success' });
      onClose();
    },
  });

  return (
    <>
      <Modal
        // {...this.props}
        show={show}
        onHide={onClose}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const userUuids = [];
            const userEmails = [];

            users.map((user) => {
              if (user.uuid) {
                userUuids.push(user.uuid);
              } else if (user.email) {
                userEmails.push(user.email);
              }
              return true;
            });

            const variables = { userEmails, userUuids, uuid };

            addRemoveUser({ variables });
          }}
        >
          <Modal.Body>
            <h6>{cases.add_members}</h6>
            <p className="modal-description muted-text">
              {cases.add_coowners_desc}
            </p>
            <Col>
              <Row
                style={{ justifyContent: 'space-between', margin: '10px 0' }}
              >
                <span>Members</span>
                <span>Country</span>
              </Row>
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <SelectUser
                  value={users}
                  variables={{ uuid }}
                  isMulti={true}
                  label=""
                  onChange={(e) => {
                    setUsers(e);
                  }}
                  country={country}
                  canAddNewUser={canAddNewUser}
                />
              </div>
              <Form.Control
                style={{
                  width: '80px',
                }}
                as="select"
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">All</option>
                {countries
                  .sort((textA, textB) =>
                    textA < textB ? -1 : textA > textB ? 1 : 0,
                  )
                  .map((e, idx) => (
                    <option key={idx} value={e}>
                      {e}
                    </option>
                  ))}
              </Form.Control>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Row className="row-small">
              <Col>
                <Button variant="light" onClick={() => onClose()}>
                  {common.cancel}
                </Button>
              </Col>
              <Col>
                <Button
                  // disabled={showNotes && !notes}
                  variant="primary"
                  type="submit"
                >
                  {common.save}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddCaseUserModal;
