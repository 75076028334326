import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { getCsrfToken } from '../../common/functions';
import HtmlRenderer from '../../components/Common/HtmlRenderer/HtmlRenderer';
import { GET_TERMS_AND_CONDITIONS } from '../Auth/query';

const TermsAndCondition = (props) => {
  const [getTnc, { data }] = useLazyQuery(GET_TERMS_AND_CONDITIONS);

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  async function getTermsAndConditions() {
    const res = await getCsrfToken();

    if (res) {
      getTnc();
    }
  }

  return (
    <div className="terms-conditions-wrapper confirmation-wrapper">
      <>
        <h4 class="terms-conditions-title text-center-alignment text-center">
          {'TERMS AND CONDITIONS'}
        </h4>
        <br />
        <div className="terms-conditons-modal-content">
          <HtmlRenderer content={data && data.get_tnc.value} />
        </div>
      </>
    </div>
  );
};

export default TermsAndCondition;
