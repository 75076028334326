export const strings = {
  all_medical_audit: 'All Medical Audit Logs',
  new_log_button: 'Add New Log',
  medical_audit_log_title: 'New Medical Audit Log',
  submit: 'Submit',
  patient_details: 'Patient’s Details',
  age: 'Age',
  month: 'Month',
  year: 'Year',
  age_title: 'Only positive integer except 0',
  sex: 'Sex',
  male: 'Male',
  female: 'Female',
  company: 'Company',
  private: 'Private',
  insurance: 'Insurance',
  others: 'Others',
  visit_details: 'Visit Details',
  visit_date: 'Visit Date',
  visit_date_placeholder: 'Select the visit date',
  visit_type: 'Visit Type',
  new: 'New',
  repeat: 'Repeat',
  change: 'Change',
  place_of_visit: 'Place of Visit',
  company_clinic: 'Company Clinic',
  hospital: 'Hospital',
  private_clinic: 'Private Clinic',
  diagnosis_theraies: 'Diagnosis & Therapies',
  diagnosis: 'Diagnosis',
  send: 'Send',
  send_mail: 'Send Mail',
  send_excel_mail: 'Send Extract To E-Mail',
  search_user: 'Search User',
  download: 'Download',
  symptom_diagnosed: 'Symptom Diagnosed',
  add_diagnosis: 'Add Diagnosis',
  therapy_medication: 'Therapy/Medication',
  therapy_medicine: 'Therapy/Medicine',
  therapy_medicine_placeholder: 'Enter first 3 characters of therapy/medicine',
  therapy_type: 'Therapy Type',
  dosage_type: 'Dosage Type',
  dosage: 'Dosage',
  quantity: 'Quantity',
  times_per_day: 'Times Per Day',
  daily: 'Daily',
  period: 'Period (Frequency)',
  add_therapy_medication: 'Add Therapy/Medication',
  on_demand: 'On-Demand Dosage',
  single: 'Single-Dose',
  normal: 'Normal',
  load_more: 'Load More',
  add_drug: 'Add Custom Drug',
  manufacturer_description: 'Manufacturer Description (Optional)',
  pack_description: 'Pack Description',
  PACKDESC_placeholder: 'Enter drug name',
  MANFDESC_placeholder: 'Enter Manufacturer Description',
  product_description: 'Product Description',
  PRODDESC_placeholder: 'Enter Product Description',
  symptoms_placeholder: 'Enter first 3 characters of symptom',
  close: 'Close',
  save_changes: 'Save Changes',
  save: 'Save',
  view_details: 'View Details',
  age_placeholder: 'Enter',
  times_placeholder: 'e.g. 1',
  remove: 'Remove',
  medical_code: 'Medical Code',
  add_new_user: 'Add New User',
  mebos_id: 'Mebos Id',
  onboard_medical_user: 'Onboard Medical User',
  create_user: 'Create User',
  first_name: 'First Name',
  last_name: 'Last Name',
  msg_success: 'Data saved successfully!!',
  msg_continue: 'Would you like to create new medical audit logs?',
  continue: 'Continue',
  msg_failure: 'Something went wrong!!',
  go_back: 'Go Back',
  email: 'Email',
  speciality: 'Speciality',
  gender: 'Gender',
  contact_number: 'Contact Number',
  copy_password: 'Copy Password',
  copy_password_reference: ' Copy your password for your reference',
  dublicate_medicine: 'Therapy/Medicine can not be dublicate',
  empty_symptom: 'Diagnosis/Symptom cannot be empty',
  number_boxes: 'Number of Boxes',
  mode_of_treatment: 'Mode of Treatment',
  in_person: 'In Person',
  teleconsultation: 'Teleconsultation',
  visit_on: 'Visit On',
  at: 'at',
  female_old: 'Old',
  male_old: 'Old',
  added_on: 'Added on',
  upload_drugs: 'Upload Drugs',
  upload_drugs_label: 'Upload Drugs List (Excel file only)',
  upload_placeholder: 'Upload an attachment',
  country: 'Country',
  download_template: 'Download Template',
  success_message: 'Data has been uploaded successfully',
  failure_message: 'Something went wrong',
  upload_diagnosis: 'Upload Diagnosis',
  start_date_ma: 'Medical Audit Start Date',
  end_date_ma: 'Medical Audit End Date',
  start_date_placeholder: 'Enter Start Date',
  end_date_placeholder: 'Enter End Date',
  upload_symptoms_label: 'Upload Symptoms List (Excel file only)',
  mebos_id_label: '(for multiple ids please use enter key)',
  optional: '(Optional)',
  create_password: 'Enter first name and last name for generating password',
  errors: {
    year_required: 'Year is required',
    month_required: 'Month is required',
    age_zero: 'Age should not be zero',
    fname_required: 'First Name is required',
    lname_required: 'Last Name is required',
    phone_required: 'Contact Number is required',
    mebos_id_required: 'Mebos ID is required',
    medical_code_reuired: 'Medical Code is required',
    email_required: 'Email is required',
    incorrect_email: 'Email format is not correct',
    date_required: 'Visit Date is required',
    symptoms_required: 'Symptom Diagnosed is required',
    therapy_required: 'Therapy/Medicine is required',
    dosageQuantity_required: 'Dosage is required',
    period_required: 'Period required',
    quantity_required: 'quantity is required',
    quantity_zero: 'quantity should not be zero',
    times_per_day_required: 'Times per day is required',
    times_per_day_zero: "Times per day can't be zero",
    PACKDESC_required: 'Package Description is required',
    MANFDESC_required: 'Manufacture Description is required',
    PRODDESC_required: 'Product Description is required',
    file_required: 'File is mendatory',
    ma_enabled_start_date_error: 'Start date should be smaller then end date',
    ma_enabled_start_date_required: 'Start date is required',
    ma_enabled_end_date_required: 'End date is required',
    country_id_required: 'Country is required',
    fname_invalid: 'Name should not contain invalid character',
    lname_invalid: 'Name should not contain invalid character',
  },
  no_medical_audit: 'No medical audit',
};
