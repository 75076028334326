import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { GET_CASES_COUNT } from '../CaseList/query';

function TabHeaderContent(props) {
  const { text, variables, query = GET_CASES_COUNT } = props;

  const { data } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {text} ({data && data.count ? data.count : 0})
    </>
  );
}
export default TabHeaderContent;
