import { strings as review } from './review';
import { strings as header } from './header';
import { strings as article } from './article';
import { strings as cases } from './case';
import { strings as profile } from './profile';
import { strings as news } from './news';
import { strings as group } from './group';
import { strings as analytics } from './analytics';
import { strings as home } from './home';
import { strings as event } from './event';
import { strings as post } from './post';
import { strings as common } from './common';
import { strings as job } from './job';
import { strings as webinar } from './webinar';
import { strings as microsite } from './microsite';
import { strings as search } from './search';
import { strings as conversation } from './conversation';
import { strings as auth } from './auth';
import { strings as landing } from './landing';
import { strings as users } from './users';
import { strings as survey } from './survey';
import { strings as notification } from './notification';
import { strings as errors } from './errors';
import { strings as medicalAudit } from './medicalAudit';
import { strings as conferenceProceedings } from './conferenceProceedings';
import { strings as econsulting } from './econsulting';
import { strings as validation } from './validation';
import { strings as action } from './action';
import { strings as journals } from './journal';

const all_strings = {
  article,
  action,
  cases,
  review,
  home,
  news,
  header,
  users,
  profile,
  group,
  event,
  post,
  common,
  analytics,
  job,
  webinar,
  search,
  conversation,
  microsite,
  auth,
  landing,
  survey,
  notification,
  errors,
  medicalAudit,
  conferenceProceedings,
  econsulting,
  validation,
  journals,
};

export const strings = all_strings;
