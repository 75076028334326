import React, { useEffect, useState } from 'react';

export default function HsRadioGroup(props) {
  const { labels, className, defaultValue } = props;
  const [selected, setSelected] = useState(labels[0]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [leftPx, setLeftPx] = useState(0);
  useEffect(() => {
    if (defaultValue) {
      const foundIdx = labels.findIndex(
        (label) => label.slug === defaultValue.slug,
      );
      if (foundIdx !== -1) {
        handler(props.name, labels[foundIdx], foundIdx);
      }
    }
  }, []);
  const handler = (name, label, index) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
      setSelected(label);

      setTimeout(() => {
        const child = document.getElementById(
          `label-${props.className}-${index}-${props.details}-${index}`,
        );

        setLeftPx(child.offsetLeft);
      }, 0);

      props.onToggle(name, label);
    }
  };

  const LabelList = (props) => {
    const isChecked = selectedIndex === props.id;
    let label_id = props.button_id !== undefined ? props.button_id : 0;

    const attr = {};
    if (isChecked) {
      attr.defaultChecked = true;
    }

    return (
      <>
        <input
          name={props.name}
          id={`${props.className}-${props.id}-${props.details}-${label_id}`}
          type="radio"
          onChange={(e) => props.onHandle(props.name, props.value, props.id)}
          {...attr}
        />
        <label
          htmlFor={`${props.className}-${props.id}-${props.details}-${label_id}`}
          id={`label-${props.className}-${props.id}-${props.details}-${label_id}`}
          className={`${props.className}__label`}
          // onClick={() => {
          //   props.onToggle(props.name, props.value);
          // }}
        >
          {props.value.name}
        </label>
      </>
    );
  };

  const toggleItem = labels.map((label, index) => (
    <LabelList
      {...props}
      value={label}
      button_id={index}
      id={index}
      key={`radio-${label}-${index}`}
      onHandle={(label, name) => handler(label, name, index)}
    />
  ));

  return (
    <div className="form-toggle-button">
      <div className={className} id={className}>
        {toggleItem}
        <label style={{ left: leftPx }} className="label__indicator">
          {selected.name}
        </label>
      </div>
    </div>
  );
}
