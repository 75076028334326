import { useMutation } from '@apollo/react-hooks';
import React, { useContext, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { strings } from '../../assets/strings/all';
import { storeDataToLocalStorage } from '../../common/functions';
import { FeedbackContext } from '../Context/FeedbackContext';
import AuthMaster from '../Layout/AuthMaster';
import { AUTO_LOGIN } from './query';

function AutoLogin(props) {
  const { auth: authString } = strings;
  const { setFeedback } = useContext(FeedbackContext);
  const { token } = props.match.params;

  const [signin, { loading }] = useMutation(AUTO_LOGIN, {
    variables: { token },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      if (data && data.autoLogin) {
        setTimeout(async () => {
          await storeDataToLocalStorage('token', data.autoLogin.token, true);
          setFeedback({});
          props.history.replace(`/cases`);
        }, 50);
      }
    },
  });

  useEffect(() => {
    signin();
  }, [signin]);

  return (
    <AuthMaster {...props}>
      {(context) => {
        return !loading ? (
          authString.signin
        ) : (
          <>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            {authString.signingin}
          </>
        );
      }}
    </AuthMaster>
  );
}

export default AutoLogin;
