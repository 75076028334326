import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import HsFileBox from './HsFileBox';
import HsImage from '../Common/HsImage';
import HsVideoPayer from './HsVideoPlayer';
import HsPDFViewer from './HsPDFViewer';

class HsMediaSlider extends Component {
  state = {
    itemActive: this.props.itemActive || 0,
  };

  setActiveItem = (itemActive) => {
    if (itemActive !== this.state.itemActive) this.setState({ itemActive });
  };

  getActiveItem = (itemActive) => {
    this.setActiveItem(itemActive);
  };

  componentDidMount = () => {
    this.setupSlider();
    // window.addEventListener('resize', this.setupSlider());
  };

  setupSlider = () => {
    const sliderWrapper = this.refs.sliderWrapper;
    if (sliderWrapper) {
      const itemCount = sliderWrapper.children.length;

      const { type } = this.props;
      let factor = 1;
      if (type === 'file') {
        factor = 0.5;
      } else if (type === 'image' && itemCount > 1) {
        factor = 0.6;
      }

      const { sliderContainer } = this.refs;
      let containerWidth = sliderContainer.clientWidth;
      const containerHeight = window.innerHeight - 150;

      if (type === 'all') {
        if (window.innerHeight < containerWidth) {
          containerWidth = window.innerHeight * 0.6;
        }
        containerWidth = window.innerWidth - 100;

        sliderContainer.style.width = `${containerWidth}px`;
        sliderContainer.style.height = `${containerHeight}px`;
      }

      let width = containerWidth * factor;

      if (type === 'thumbnail') {
        width = 96;
      }

      const postMediaChild = this.refs.postMediaChild;

      if (postMediaChild) {
        postMediaChild.style.clientWidth = width;
        const wrapperWidth = width * itemCount;
        const active = this.state.itemActive;
        let maxItemActive = Math.ceil(wrapperWidth / width);
        // let maxItemActive = itemCount;
        if (type === 'file') {
          maxItemActive = itemCount / 2;
        }
        if (type === 'thumbnail') {
          maxItemActive = Math.ceil(
            itemCount / Math.ceil(containerWidth / width),
          );
        }

        maxItemActive -= 1;

        this.setState({ itemActive: active });
        this.setState({ itemWidth: width });
        this.setState({ itemCount });
        this.setState({ wrapperWidth });
        this.setState({ maxWidth: containerWidth });
        this.setState({ wrapperTranslate: width * active });
        this.setState({ maxItemActive });
        this.setState({ containerHeight });
      }
    }
  };

  setWrapperStyle = () => {
    const { wrapperWidth, itemActive, itemWidth, maxWidth } = this.state;
    const sliderWrapper = this.refs.sliderWrapper;
    if (!sliderWrapper) {
      return;
    }

    const { type } = this.props;
    let transform = 0;

    if (type === 'image') {
      transform =
        itemActive === 0
          ? 0
          : itemActive === 1
          ? itemWidth - (maxWidth - itemWidth) / 2
          : itemWidth * (itemActive - 1) +
            (itemWidth - (maxWidth - itemWidth) / 2);
    } else {
      transform = itemActive * maxWidth;
    }

    // if (
    //   transform > wrapperWidth - maxWidth - 12 &&
    //   wrapperWidth - maxWidth - 12 > 0
    // ) {
    //   transform = wrapperWidth - maxWidth - 12;
    // }

    if (transform > wrapperWidth - maxWidth && wrapperWidth - maxWidth > 0) {
      transform = wrapperWidth - maxWidth;
    }

    // // if (type === 'image') {
    // transform += 12;
    // // }

    // if (transform < 24) {
    //   transform = 0;
    // }

    return {
      width: wrapperWidth,
      transition: 'all 1s',
      transform: `translate3d(${-1 * transform}px, 0px, 0px)`,
    };
  };

  render() {
    const { files, type, sendActiveItem } = this.props;
    let { itemActive, maxItemActive } = { ...this.state };

    if (this.props.itemActive) {
      itemActive = this.props.itemActive;
    }

    return (
      <>
        <div
          className={`slider-parent ${type}-slider-parent`}
          ref="sliderParent"
        >
          <div className={`slider-container`} ref="sliderContainer">
            {itemActive !== 0 && (
              <div
                className="left-arrow-image-slider arrow-image-slider"
                onClick={(e) => {
                  this.setState((prevState) => ({
                    itemActive:
                      prevState.itemActive >= 1 ? prevState.itemActive - 1 : 0,
                  }));
                }}
              >
                <span className="icon-ico-dropdown left-dropdown" />
              </div>
            )}
            {itemActive < maxItemActive && (
              <div
                className="right-arrow-image-slider arrow-image-slider"
                onClick={(e) => {
                  this.setState((prevState) => ({
                    itemActive:
                      prevState.itemActive < maxItemActive
                        ? prevState.itemActive + 1
                        : maxItemActive,
                  }));
                }}
              >
                <span className="icon-ico-dropdown right-dropdown" />
              </div>
            )}

            <Row
              className="m-0 slider-wrapper"
              ref="sliderWrapper"
              style={this.setWrapperStyle(itemActive)}
            >
              {files.map((file, index) => {
                return (
                  <Col
                    key={index + 1}
                    ref="postMediaChild"
                    onClick={() => {
                      if (sendActiveItem) {
                        sendActiveItem(index);
                      }

                      if (this.props.setOpenModal && file.type === 'image') {
                        this.props.setOpenModal(true);
                      }
                    }}
                    className={`${
                      itemActive === index ? 'active' : ''
                    } post-media-child centerFlex`}
                    style={{
                      width: this.state.itemWidth,
                      maxWidth: this.state.itemWidth,
                    }}
                  >
                    <div
                      className={(
                        type === 'image' ||
                        (type === 'thumbnail' ? 'squareWrapper' : '')
                      ).toString()}
                    >
                      <div
                        className={`${
                          type === 'image' ||
                          (type === 'thumbnail' ? 'squareContent' : '')
                        } centerFlex ${file.fileType}`}
                        style={
                          type === 'thumbnail'
                            ? {}
                            : { height: this.state.containerHeight }
                        }
                      >
                        {
                          {
                            image: (
                              <div className="pos-rel">
                                <HsImage
                                  src={
                                    type === 'all' ? file.url : file.thumbnail
                                  }
                                  className="img-max-responsive slider-image img-responsive"
                                  style={
                                    file.downloadable
                                      ? {}
                                      : { pointerEvents: 'none' }
                                  }
                                />
                              </div>
                            ),
                            video: (
                              <HsVideoPayer
                                src={type === 'all' ? file.url : file.thumbnail}
                                type={type}
                                className="img-max-responsive slider-video"
                              />
                            ),
                            file: <HsFileBox file={file} type={type} />,
                            pdf:
                              type === 'all' ? (
                                <HsPDFViewer url={file.url} />
                              ) : (
                                <HsFileBox file={file} type={type} />
                              ),
                          }[file.fileType]
                        }
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>

        {type === 'all' && (
          <HsMediaSlider
            files={files}
            type="thumbnail"
            itemActive={itemActive}
            sendActiveItem={this.getActiveItem}
          />
        )}
      </>
    );
  }
}

export default HsMediaSlider;
