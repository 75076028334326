import React from 'react';
import { components } from 'react-select';

const Option = (props) => {
  const newProps = {
    ...props,
    className: `hs-search-option ${
      props.isFocused ? 'hs-search-option-focused' : ''
    } ${props.isSelected ? 'hs-search-option-selected' : ''}`,
  };

  return <components.Option {...newProps} />;
};

export default Option;
