import React from 'react';
import Image from 'react-bootstrap/Image';
import { forceRelative } from '../../../common/functions';

function CaseUserImageGroup(props) {
  const { users } = props;
  const number = users.length;
  const caseUsers = [...users].splice(0, 3);

  let classType = 'multi';

  if (number === 1) {
    classType = 'one';
  } else if (number === 2) {
    classType = 'two';
  }
  return (
    <div className={`owner-image-group owner-image-group-${classType}`}>
      {caseUsers.map((user) => (
        <Image
          key={`${user.uuid}-image-user`}
          className="owner-image"
          src={forceRelative(user.avatar)}
        />
      ))}
    </div>
  );
}

export default CaseUserImageGroup;
