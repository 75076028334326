import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const ShouldShareModal = (props) => {
  const { open, close, slug } = props;
  const history = useHistory();
  return (
    <Modal size="md" show={open} onHide={close} centered>
      <Modal.Header>
        {' '}
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
          }}
        >
          Make the case public
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'left' }}>
        <p style={{ fontSize: '14px' }}>
          By clicking this button, this case may be duplicated and shared with
          all registered CAVi users.
        </p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          onClick={() => {
            history.push(`/cases/share/${slug}`);
            close();
          }}
        >
          Share
        </Button>
        <Button variant="outline-danger" onClick={close}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShouldShareModal;
