export const strings = {
  publish: 'Publish',
  published: 'Published',
  edit_case: 'Edit Case',
  edit_draft: 'Edit Draft',
  new_case: 'New Case',
  speciality_placeholder: 'Choose Speciality',
  save_as_draft: 'Save As Draft',
  archive_case: 'Archive Case',
  title: 'Edit title',
  title_placeholder: 'Enter title',
  speciality: 'Speciality',
  brief: 'Brief',
  brief_placeholder: 'Write here...',
  tags: 'Tags (Add tags to help us show you relevant search results)',
  mentions: 'Mentions',
  add_mention: 'Add Mention',
  add_tag: 'Add tags',
  source: 'Source',
  all_specialities: 'All Specialities',
  source_placeholder: 'Paste URL link here and/or upload an attachment',
  search_placeholder: 'Search Cases',
  disclaimer: 'Disclaimer',
  disclaimer_desc:
    'Use of patient’s personal information not limited to gender & age is against confidentiality policy & IQVIA takes no responsibility if you violate the policy.',
  age: 'Patient’s Age',
  age_placeholder: 'Enter age (e.g. 52)',
  gender_only: 'Gender',
  gender: 'Patient’s Gender',
  mention_people: 'Mention Peoples : ',
  genderList: {
    female: 'Female',
    male: 'Male',
    other: 'Other',
    not_specified: 'Not Specified',
  },
  optional: 'Optional',
  errors: {
    age_max: 'Age should not be greater than 150',
    cover_required: 'You need to upload case cover image',
    cover_uploading: 'Cover is being uploaded',
    title_required: 'Case Title is required',
    title_min_length: 'Case title has to be min 3 characters',
    title_max_length: 'Case title cannot be more than 250 characters',
    brief_required: 'Case Brief is required',
    brief_min_length: 'Case Brief has to be min 3 characters',
    brief_max_length: 'Case Brief cannot be more than 250 characters',
    source_url: 'Source should be valid URL',
    age_number: 'Age should always be a number',
    age_min: 'Age should be greater then zero',
    source_max_limit: 'Source cannot be more than 300 characters',
    description_required: 'Case description is required',
    description_min_length: 'Case description has to be min 3 characters',
    description_max_length:
      'Case description cannot be more than 2500 characters',
    speciality_id_required: 'Please choose a speciality',
  },
  case_title: 'Cases /Questions',
  all_case: 'All Cases /Questions',
  need_help: 'Need help with a Case?',
  following_cases: 'Following Cases',
  review_cases: 'Review',
  my_cases: 'My Cases /Questions',
  publish_case: 'Publish a Case',
  view: 'Views',
  follow: 'Following',
  like: 'liked',
  cancel: 'Cancel',
  comment: 'comments',
  delete_draft: 'Delete Draft',
  draft: 'Drafts',
  discard_changes: 'Discard Changes',
  delete_case: 'Delete Case',
  delete_Draft: 'Delete Draft',
  cases_by: 'Cases By {name}',
  unarchived: 'Unarchive',
  archived_button: 'Archived',
  archived: 'Archive',
  edit: 'Edit',
  see_all_cases: 'See all {num} cases',
  published_ago: 'Created {date}',
  published_on: 'Published on {date}',
  last_published: 'Last updated {date}',
  last_archived: 'Archived on {date}',
  num_attachments: '{num} Attachment',
  saving_changes_confirmation:
    'Are you sure you want to go back without saving changes?',
  saving_changes_confirmation_desc:
    'You have unsaved changes. You will lose all recent changes and this action cannot be reversed',
  delete_case_confirm: 'Are you sure you want to delete?',

  invite_commenters: 'Invite Commenter(s)',
  invite_commenters_desc: 'Invite other users to comment on this case',
  add_members: 'Add Member(s)',
  add_coowners_desc: 'add another HCPs who can contribute to this case',
  case_owner: 'Case Owner',
  case_member: 'Case Member(s)',
  case_disclaimer_body:
    'Any patient identifiable information shall not be displayed, uploaded or distributed through the Platform. Platform’s users who provide any patient identifiable information assume the full responsibility for complying with the applicable local data privacy laws and shall be responsible for obtaining and maintaining any notices, consents or approvals necessary to make such information available to others.',

  case_disclaimer_body_2:
    'If you find any patient identifiable information being shared on the Platform or have any other concern over the content of the Platform, we request you to please report this to the Platform team on DLE-HCPSupport@imshealth.com.',
  case_disclaimer_body_3:
    'I confirm that this submission is anonymized to ensure patient confidentiality is not being compromised.',

  case_disclaimer_body_4:
    'Submissions once shared cannot edited. Please press Cancel if you need to make any changes',
  request_to_publish: 'Request to publish',
  submit: 'Submit',
  rate_case: 'Rate Case/Question',
  rate_case_description:
    'How well were your concerns addressed in this discussion?',
  recommend_pfizer: 'Would you recommend it to other HCPs',
  improvement_recommendations: 'Any recommendations for improvement?',
  call: 'Schedule a call',
  call_header: '* Available in the mobile app',
  call_description:
    'Set-up a call to discuss with the nominated experts and other participants of this case. You may use the case comments to fix the meeting time.',
  close: 'Close',
  comments: 'Comments',
  panel_members: 'Panel Members',
  // case_details: 'Case/ Question Details',
  go_back: 'Go back',
  closure_reason: 'Resolution Note',
  yes: 'Yes',
  no: 'No',
  next: 'Next',
  select_reason: 'Select a reason',
  resolve_case: 'Close Case/Question',
};
