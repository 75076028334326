import React, { useState } from 'react';
import HtmlRenderer from './HtmlRenderer';
import { strings } from '../../../assets/strings/all';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function HsDescription(props) {
  const [showMore, setShowMore] = useState();
  const history = useHistory();
  const { description } = props;
  let lessDescription = description;
  let hasMore = false;
  if (
    (description && description.length > 1220) ||
    (description && description.split(/\r\n|\r|\n/).length > 2)
  ) {
    // const descriptionArray = lessDescription.split('\n');
    // lessDescription = `${descriptionArray[0]}\n${descriptionArray[1]}`;
    lessDescription = lessDescription.substring(0, 1220);
    hasMore = true;
  }

  const { common } = strings;

  return !hasMore ? (
    <div
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        {
          try {
            const link = description.match(/CAVI-\d\d\d/g)[0];
            if (link) {
              history.push('/cases/' + link);
            }
          } catch (e) {}
        }
      }}
    >
      <HtmlRenderer content={description} />
    </div>
  ) : (
    <>
      <HtmlRenderer
        content={!showMore ? lessDescription : description}
        escapeHtml={true}
      />
      <span className="show-more" onClick={() => setShowMore(!showMore)}>
        {!showMore ? common.show_more : common.show_less}
      </span>
    </>
  );
}

export default HsDescription;
