import React from 'react';
import { components } from 'react-select';
const SingleValue = (props) => {
  const { children, selectProps } = props;
  return (
    <components.SingleValue {...props}>
      {selectProps.defaultIcon ? (
        <i className={selectProps.defaultIcon} />
      ) : (
        <></>
      )}
      {children}
    </components.SingleValue>
  );
};

export default SingleValue;
