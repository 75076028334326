import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { GET_USER_ANALYTICS } from './query';
import UserAnalyticsProfile from './UserAnalyticsProfile';
import LicenseList from './LicenseList';
import permissionList from '../../common/permissions';
import { userHasPermission } from '../../common/functions';
import { UserContext } from '../../containers/Context/UserContext';
import { strings } from '../../assets/strings/all';

const UserAnalyticsDetails = (props) => {
  const { match } = props;
  const { user_slug } = match.params;
  const { users: userString } = strings;

  const { GET_ANALYTICS } = permissionList;
  const { user } = useContext(UserContext);

  const canGetAnalytics = userHasPermission(user, GET_ANALYTICS);

  if (!canGetAnalytics) {
    props.history.replace(`/cases`);
  }

  const [activeTab, setActiveTab] = useState('overview');

  const { setFeedback } = useContext(FeedbackContext);
  const history = useHistory();

  const [variables, setVariables] = useState({
    username: user_slug,
  });

  const { loading, error, data } = useQuery(GET_USER_ANALYTICS, {
    variables,
    fetchPolicy: 'network-only',
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
        history.push('/cases');
      }
    },
  });

  if (loading || error || isEmpty(data)) {
    return 'Loading';
  }

  const { getUserAnalytics: userObj } = data;

  // const getUserData = (newVariable) => {
  //   setVariables({
  //     ...newVariable,
  //   });
  // };

  return (
    <>
      <Row>
        <Col md={12}>
          <Tab.Container
            id="admin-tab-container"
            defaultActiveKey={activeTab}
            activeKey={activeTab}
          >
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link
                  onClick={() => setActiveTab('overview')}
                  eventKey="overview"
                  className="admin-tab-item"
                >
                  {userString.user_details}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  onClick={() => setActiveTab('user-onboarding')}
                  eventKey="user-onboarding"
                  className="admin-tab-item"
                >
                  {userString.license}
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {/* PANELS */}
            <Tab.Content>
              <Tab.Pane
                eventKey="overview"
                className="analytics-overview-admin"
              >
                <UserAnalyticsProfile
                  {...userObj}
                  setVariables={setVariables}
                  variables={variables}
                />
              </Tab.Pane>

              <Tab.Pane
                eventKey="user-onboarding"
                className="analytics-overview-admin"
              >
                <LicenseList {...userObj} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </>
  );
};

export default UserAnalyticsDetails;
