import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';

class DateTimeComponent extends Component {
  render() {
    return (
      <>
        <Form.Group className="m-0 react-date-select-input">
          <Form.Control
            type="text"
            placeholder={this.props.placeholder}
            onClick={this.props.onClick}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            value={this.props.value}
          />
        </Form.Group>
        <span
          className="licence-form-calender btn btn-black"
          onClick={!this.props.disabled ? this.props.onClick : null}
        >
          <span className="icon icon-ico-date" />
        </span>
      </>
    );
  }
}

export default class CustomDateTime extends Component {
  render() {
    return (
      <DatePicker
        customInput={<DateTimeComponent />}
        className={this.props.className}
        selected={this.props.data}
        showMonthDropdown
        showYearDropdown
        dateFormat={
          this.props.showTimeSelect ? 'dd-MMM-yyyy, hh:mm aa' : 'dd-MMM-yyyy'
        }
        value={this.props.value !== undefined ? this.props.value : null}
        name={this.props.name}
        placeholderText={this.props.placeholder}
        disabled={this.props.disabled}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        onChange={this.props.onChange}
        {...this.props}
        minTime={this.props.minTime}
        maxTime={this.props.maxTime}
      />
    );
  }
}
