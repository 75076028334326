import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { getCsrfToken } from '../../common/functions';
import HtmlRenderer from '../../components/Common/HtmlRenderer/HtmlRenderer';
import { GET_PRIVACY_POLICY } from '../Auth/query';

const PrivacyPolicy = (props) => {
  const [getPrivacy, { data }] = useLazyQuery(GET_PRIVACY_POLICY);

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  async function getPrivacyPolicy() {
    const res = await getCsrfToken();

    if (res) {
      getPrivacy();
    }
  }

  return (
    <div className="terms-conditions-wrapper confirmation-wrapper">
      <>
        <h4 class="terms-conditions-title text-center-alignment text-center">
          {'PRIVACY POLICY'}
        </h4>
        <br />
        <div className="terms-conditons-modal-content">
          <HtmlRenderer content={data && data.get_privacy.value} />
        </div>
      </>
    </div>
  );
};

export default PrivacyPolicy;
