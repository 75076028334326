import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import CaseUserListItem from '../Case/components/CaseUserListItem';
import { strings } from '../../assets/strings/all';
function JournalDetailsSideCard(props) {
  const {
    data: { createdBy },
  } = props;

  const { journals } = strings;

  return (
    <>
      <Card className="case-detail-side-card">
        <Card.Body>
          <div className="case-side-user-wrapper">
            <div className="case-side-user-wrapper-title">
              {journals.my_journals}
            </div>
            <ListGroup variant="flush">
              <CaseUserListItem
                {...{ user: createdBy }}
                type="primaryConsultant"
              />
            </ListGroup>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default JournalDetailsSideCard;
