import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { FeedbackContext } from '../../../containers/Context/FeedbackContext';
import { LIKE_UNLIKE_ENTITY } from '../../Comment/query';
import { RoleContext } from '../../../containers/Context/RoleContext';

function LikeButton(props) {
  const { entityUuid, entityType, hasLiked, likesCount } = props;
  const { role } = useContext(RoleContext);
  const { setFeedback } = useContext(FeedbackContext);

  const [likeUnlikeEntity] = useMutation(LIKE_UNLIKE_ENTITY, {
    variables: { entityUuid, entityType },
    onCompleted: (data) => {
      const {
        like: { message, success },
      } = data;
      setFeedback({ message, variant: success ? 'success' : 'danger' });
    },
  });

  return (
    <>
      {role !== null && role.name === 'Screener' ? (
        <></>
      ) : (
        <Button
          variant="link"
          onClick={(e) => {
            e.stopPropagation();
            likeUnlikeEntity();
          }}
          className={'action-button'}
        >
          {hasLiked ? (
            <span className="icon icon-ico-like---solid" />
          ) : (
            <span className="icon icon-ico-like" />
          )}

          <span className="action-text">
            {likesCount} {hasLiked ? 'Endorsed' : 'Endorse'}
          </span>
        </Button>
      )}
    </>
  );
}

export default LikeButton;
