import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { strings } from '../../assets/strings/all';

function CaseTemplateEditModal(props) {
  const { show, showExtraField, onClose, updateExtraFieldData } = props;
  const { action } = strings;

  const [extraField, setExtraField] = useState(props.extraField);

  return (
    <>
      <Modal
        // {...this.props}
        show={show}
        onHide={onClose}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h6>Edit Field</h6>

          <Form.Group>
            <Form.Label>Field Name</Form.Label>
          </Form.Group>
          <Form.Control
            type="text"
            name="extraField"
            {...props}
            onChange={(e) => {
              setExtraField({ ...extraField, ...{ name: e.target.value } });
            }}
            value={extraField.name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onClose()}>
            {action.cancel}
          </Button>
          <Button
            disabled={showExtraField && !extraField.name}
            variant="primary"
            onClick={() => {
              updateExtraFieldData(extraField);
              onClose();
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CaseTemplateEditModal;
