import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { strings } from '../../assets/strings/all';
import AddCaseJournalModal from './AddCaseJournalModal';

function AddCaseJournalButton(props) {
  const { journals: journalsString } = strings;
  const { uuid, journals, variant } = props;
  const [show, setShow] = useState();

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Button
        variant={variant ? variant : 'outline-primary'}
        onClick={() => toggleShow()}
        size="sm"
      >
        {journalsString.attach_journals}
      </Button>
      {show ? (
        <AddCaseJournalModal
          type="archive"
          uuid={uuid}
          journals={journals}
          showNotes={true}
          show={show}
          onClose={() => toggleShow()}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default AddCaseJournalButton;
