import React from 'react';

const AlertBar = (props) => {
  const { message } = props;

  return (
    <div
      className="alert alert-warning muted-text"
      style={{ width: '100%', textAlign: 'center' }}
    >
      {message}
    </div>
  );
};

export default AlertBar;
