import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { strings } from '../../assets/strings/all';
import { IdleTimerContext } from '../../containers/Context/IdleTimerContext';

const HsGlobalPopup = () => {
  const { open, title, body, callback, onClose } = useContext(IdleTimerContext);
  const { common } = strings;

  return (
    <>
      <Modal
        show={open ? open : false}
        onHide={onClose}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h6>{title ? title : ''}</h6>
          <p className="modal-description muted-text">{body ? body : ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Row className="row-small">
            <Col>
              <Button variant="light" onClick={() => onClose()}>
                {common.continue}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HsGlobalPopup;
