/**
 * Number.prototype.format(n, x)
 *
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
/*eslint no-extend-native: ["error", { "exceptions": ["Number", "String"] }]*/
Number.prototype.format = function(n, x) {
  var re =
    '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(
    new RegExp(re, 'g'),
    '$&,',
  );
};
