export const strings = {
  conversation_title: 'Messages',
  no_message: 'No Messages Yet?',
  no_data: 'This seems to be the beginning of your conversations',
  start_conversation: 'Start a Conversation',
  search_messages: 'Search Messages',
  inbox: 'Inbox',
  important: 'Important',
  archived: 'Archived',
  new_message: 'New Message',
  add_more_people: 'Add one or more people',
  say_something: 'Say Something...',
  upload_photo: 'Upload a Photo',
  upload_video: 'Upload a Video',
  attach_file: 'Attach a file',
  send: 'Send',
  add_group_title: 'Add a group conversation title (Optional)',
  mark_as_important: 'Mark as Important ',
  archive_conversation: 'Archive Conversation',
  unmark_as_important: 'Unmark as Important ',
  unarchive_conversation: 'Unarchive Conversation',
  delete_conversation: 'Delete Conversation',
  add_participants: 'Add Participants',
  leave_conversation: 'Leave Conversation',
  group_conversation_title: 'Group Conversation Title',
  no_conversations: 'No Conversations',
  no_client_conversation:
    'You are only one in the conversation. You cannot send the message',
  are_you_sure_delete: 'Are you sure you want to delete this conversation?',
  are_you_sure_leave: 'Are you sure you want to leave this conversation?',
};
