import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

function NoData(props) {
  const { message } = props;

  return (
    <div className="no-data no-data-lg">
      {/* <Row>
        <Col>
          <Image src="/api/static/placeholder.svg" />
        </Col>
      </Row> */}
      <Row>
        <Col className="text-center no-data-content">
          <div className="no-data-title"></div>
          <p className="col-content">{message ? message : ''}</p>
          <Link to={'/search/People/suggestions'}>
            {/* <Button variant="light">{network.discover}</Button> */}
          </Link>
        </Col>
      </Row>{' '}
    </div>
  );
}

export default NoData;
