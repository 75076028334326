import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function CaseTemplateFormBottom(props) {
  const { onSubmit, errors, loading } = props;

  const [isDisabled, setIsDisabled] = useState(!isEmpty(errors) || loading);

  useEffect(() => {
    setIsDisabled(!isEmpty(errors) || loading);
  }, [errors, loading]);

  return (
    <div className="case-fixed-footer">
      <div className="case-fixed-footer-wrapper">
        <div className="case-fixed-footer-inner">
          <Row>
            <Col>
              <Row className="justify-end">
                <Col xs="auto">
                  <Button
                    variant="outline-primary"
                    disabled={isDisabled}
                    onClick={() => onSubmit()}
                  >
                    Update Case Template
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default CaseTemplateFormBottom;
