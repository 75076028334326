import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { strings } from '../../assets/strings/all';
import SelectUser from '../User/SelectUser';
import { ADD_REMOVE_CASE_COMMENTOR } from './query';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import { useContext } from 'react';
import { GET_COMMENTORS } from '../User/query';

function InviteCommentorModal(props) {
  const { show, uuid, onClose, commentors = [] } = props;
  const { cases, common } = strings;

  const { setFeedback } = useContext(FeedbackContext);

  const [users, setUsers] = useState(commentors);

  const [addRemoveCommentor] = useMutation(ADD_REMOVE_CASE_COMMENTOR, {
    onCompleted: (data) => {
      setFeedback({ message: data.commentor.message, variant: 'success' });
      onClose();
    },
  });

  return (
    <>
      <Modal
        // {...this.props}
        show={show}
        onHide={onClose}
        size="md"
        className="delete-draft-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const userUuids = [];
            const userEmails = [];

            users.map((user) => {
              if (user.uuid) {
                userUuids.push(user.uuid);
              } else if (user.email) {
                userEmails.push(user.email);
              }
              return true;
            });

            const variables = { userEmails, userUuids, uuid };

            addRemoveCommentor({ variables });
          }}
        >
          <Modal.Body>
            <h6>{cases.invite_commenters}</h6>
            <p className="modal-description muted-text">
              {cases.invite_commenters_desc}
            </p>
            <SelectUser
              query={GET_COMMENTORS}
              value={users}
              variables={{ uuid }}
              isMulti={true}
              label=""
              onChange={(e) => {
                setUsers(e);
              }}
              canAddNewUser={props.canAddNewUser}
            />
          </Modal.Body>
          <Modal.Footer>
            <Row className="row-small">
              <Col>
                <Button variant="light" onClick={() => onClose()}>
                  {common.cancel}
                </Button>
              </Col>
              <Col>
                <Button
                  // disabled={showNotes && !notes}
                  variant="primary"
                  type="submit"
                >
                  {common.invite}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default InviteCommentorModal;
