import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import CaseUserListItem from '../../Case/components/CaseUserListItem';
import { GET_USER_SEARCH } from '../../CaseForm/query';
import './index.css';
const AutoNameSearchCompleteInput = ({
  style = {},
  onSelect = () => {},
  country,
}) => {
  const ref = useRef(null);
  const [value, setValue] = useState('');
  const { loading, data, error } = useQuery(GET_USER_SEARCH, {
    variables: {
      queryString: value,
      country: country !== '' ? country : undefined,
      shouldExclude: false,
    },
  });

  return (
    <div
      style={{
        position: 'relative',
        flex: 1,
        display: 'flex',
      }}
      className="dropdown"
    >
      <input
        ref={ref}
        style={{
          flex: 1,
          padding: '7px 14px',
          borderColor: 'slategray',
          borderWidth: '1px',
          ...style,
        }}
        type="text"
        placeholder="Search by name"
        value={value}
        onChange={(ev) => {
          setValue(ev.target.value);
        }}
      />
      {!loading &&
        data &&
        data.getCaseCoownerSuggestions &&
        data.getCaseCoownerSuggestions.users &&
        data.getCaseCoownerSuggestions.users.length > 0 && (
          <div
            className="dropdown-content"
            style={{
              position: 'absolute',
              top: `${30}px`,
              backgroundColor: 'white',
              maxHeight: '250px',
              // display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              overflowX: 'hidden',
              gap: '4px',
              width: '100%',
              cursor: 'pointer',
              zIndex: 900,
            }}
          >
            {data.getCaseCoownerSuggestions.users.map((entity) => {
              return (
                <div>
                  <CaseUserListItem
                    onClick={() => {
                      onSelect(entity);
                    }}
                    {...{ user: entity }}
                    newPage={true}
                  />
                </div>
              );
            })}
          </div>
        )}
    </div>
  );
};
export default AutoNameSearchCompleteInput;
