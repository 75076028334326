import React, { useContext, useEffect } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function RaceSelect(props) {
  const { organization } = useContext(OrganizationContext);
  const { onChange } = props;
  const { visibilityStatuses = [] } = organization || {};

  const races = [
    'Asian',
    'Caucasian',
    'Black',
    'Oriental',
    'Hispanic',
    'African American',
    'Other',
    'Missing/Unknown',
  ];

  useEffect(() => {
    if (props.value && !props.value.name) {
      onChange({ name: props.value });
    }
  }, []);
  // const privateVisibility = visibilityStatuses.find((obj) => {
  //   return obj.slug === 'private';
  // });

  // useEffect(() => {
  //   if (privateVisibility) {
  //     onChange(privateVisibility);
  //   }
  // }, [privateVisibility]);

  const formattedLabel = (option) => {
    const { name, description } = option;
    return (
      <div className="select-option-wrapper">
        <div className="select-option-name">{name}</div>
        <div className="select-option-description">{description}</div>
      </div>
    );
  };

  return (
    <>
      <HsSelect
        label="Ethnicity"
        placeholder="Ethnicity"
        options={[
          {
            label: 'Ethnicity',
            options: races.map((race) => ({
              name: race,
            })),
          },
        ]}
        getOptionLabel={(option) => formattedLabel(option)}
        defaultValue={props.value}
        onChange={onChange}
        isClearable={false}
        {...props}
        // value={caseData.visibilityStatus}
      />
    </>
  );
}

export default RaceSelect;
