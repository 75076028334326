import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { hasCasePermission, hasPermission } from '../../../common/functions';
import permissionList from '../../../common/permissions';
import CommentReviewWrapper from '../../CommentReview/CommentReviewWrapper';
import InviteCommentorButton from '../InviteCommentorButton';
// import CaseUserDropdown from "./CaseUserDropdown";
import CommentList from '../../Comment/CommentList';
import { strings } from '../../../assets/strings/all';
import { RoleContext } from '../../../containers/Context/RoleContext';

function CaseBottom(props) {
  const { role } = useContext(RoleContext);
  const {
    data: {
      uuid,
      commentsCount,
      caseStatus: { slug: caseStatusSlug },
      commentors = [],
      permissionArray,
      isModerationEnabled,
      id,
    },
    viewType,
  } = props;

  const { cases: caseString } = strings;

  const {
    VIEW_COMMENTS,
    INVITE_CASE_COMMENTOR,
    COMMENT_ON_CASE,
    ADD_NEW_USER,
  } = permissionList;

  const canViewComments = hasPermission(permissionArray, VIEW_COMMENTS);
  const canComment = hasPermission(permissionArray, COMMENT_ON_CASE);
  const canInviteCommentor = hasPermission(
    permissionArray,
    INVITE_CASE_COMMENTOR,
  );
  const canAddNewUser = hasCasePermission(props.data, ADD_NEW_USER);

  if (caseStatusSlug === 'draft' || caseStatusSlug === 'rejected') {
    return <></>;
  }
  return (
    <div className="">
      {viewType !== 'reviewComments' ? (
        <Row className="case-bottom-header">
          <Col>
            {canViewComments ? (
              <div
                style={{
                  fontSize: '.8rem',
                  color: '#215d8d',
                  fontWeight: 'bold',
                }}
              >
                {commentsCount} {caseString.comments}
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col>
            {/* {commentors.length > 0 ? (
              <div>
                <CaseUserDropdown users={[...commentors]} />
              </div>
            ) : (
              <></>
            )} */}

            {canInviteCommentor ? (
              <div className="text-right">
                <InviteCommentorButton
                  type="archive"
                  uuid={uuid}
                  commentors={commentors}
                  canAddNewUser={canAddNewUser}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {viewType === 'reviewComments' ? (
        <CommentReviewWrapper {...props} />
      ) : (
        <></>
      )}
      {canViewComments && viewType !== 'reviewComments' ? (
        <CommentList
          variables={{
            entityType: 'cases',
            entityUuid: uuid,
            limit: 10,
            offset: 0,
            caseId: id,
          }}
          setExpertComment={props.setExpertComment}
          entityType="cases"
          entityUuid={uuid}
          caseUuid={uuid}
          canComment={canComment}
          isModerationEnabled={isModerationEnabled}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default CaseBottom;
