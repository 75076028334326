export const strings = {
  has_invited: 'has invited you to join',
  sent_connection_request: 'has sent you a connection request',
  has_viewed: 'has viewed your profile',
  has_requested_to_join: 'has requested to join',
  has_joined: 'has joined on',
  has_published: 'has published',
  has_posted: 'has posted in',
  action_type: 'has {action} on',
  action_types: {
    followings: 'following',
    likes: 'liked',
    comments: 'commented',
    answers: 'answred',
    cases: 'case',
    blogs: 'blog',
    posts: 'posted',
    microsite_posts: 'posted',
    microsite_blogs: 'article',
  },
  following: 'is now {follwowing}',
  liked: 'has {liked}',
  in_microsite: 'in {microsite} microsite',
  client_follow: ' is now following you',
  like_post: ' has liked your post',
  comment_post: ' has commented on your post',
  like_poll: ' has liked your poll',
  comment_poll: ' has commented on your poll',
  notifications: 'Notifications',
  no_data: 'No Notifications',

  mentions: '{from_client} has mentioned you in their {sub_entity} {place}',
  commented: '{from_client} commented on your {sub_entity} {place}',
  posted: '{from_client} posted {sub_entity} {place}',

  replied: '{from_client} replied on your {sub_entity} {place}',
  followed: '{from_client} started following {sub_entity} {place}',
  likes: '{from_client} liked your {sub_entity} {place}',
  connection_requests: '{from_client} sent you a connection request',
  client_connections: '{from_client} accepted your connection request',
  profile_views: '{from_client} viewed your profile',
  published: '{from_client} published {sub_entity} {place}',
  membership_request_recieved:
    '{from_client} has sent a request to join {sub_entity} {place}',
};
