import React, { useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import { formatPublishedAgoDate } from '../../common/date-functions';
import CaseUserImageGroup from '../Case/components/CaseUserImageGroup';
import HsCaseStatusIcon from '../Common/HsCaseStatusIcon';
import HsCaseType from '../Common/HsCaseType';
import CaseCardBottomAction from './CaseCard/CaseCardBottomAction';
function shortenString(str, n) {
  if (str.length <= n) {
    return str;
  }
  return str.substring(0, n) + '...';
}
function CaseCard(props) {
  const {
    caseData: {
      caseStatus = {},
      caseType = {},
      title,
      inquiryType,
      caseTitle,
      commentsCount,
      followingsCount,
      createdAt,
      primaryConsultants = [],
      secondaryConsultants = [],
      slug,
      originalCaseId,
    },
    showButton,
  } = props;

  const [hovered, setHovered] = useState(false);

  const caseCardRef = useRef();

  const users = [...primaryConsultants, ...secondaryConsultants];

  const { post: postString, news: newsString } = strings;

  const onEnterListener = () => {
    caseCardRef.current.classList.add('hovered');
    setHovered(true);
  };

  const onLeaveListener = () => {
    caseCardRef.current.classList.remove('hovered');
    setHovered(false);
  };
  return (
    <>
      <Card
        className="hoverable"
        ref={caseCardRef}
        onMouseEnter={() => onEnterListener()}
        onMouseLeave={() => onLeaveListener()}
      >
        <Card.Body as={Link} to={`/cases/${slug}`}>
          <div className="mb-2">
            {/* <HsCaseStatusIcon caseStatus={caseStatus} /> */}
            <HsCaseType caseType={caseType} />
          </div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '12px',
            }}
            className="mb-2 twoLineTitle"
          >
            {title}{' '}
            {
              <span
                style={{
                  color: 'teal',
                  marginLeft: '8px',
                  fontSize: '11px',
                }}
              >
                ({props.caseData.visibilityStatus.name}){' '}
                {caseStatus &&
                  (caseStatus.slug === 'open' ||
                    caseStatus.slug === 'close') && (
                    <span>
                      -{' '}
                      {caseStatus.slug.charAt(0).toUpperCase() +
                        caseStatus.slug.slice(1)}
                    </span>
                  )}
              </span>
            }
          </div>
          <div
            className="mb-1"
            style={{
              fontSize: '12px',
            }}
          >
            <strong className="mr-1">{inquiryType}: </strong>{' '}
            <span>{shortenString(caseTitle, 24)}</span>
          </div>
          {true ? (
            <Row className="card-stat-wrapper row-small">
              <Col
                style={{
                  fontSize: '12px',
                }}
                xs="auto"
              >
                {commentsCount === 1
                  ? strings.formatString(postString.num_comment, {
                      num: commentsCount,
                    })
                  : strings.formatString(postString.num_comments, {
                      num: commentsCount,
                    })}
              </Col>
              {/* <Col xs="auto">
                {viewsCount === 1
                  ? strings.formatString(postString.num_view, {
                      num: viewsCount,
                    })
                  : strings.formatString(postString.num_views, {
                      num: viewsCount,
                    })}
              </Col> */}
              {/* <Col xs="auto">
                {followingsCount === 1
                  ? strings.formatString(postString.num_following, {
                      num: followingsCount,
                    })
                  : strings.formatString(postString.num_followings, {
                      num: followingsCount,
                    })}
              </Col> */}
            </Row>
          ) : (
            <></>
          )}
          <div
            style={{
              height: '12px',
            }}
          />
          <div className="case-card-bottom">
            <div>
              {strings.formatString(newsString.submitted_ago, {
                date: formatPublishedAgoDate(createdAt),
              })}
            </div>
            <div className="case-user-wrapper">
              <CaseUserImageGroup users={users} {...props} />
              {users && users.length > 0 ? (
                <div className="users-name">
                  {users[0].fullName}{' '}
                  {users.length > 1 ? `& ${users.length - 1} others` : ''}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Card.Body>
        {showButton ? (
          <div className="case-card-bottom-action-wrapper">
            <CaseCardBottomAction {...props} {...{ showButton, hovered }} />
          </div>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}

export default CaseCard;
