import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { GET_CASES_COUNT } from '../CaseList/query';

function HsTabFilterButton(props) {
  const { text, variables, variant, onClick, query = GET_CASES_COUNT } = props;

  const { data } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
  });

  return (
    <Button {...{ variant, onClick }}>
      {text} ({data && data.count ? data.count : 0})
    </Button>
  );
}

export default HsTabFilterButton;
