import { useSubscription } from '@apollo/client';
import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { NotificationCountContext } from '../../containers/Context/NotificationContext';
import { UREAD_NOTIFICATION_COUNT } from './query';
import { GET_USER_ROLE } from '../../containers/Auth/query';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function NotificationBell(props) {
  const { user } = props;
  const { notificationsCount, setNotificationsCount } = useContext(
    NotificationCountContext,
  );
  const [role, setRole] = useState(null);
  const { data, loading } = useQuery(GET_USER_ROLE);

  useEffect(() => {
    if (role === null && !loading) {
      const { getRole } = data;
      setRole({
        ...getRole,
      });
    }
  }, [data, loading]);
  useEffect(() => {
    setNotificationsCount(user.unreadNotificationsCount);
  }, [setNotificationsCount, user.unreadNotificationsCount]);

  useSubscription(UREAD_NOTIFICATION_COUNT, {
    variables: { uuid: `${user.uuid}` },
    shouldResubscribe: true,
    onSubscriptionData: (response) => {
      const { subscriptionData } = response;
      const { data } = subscriptionData;
      const {
        count: { unreadNotificationsCount },
      } = data;

      try {
        if (unreadNotificationsCount !== notificationsCount)
          setNotificationsCount(unreadNotificationsCount);
      } catch (e) {
        console.log({ FETCH: e });
      }
    },
  });
  if (!role || role.name === 'Screener') return <></>;
  return (
    <Nav.Link
      style={{
        padding: 0,
        width: '43px',
      }}
      as={Link}
      to={`/notifications`}
      className="ico"
    >
      <img
        src="/api/static/bell.svg"
        width={25}
        loading="lazy"
        decoding="async"
      />
      {notificationsCount > 0 ? (
        <span className="badge badge-notify">
          {notificationsCount > 99 ? '99+' : notificationsCount}
        </span>
      ) : (
        <></>
      )}
    </Nav.Link>
  );
}
