import React, { useContext } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function CaseTypeFilter(props) {
  const { organization } = useContext(OrganizationContext);

  const { caseTypes = [] } = organization || {};
  const { type } = props;

  const allCaseTypes = [];
  let defaultValue = null;

  if (type !== 'select') {
    allCaseTypes.push({ slug: null, name: 'All Cancer Type' });
    defaultValue = allCaseTypes[0];
  }
  allCaseTypes.push(...caseTypes);

  return (
    <>
      <HsSelect
        label="Cancer Type"
        placeholder="Cancer Type"
        options={[{ label: 'Cancer Type', options: allCaseTypes }]}
        // defaultIcon="icon-ico-hide"
        defaultValue={defaultValue}
        onChange={props.onChange}
        isClearable={false}
        {...props}
        // value={caseData.visibilityStatus}
      />
    </>
  );
}

export default CaseTypeFilter;
