import gql from 'graphql-tag';

// #FRAGMENTS

export const GENERAL_RESPONSE_KEYS = gql`
  fragment GeneralResponse on GeneralResponse {
    code
    success
    message
  }
`;

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfileResponse on User {
    id
    uuid
    firstName
    lastName
    fullName
    username
    email
    phoneNumber
    phoneCode
    permissionArray
    licenseId
    smsEnabled
    emailEnabled
    pnEnabled
    avatar
    unreadNotificationsCount
    country {
      name
    }
    role {
      name
    }
    speciality {
      name
      slug
    }
    organizations {
      uuid
      name
      slug
      url
    }
    # profession {
    #   name
    #   slug
    # }
    userTitle {
      name
      slug
    }
    isPhoneVerified
    isEmailVerified
    hasStarted
    needPasswordReset
    about
    association
    licenseFile {
      url
      attachmentType
      fileName
      thumbnail
      fileType
    }
    isExpert
    isLicenseVerified
  }
`;

export const ORGANIZATION_DATA_FRAGMENT = gql`
  fragment OrganizationDataResponse on Organization {
    uuid
    name
    logo
    dark_logo
    caseTypes {
      name
      slug
    }
    caseStatuses {
      name
      slug
    }
    caseFields {
      uuid
      sortOrder
      name
      slug
      type
      isRequired
      fieldType {
        name
        slug
      }
    }
    sharingFields {
      name
      type
      slug
      sortOrder
      isRequired
      uuid
      fieldType {
        name
        slug
      }
    }
    userTitles {
      name
      slug
    }
    specialities {
      name
      slug
    }
    professions {
      name
      slug
    }
    countries {
      name
      phoneCode
      iso3
    }
    visibilityStatuses {
      name
      slug
      description
    }
    label
  }
`;

// # query

export const SIGNIN = gql`
  mutation (
    $email: String!
    $password: String!
    $token: String
    $captcha_type: String
  ) {
    signin: signIn(
      email: $email
      password: $password
      token: $token
      captcha_type: $captcha_type
    ) {
      token
    }
  }
`;

export const AUTO_LOGIN = gql`
  mutation ($token: String!) {
    autoLogin: autoLogin(token: $token) {
      token
    }
  }
`;

export const SIGNOUT = gql`
  mutation {
    signOut
  }
`;

export const ADD_DEVICE_TOKEN = gql`
  mutation ($device_token: String!) {
    addDeviceToken(device_token: $device_token)
  }
`;

// export const SIGNUP = gql`
//   mutation ($first_name: String!, $last_name: String!, $email: String!, $phone: String, $password: String!) {
//     signup: signUp(first_name: $first_name, last_name: $last_name, email: $email, phone: $phone, password: $password) {
//       token
//     }
//   }
// `;

export const ACTIVATE_ACCOUNT = gql`
  mutation ($token: String!) {
    activateAccount: activateAccount(token: $token) {
      token
    }
  }
`;

export const SIGNIN_FROM_HS = gql`
  mutation ($token: String!) {
    signinFromHS: signinFromHS(token: $token) {
      token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($token: String!, $password: String!) {
    resetPassword: resetPassword(token: $token, password: $password) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    forgotPassword: forgotPassword(email: $email) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const RESEND_VERIFICATION_MAIL = gql`
  mutation ($email: String!) {
    resendLink: resendVerificationLink(email: $email) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const SUBSCRIBEME = gql`
  subscription subscribeMe($uuid: String!) {
    subscribeMe(uuid: $uuid) {
      data
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query ($username: String) {
    profile: userProfile(username: $username) {
      ...UserProfileResponse
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;

export const GET_USER_ROLE = gql`
  query {
    getRole {
      id
      name
    }
  }
`;

export const GET_ORGANIZATION_DATA = gql`
  query {
    caseData: getOrganization {
      ...OrganizationDataResponse
    }
  }
  ${ORGANIZATION_DATA_FRAGMENT}
`;

export const GET_INTIALIZATION_DATA = gql`
  query {
    profile: myProfile {
      ...UserProfileResponse
    }
    organizationData: getOrganization {
      ...OrganizationDataResponse
    }
  }
  ${USER_PROFILE_FRAGMENT}
  ${ORGANIZATION_DATA_FRAGMENT}
`;

export const SEND_OTS_LINK = gql`
  mutation ($email: String!) {
    sendOtsLink: sendOneTimeSinginLink(email: $email) {
      ...GeneralResponse
    }
  }
  ${GENERAL_RESPONSE_KEYS}
`;

export const CREATE_ACCOUNT = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $userTitleSlug: String!
    $specialitySlug: String
    $organizationUuid: String!
    $country: String
    # $professionSlug: String
    $licenseId: String
    $licenseFile: [AttachmentInput]!
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      organizationUuid: $organizationUuid
      country: $country
      userTitleSlug: $userTitleSlug
      specialitySlug: $specialitySlug
      # professionSlug: $professionSlug
      licenseId: $licenseId
      licenseFile: $licenseFile
    ) {
      ...GeneralResponse
    }
  }

  ${GENERAL_RESPONSE_KEYS}
`;

export const VERIFY_EMAIL = gql`
  mutation ($token: String!) {
    verifyEmail(token: $token) {
      token
    }
  }
`;

export const GET_TERMS_AND_CONDITIONS = gql`
  query {
    get_tnc: getTermsAndConditions {
      value
    }
  }
`;

export const GET_PRIVACY_POLICY = gql`
  query {
    get_privacy: getPrivacyPolicy {
      value
    }
  }
`;

export const GET_PRIVACY_TERMS = gql`
  query {
    get_tnc: getTermsAndConditions {
      value
    }
    get_privacy: getPrivacyPolicy {
      value
    }
  }
`;
