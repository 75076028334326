import {
  // useLazyQuery,
  useMutation,
} from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { strings } from '../../assets/strings/all';
import SpecialitySelect from '../../components/Common/Dropdown/SpecialitySelect';
import UserTitleSelect from '../../components/Common/Dropdown/UserTitleSelect';
import { FeedbackContext } from '../../containers/Context/FeedbackContext';
import CountryCodeSelect from '../Common/Dropdown/CountryCodeSelect';
import {
  // SEND_EMAIL_VERIFICATION_LINK,
  SEND_OTP,
  VERIFY_OTP_FOR_PHONE_NUMBER,
} from '../User/query';
import { verifyUserInputData } from './functions';
import ProfileFormBotttom from './ProfileFormBotttom';
import ProfilePictureInputWrapper from './ProfilePictureInputWrapper';
import LicenseWrapper from './LicenseWrapper';
import HsImage from '../Common/HsImage';
import DeleteLicenseModal from './DeleteLicenseModal';

function ProfileForm(props) {
  const { user = {}, onSubmit, onRemove, loading, active } = props;
  const { profile, common } = strings;
  const { setFeedback } = useContext(FeedbackContext);
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState(user);
  const [contactData, setContactData] = useState({
    phoneCode: user.phoneCode ? user.phoneCode : '',
    phoneNumber: user.phoneNumber ? user.phoneNumber : '',
    otp: '',
    uuid: user.uuid ? user.uuid : '',
  });
  const [deleteLicense, setDeleteLicense] = useState({
    show: false,
    licenseFile: {},
  });

  const [show, setShow] = useState({
    verification: false,
    disableOtp: user.isPhoneVerified ? true : false,
  });

  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    let otpInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
      if (countdown === 0) {
        clearInterval(otpInterval);
        setShow({
          ...show,
          disableOtp: false,
        });
      }
    }, 1000);
    return () => {
      clearInterval(otpInterval);
    };
  }, [countdown]);

  const updateUserData = (name, value) => {
    const userObj = { ...userData };
    const newUserObj = { ...userObj, [name]: value };

    // if (name === "avatar") {
    //   newUserObj.avatar = value;
    // }

    setUserData(newUserObj);

    const errorObj = verifyUserInputData(
      { ...newUserObj, [name]: value },
      [],
      contactData,
    );
    setErrors(errorObj);
    if (!isEmpty(errorObj)) {
      return false;
    }
  };

  const submitUserData = () => {
    let errorObj = verifyUserInputData(userData, [], contactData);
    const submitData = { ...userData };

    setErrors(errorObj);
    if (!isEmpty(errorObj)) {
      if (
        userData.licenseFile.length === 0 &&
        userData.licenseFileList.length === 0
      ) {
        return false;
      }
    } else {
      let fileObj = [];

      if (userData.licenseFileList && userData.licenseFileList.length > 0) {
        userData.licenseFileList &&
          userData.licenseFileList.map(async (file) => {
            fileObj.push({
              url: file.url,
              attachmentType: file.attachmentType,
              fileName: file.fileName,
            });
          });
      }
      submitData.licenseFile = fileObj;

      if (userData.about) {
        submitData.about = userData.about.trim();
      }

      if (userData.association) {
        submitData.association = userData.association.trim();
      }

      if (userData.licenseId) {
        submitData.licenseId = userData.licenseId.trim();
      }

      onSubmit(submitData);
    }
  };

  //FORM VALIDATION
  const validateForm = async () => {
    const errorsObj = {};
    const { errors: errorString } = profile;

    if (!contactData.phoneCode) {
      errorsObj.phoneCode = errorString.no_phone_code;
    }

    if (!contactData.phoneNumber) {
      errorsObj.phoneNumber = errorString.no_phone_number;
    } else {
      if (contactData.phoneNumber.trim() === '') {
        errorsObj.phoneNumber = errorString.no_phone_number;
      }
      if (contactData.phoneNumber === userData.phoneNumber) {
        errorsObj.phoneNumber = errorString.same_phone_number;
      }
    }

    await setErrors({ ...errorsObj });

    return Object.keys(errorsObj).length === 0;
  };

  const [send_otp] = useMutation(SEND_OTP, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];
      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    onCompleted: (data) => {
      const { send_otp } = data;

      const { success, message } = send_otp;

      if (!success) {
        setFeedback({ message: message, variant: 'danger' });
      } else {
        setCountdown(50);

        setShow({
          ...show,
          verification: true,
          disableOtp: true,
        });
      }
    },
  });

  const [verify_otp] = useMutation(VERIFY_OTP_FOR_PHONE_NUMBER, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        const otp_error = error.errors;
        setFeedback({ message: error.message, variant: 'danger' });
        setErrors({
          ...errors,
          ...otp_error,
        });
      }
    },

    onCompleted: () => {
      setShow({
        ...show,
        verification: false,
        disableOtp: true,
      });
      setContactData({
        ...contactData,
        otp: '',
      });
      setFeedback({ variant: 'success', message: 'Success' });
    },
  });

  const updateContactData = (name, value) => {
    const contData = { ...contactData };
    contData[name] = value;

    setContactData({
      ...contData,
    });

    if (name !== 'otp') {
      setShow({
        ...show,
        verification: false,
        disableOtp: false,
      });
    }

    const errorObj = verifyUserInputData(userData, [], contData);
    setErrors(errorObj);
    if (!isEmpty(errorObj)) {
      return false;
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();

    if (await validateForm()) {
      send_otp({
        variables: {
          phoneCode: contactData.phoneCode,
          phoneNumber: contactData.phoneNumber,
        },
      });
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    if (await validateForm()) {
      verify_otp({
        variables: {
          ...contactData,
        },
      });
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        submitUserData();
      }}
    >
      <div className="profile-top-action">
        <div>
          {active === 'completeProfile' ? (
            <span className="title-complete-profile">{profile.my_account}</span>
          ) : (
            <>
              <Link variant="primary" to={`/profile`}>
                <span className="icon-ico-back back-btn" />
              </Link>
              <span className="title">{profile.my_account}</span>
            </>
          )}
        </div>

        <Col></Col>

        <Col></Col>
      </div>

      <Row>
        <Col md={3}>
          <ProfilePictureInputWrapper
            error={errors['avatar'] ? errors['avatar'] : null}
            userData={userData}
            onChange={(url) => {
              updateUserData('avatar', url);
              // updateUserData('avatar', url);
            }}
          />
        </Col>
        <Col md={9}>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  <div className="contact-number-container">
                    {profile.email_address}
                    {user.isEmailVerified ? (
                      <>
                        <span className="icon-ico-info icon verified" />
                        <p className="verified"> Verified</p>
                      </>
                    ) : (
                      <>
                        <span className="icon-ico-info icon not-verified" />
                        <p className="not-verified">Not Verified</p>
                      </>
                    )}
                  </div>
                </Form.Label>
                <Form.Control
                  maxLength={255}
                  value={userData.email}
                  disabled={true}
                  onChange={(e) => updateUserData('email', e.target.value)}
                />
                {/* {show.emailHelper ? <Form.Text className="muted-text">{profile.email_verification_helper_text}</Form.Text> : null} */}
                {errors['email'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['email']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            {/* {show.disableEmailLink && show.emailHelper ? (
              <Col xs={"auto"} className="otp-btn-container">
                <span className={`text ${show.disableEmailLink ? "disabled" : "enabled"}`} disabled={show.disableEmailLink} onClick={sendEmailVerificationLink}>
                  {show.disableEmailLink && show.emailHelper
                    ? strings.formatString(profile.otp_countdown, {
                        num: emailCountdown,
                      })
                    : profile.send_verification_link}
                </span>
              </Col>
            ) : (
              <></>
            )} */}
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">{profile.title}</Form.Label>
                <UserTitleSelect
                  value={userData.userTitle}
                  onChange={(value) => updateUserData('userTitle', value)}
                />
                {errors['userTitle'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['userTitle']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.first_name}
                </Form.Label>
                <Form.Control
                  maxLength={255}
                  value={userData.firstName}
                  onChange={(e) => updateUserData('firstName', e.target.value)}
                />
                {errors['firstName'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['firstName']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.last_name}
                </Form.Label>
                <Form.Control
                  maxLength={255}
                  value={userData.lastName}
                  onChange={(e) => updateUserData('lastName', e.target.value)}
                />
                {errors['lastName'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['lastName']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.association_optional}
                </Form.Label>
                <Form.Control
                  maxLength={255}
                  value={userData.association}
                  onChange={(e) =>
                    updateUserData('association', e.target.value)
                  }
                />
                {errors['association'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['association']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          {user && user.country && user.country.name && (
            <Row className="my-2 mb-4">
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '.8rem',
                }}
                xs={12}
              >
                <div
                  style={{
                    color: 'gray',
                  }}
                >
                  Country : {user.country.name}
                </div>
                <div>
                  Kindly contact{' '}
                  <span
                    onClick={() => {
                      window.open('mailto:cavi@iqvia.com');
                    }}
                    className="linker"
                  >
                    technical support
                  </span>{' '}
                  to change your country
                </div>
              </Col>
            </Row>
          )}
          <Row className={errors && errors['phoneCode'] ? 'mb-3' : ''}>
            <Col xs={3}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.phone_code}
                </Form.Label>
                <CountryCodeSelect
                  value={contactData.phoneCode}
                  onChange={(val) => {
                    updateContactData('phoneCode', val.phoneCode);
                  }}
                />
                {errors['phoneCode'] && (
                  <Form.Control.Feedback
                    className="signup-feedback"
                    type="invalid"
                  >
                    {errors['phoneCode']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  <div className="contact-number-container">
                    {profile.phone_number}
                    {user.isPhoneVerified ? (
                      <>
                        <span className="icon-ico-info icon verified" />
                        <p className="verified"> Verified</p>
                      </>
                    ) : (
                      <>
                        <span className="icon-ico-info icon not-verified" />
                        <p className="not-verified">Not Verified</p>
                      </>
                    )}
                  </div>
                </Form.Label>
                <Form.Control
                  type="number"
                  value={contactData.phoneNumber}
                  onChange={(e) =>
                    updateContactData('phoneNumber', e.target.value)
                  }
                />

                {errors['phoneNumber'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['phoneNumber']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={'auto'} className="otp-btn-container">
              <span
                className={`text ${show.disableOtp ? 'disabled' : 'enabled'}`}
                disabled={show.disableOtp}
                onClick={sendOtp}
              >
                {show.disableOtp && show.verification
                  ? strings.formatString(profile.otp_countdown, {
                      num: countdown,
                    })
                  : profile.send_verification_code}
              </span>
            </Col>
          </Row>

          {show.verification ? (
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="formLabel">
                    {profile.phone_verification_code}
                  </Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        type="number"
                        value={contactData.otp}
                        onChange={(e) =>
                          updateContactData('otp', e.target.value)
                        }
                      />
                    </Col>
                    <Col xs={'auto'} className="otp-btn-container">
                      <span className="text enabled" onClick={verifyOtp}>
                        {profile.verify}
                      </span>
                    </Col>
                  </Row>

                  <Form.Text className="muted-text">
                    {profile.verification_helper_text}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.license_id_optional}
                </Form.Label>
                <Form.Control
                  maxLength={255}
                  value={userData.licenseId}
                  onChange={(e) => updateUserData('licenseId', e.target.value)}
                />
                {errors['licenseId'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['licenseId']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="formBasicCheckbox">
            <Form.Label>{profile.license_file}</Form.Label>

            {user.licenseFile ? (
              user.licenseFile.map((license) => (
                <div className="license-border-profile">
                  <Row className="license-file">
                    <Col md={3}>
                      <div className="extension-wrapper left">
                        <>
                          <HsImage
                            style={{ height: '70px' }}
                            src={license.thumbnail}
                          />
                        </>
                      </div>
                    </Col>

                    <Col md={7}>
                      <div className="right">
                        <div
                          style={{ maxWidth: '7rem' }}
                          className="muted-text oneLineDes"
                        >
                          {license.fileName}
                        </div>
                        <div>
                          <a
                            className="muted-text"
                            href={license.url}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {common.view}
                          </a>
                        </div>
                      </div>
                    </Col>

                    <Col xs="auto">
                      <div className="remove-attachment">
                        <span
                          className="icon-ico-remove"
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            fontSize: '.8rem',
                            position: 'relative',
                            top: '-1px',
                          }}
                          onClick={() => {
                            setDeleteLicense({
                              show: true,
                              licenseFile: license,
                            });
                          }}
                        >
                          x
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <> </>
            )}

            <div className="custom-control-box">
              <LicenseWrapper
                userData={user}
                licenseFile={userData.licenseFileList}
                onChange={(url) => updateUserData('licenseFileList', url)}
              />
            </div>

            {errors['licenseFile'] && (
              <Form.Control.Feedback className="signup-feedback">
                {errors['licenseFile']}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.speciality}
                </Form.Label>
                <SpecialitySelect
                  value={userData.speciality}
                  onChange={(value) => updateUserData('speciality', value)}
                />
                {errors['speciality'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['speciality']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.profession}
                </Form.Label>
                <ProfessionSelect
                  value={userData.profession}
                  onChange={(value) => updateUserData('profession', value)}
                />
                {errors['profession'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['profession']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row> */}

          <Row>
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="formLabel">
                  {profile.about_optional}
                </Form.Label>
                <Form.Control
                  maxLength={5000}
                  className="justify-textarea"
                  as="textarea"
                  rows={6}
                  value={userData.about}
                  onChange={(e) => updateUserData('about', e.target.value)}
                />
                {errors['about'] && (
                  <Form.Control.Feedback type="invalid">
                    {errors['about']}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <div style={{ width: '100%' }} className="profile-top-action">
          <div />
          <Col></Col>

          <Col xs="auto">
            {active === 'completeProfile' ? (
              <></>
            ) : (
              <>
                <Button variant="secondary" as={Link} to={`/profile/`}>
                  {profile.cancel}
                </Button>
              </>
            )}
          </Col>

          <Col xs="auto">
            <ProfileFormBotttom loading={loading} errors={errors} />
          </Col>
        </div>
      </Row>
      <DeleteLicenseModal
        show={deleteLicense.show}
        licenseFile={deleteLicense.licenseFile}
        closeModal={() =>
          setDeleteLicense({
            show: false,
            licenseFile: {},
          })
        }
        onRemove={onRemove}
        user={userData}
      />
    </Form>
  );
}

export default ProfileForm;
