export const strings = {
  network_with_hcp:
    'Network with Health Care Professionals (HCPs) across the globe',
  webinar_by_kol: 'Attend Webinars conducted by Key Opinion Leaders (KOLs)',
  discuss_cases: 'Discuss medical cases in a secure environment',
  signup_cme: 'Sign up for Continuous Medical Education (CME)',
  hcp_space_help_text:
    'A Holistic Networking & Learning Platform for Healthcare Professionals',
  download_app: 'Download the app',
  download_app_desc:
    'Grab {name} App from Google Play or App Store and start to learn from healthcare professionals ',
  hcp_testi: 'What health Care professionals have to say about {short_name}',
  hcp_testi_title:
    'What health Care professionals have to say about {short_name}',
  hcp_testi_text_1:
    'Collaboration is the sine quo non of good medical practice and service',
  hcp_testi_name_1: 'Dr. Olufemi Olugbile',
  hcp_testi_work_1: '',
  hcp_testi_text_2:
    'Many sectors in Africa have adopted technology but Health sector is crawling when it comes to digital. One way to change this narrative is with the HCP platform.',
  hcp_testi_name_2: 'Dr. Noimot Balogun',
  hcp_testi_work_2: '',
  hcp_testi_text_3:
    'Beauty of this is everyone in the group can pick and choose what will help them improve',
  hcp_testi_name_3: 'Dr. Arinola Joda',
  hcp_testi_work_3: '',
  hcp_testi_text_4:
    'I cannot exaggerate the benefit of bringing expertise together and providing holistic support and solutions',
  hcp_testi_name_4: 'Dr. Abimbola Adebakin',
  hcp_testi_work_4: '',
  hcp_testi_text_5:
    'A unique space for multidisciplinary working, networking and continuous Medical education',
  hcp_testi_name_5: 'Dr. Albert Akpalu',
  hcp_testi_work_5: '',
  hcp_testi_text_6:
    "HCP Space is for the smart HCP. It's all about knowledge acquisition and practice enhancement through networking. Get on board now!!",
  hcp_testi_name_6: 'Dr. Oscar Bruce',
  hcp_testi_work_6: '',
  hcp_testi_text_7:
    'An excellent opportunity for medical community to learn, collaborate and inspire !!!',
  hcp_testi_name_7: 'Dr. Dennis Addo',
  hcp_testi_work_7: '',
  medical_association_signed_up: 'Medical Associations signed up with',

  who_title: 'Who is {short_name} for?',
  who_title_help_text: 'Any Healthcare Professional who is interested to',
  num_hcp: '20,000+ HCPs',
  num_hcp_help_text:
    'In more than 50 specialties from Africa, India & the Middle East',

  footer_logo_help_text: 'Copyright © {year} HCP Space',
  support: 'Support',
  privacy_policy: 'Privacy Policy',
  tnc: 'Terms & Conditions',
  android: 'Android',
  ios: 'iOS',
  landing_title: '{name}',
  cokie_notice: 'Notice: ',
  cookie_message: `This website or its third-party tools use cookies,
  which are necessary to its functioning and required to achieve
  the purposes illustrated in the `,
  cookie_policy: 'Cookie Policy',
  cookie_close_message: `, If you want to know more or withdraw your consent to all or
  some of the cookies, please refer to the Cookie Policy. By
  closing this banner, you agree to the use of cookies`,
  blogs: 'Blogs',
  blogs_serach: 'Search Blogs',
  login: 'Login',
  login_request: 'Please login to HCPSpace',
  close: 'Close',
  tnc_term_condition1:
    'THIS IS A LEGALLY BINDING AGREEMENT between IQVIA (“IQVIA”, “we” or “us“), and you. BY CLICKING “I AGREE,” OR BY OTHERWISE SIGNING UP OR FOR AN ACCOUNT, OR BY ACCESSING OR USING THE IQVIA HCP Space (“Services”), YOU AGREE TO BE BOUND BY THESE TERMS OF USE AND DISCLAIMERS (“Terms”) AND ARE ENTERING INTO A FULLY BINDING AGREEMENT.',
  tnc_term_condition2:
    'NO HCP is eligible/permitted to be a member of HCP Space or even use the HCP Space if you are a resident of any of the following countries, Iran, Syria, Cuba, Crimea (Ukraine) and North Korea.',
  tnc_term_condition3:
    'Please read these Terms carefully, and do not sign up for an account or use the Services if you are unwilling or unable to be bound by these Terms. You and we are collectively referred to as the “Parties.”',
  purpose_of_services: 'Purpose of Services',
  tnc_term_condition4:
    'The purpose of the Services is to (i) collaborate, store, access, publish and use information as it pertains to medical information, diagnosis, prognosis, innovative methods in treatment, knowledge sharing, learning recommendations about patients by you (“Health Information”), (ii) to promote exchange of career options, information of upcoming medical conferences, events and Healthcare news; and (iii) to facilitate economic opportunities by networking with other HCPs (“Healthcare Professionals”, which term refers to doctors, nurses, pharmacists, medical technicians etc.), pharmaceutical companies, hospitals, pharmacies etc. You authorize IQVIA to use and disclose the Information as follows, subject to the recipient’s agreement to comply with applicable laws and regulations relating to the use and disclosure of health information.',
  licence: 'License',
  licence_grant: 'License Grant.',
  tnc_term_condition5:
    'IQVIA grants to you a non-exclusive, non-transferable license, without rights to sublicense, to use the Services for yourself, which shall be provided in accordance with these Terms.',
  restrictions: 'Restrictions',
  tnc_term_condition6:
    'The Services may not be copied, modified or used other than as permitted by these Terms. You will not, and will not permit or authorize third parties to: (i) rent, lease, or otherwise permit third parties to access or use the Services or Documentation; (ii) use the Services to provide services to third parties (e.g. as a service bureau); (iii) reverse engineer, reverse assemble or otherwise attempt to gain access to the source code of all or any portion of the Services; (iv) circumvent or disable any security or other technological features or measures of the Services; or (v) violate the additional terms of use as per attached Annexure A.',
  userid: 'User IDs',
  userid_condition:
    "User IDs will be generated on successful completion of the sign-up process. You will be provided with 'read-only' access to view medical information until your medical credentials as requested are certified and / or validated successfully.",
  passwords: 'Passwords',
  password_condition:
    'You will adopt and maintain reasonable and appropriate security precautions to protect your password, to prevent its disclosure to or use by any unauthorized persons.',
  personal_data: 'Your Personal Data',
  personal_data_condition:
    'You agree to the terms of the IQVIA Online Privacy Policy that sets out our online information practices and governs how your personal information is collected and used by us.',
  confidentiality: 'Confidentiality',
  confidentiality_condition:
    "Neither Party shall communicate, disclose, or provide to any third party any information provided by one party to the other in connection with these Terms which is identified at the time of its disclosure as confidential or which, by the nature or type of information, reasonably should be regarded as confidential information (collectively “Confidential Information”), except as expressly provided in these Terms or otherwise expressly authorized by the disclosing party in writing. You acknowledge and agree that the Services are confidential to IQVIA, and that any disclosure of the Services shall be subject to IQVIA’s prior written consent and these Terms. Each Party agrees to treat the Confidential Information of the other as confidential, using the same degree of care used by the receiving party to protect the receiving party's own confidential information, but in any event not less than a reasonable degree of care. This confidentiality provision does not apply to any information: (i) available in the public domain through no fault of the receiving party; (ii) independently developed by or on behalf of the receiving party without reference to the Confidential Information; or (iii) disclosed to the receiving party without restriction by a third party having a bona fide right to do so and not having an obligation of confidence with respect to such information. Nothing in these Terms shall restrict a Party from disclosing any Confidential Information as required by law; provided in each case that the receiving party, to the extent permitted by such law, shall timely inform the disclosing party so that the disclosing party may seek a protective order. This confidentiality provision shall continue until a period of five (5) years following the time of return or deletion of the Confidential Information.",
  intellectual_property: 'Intellectual Property',
  intellectual_property_condition:
    'IQVIA Materials, as defined below, shall remain the sole and exclusive property of IQVIA. IQVIA does not grant, and you do not receive, any other interest in any Services, except for those rights explicitly granted in these Terms. "IQVIA Materials" shall mean the Services and all data, databases, data models, methodologies, know-how, ideas, concepts and discoveries and other materials compiled, developed, obtained or generated by IQVIA in its performance of the Services, and any derivatives, modifications or enhancements of the foregoing. All proprietary notices incorporated in, marked on, or affixed to the Services or other Confidential Information by IQVIA or its suppliers (including third parties) shall be replicated by you on all copies of all or any part of the Services and shall not be altered, removed or obliterated. Third Party Materials, as defined below, shall remain the sole and exclusive property of such third party providing the material. Third party does not grant, and you do not receive, any other interest in any information / intellectual property rights (IPR), except for those rights explicitly granted in these Terms. "Third Party Materials" shall mean the information and services and all content, databases, know-how, ideas, concepts and discoveries and other materials compiled, developed, obtained or generated by third parties on HCP Space.',
  verification: 'Verification',
  verification_condition:
    'You shall at all times while using the Services have the necessary licenses in place to operate as a HCP and use the Services. Upon IQVIA’s request, from time to time, you shall certify compliance with this provision to IQVIA in writing. IQVIA may, but shall not be obligated to, verify such compliance.',
  terms_of_use: 'Terms of Use',
  relationship: 'Relationship',
  relationship_condition:
    'In the future, IQVIA may / will separately enter into agreements with HCPs governing their participation in the Services. IQVIA may / will collect, use and disclose Health Information from these HCPs consistent with applicable law and with the IQVIA Privacy Policy (available at https://www.iqvia.com/about-us/privacy). You confirm that you have read this Privacy Policy. You recognize and acknowledge that in the course of operating the Services, IQVIA will enter into such agreements with participating individuals and that IQVIA may use and disclose information consistent with the IQVIA Privacy Policy.',
  compliance: 'Compliance',
  compliance_condition:
    'You are responsible for ensuring that your use of personal Information is consistent with any applicable legal restrictions in your jurisdiction of use, including consents for use of your information which may include your identifiable information.',
  misuse_by_user: 'Responsibility for Misuse by Users.',
  misuse_by_user_condition:
    'You acknowledge that in granting access to the Services for the purposes set forth in these Terms, IQVIA will rely on your assurances as to (i) your identity and credentials; (ii) the purposes for which you are accessing the system; and (iii) the nature and extent of the information to which you will have access. You acknowledge that, while the Services will contain certain technical safeguards against misuse of the Services, it will rely to a substantial extent on the representations and undertakings of all users of the Services, including you. You agree that IQVIA will not be responsible for any unlawful access to or use of the personal Information provided by you or any user which results from you or the user’s misrepresentation to IQVIA, access by unauthorized persons who default IQVIA’s safeguards against such access or you or user’s breach of these Terms. Furthermore, you warrant that you have full rights to supply to IQVIA any electronic file, database, data and/or other information transferred to IQVIA in connection with the use of HCP Space.',
  advertisements: 'Advertisements.',
  advertisements_condition:
    'IQVIA may place advertisements concerning products, events, conferences and services of third parties throughout the Services, so that users see them when they use the Services. IQVIA may receive remuneration from the suppliers of these products and services for placing their advertisements. IQVIA may use computerized processes to tailor the advertisements to users or their use of the system. However, except as expressly permitted by these Terms or by our policies and procedures, unless IQVIA obtains your consent, IQVIA will not disclose to any third party any information that identifies you to enable the third party to market products or services to you directly.',
  clinical_support: 'Clinical Support Information.',
  clinical_support_condition:
    'HCP Space may contain information to assist you in clinical decision-making. This may include information about digital health, general health-care related information and resources. HCP Space may also provide forums for you and other users to exchange information. You agree that the information and materials available through the Services are for informational and educational purposes only and are not intended to constitute professional advice, diagnosis or treatment, or to substitute for your professional judgment. Information may be placed in the Services by IQVIA and by third parties beyond IQVIA’s control. IQVIA is not responsible for the accuracy or completeness of information available from or through the Services. You assume full risk and responsibility for the use of information you or patients obtain from or through the Services, and neither IQVIA nor any of IQVIA’ licensors or data providers are responsible or liable for any claim, loss, or liability arising from use of the information. IQVIA does not recommend or endorse any provider of health care or health-related products, items or services, and the appearance of materials in the Services relating to any such products, items or services is not an endorsement or recommendation of them. You will review the definitions, functionality, and limitations of the Services, and make an independent determination of their suitability for your use.',
  regulatory_disclosures: 'Regulatory Disclosures.',
  regulatory_disclosures_condition:
    'To the extent you are required under applicable law to report adverse reactions, medication errors or other similar events to a local medical regulator or authority, you understand and agree that seeking to report, detail or otherwise disclose such events on HCP Space will not discharge this obligation in any way. Any such regulatory disclosures must be made directly to your applicable local medical regulator or authority.',
  your_content: 'Your Content.',
  your_content_condition:
    'You retain the rights to your copyrighted content and information that you submit or post to HCP Space ("user content"), except as described herein. By submitting user content to HCP Space, you grant us a royalty-free, perpetual, irrevocable, non-exclusive, unrestricted, worldwide license to reproduce, prepare derivative works, distribute copies, perform, or publicly display your user content in any medium and for any purpose, including commercial purposes, and to authorize others to do so. You agree that you have the right to submit anything you post on HCP Space, and that your user content does not violate the copyright, trademark, trade secret or any other personal or proprietary right of any other party.',
  disclaimers: 'Disclaimers',
  disclaimers_condition:
    'The Information and Services are provided on an “as is” basis. IQVIA does not warrant that the Information Services shall meet the present or future needs or objectives of HCPs and HCPs assume sole responsibility for the use, selection and suitability of the Information and Services to its needs and objectives. In addition, IQVIA shall not be liable for any expressions of opinion, evaluations or forecasts contained within the Services. Where the Services are based on information provided by HCPs or a third party, IQVIA shall be entitled to assume that such information is accurate. Other than those warranties which are implied by applicable law and are incapable of exclusion, restriction or modification, IQVIA and its suppliers and licensors disclaim all warranties, whether expressed or implied, including any warranty as to the quality, accuracy, and suitability of the information provided by the Services for any purpose.',
  obligations: 'Your Obligations Regarding Safeguards',
  safeguards: 'Security and Safeguards.',
  safeguards_condition:
    'You will implement and maintain appropriate administrative, physical and technical safeguards to protect information within the Services and in connection with your use of the Services. Such safeguards shall comply with federal, national, and any other local law requirements (as applicable). You will maintain appropriate security with regard to all personnel, systems, and administrative processes used by you to transmit, store and process electronic health information through the use of the Services.',
  breaches: 'Security Breaches.',
  breaches_condition:
    'You shall have responsibility for evaluation, investigation and notification (if any) in connection with any breach or suspected breach of the security of the Services of which you become aware. You will take such action to mitigate the breach, suspected breach, or unauthorized use or disclosure of information within or obtained from the Services. Where appropriate in the context of the breach or suspected breach, you will notify IQVIA of any such breach that relates to the operation of the Services, so that IQVIA can evaluate mitigation efforts related to the Services, if any.',
  termination: 'Termination of Use of the Services',
  termination_condition:
    'You may terminate your use of the Service at any time and for whatever reason. You are not obligated to advise IQVIA of such termination. IQVIA retains the right to block your access to the Services and discontinue your use of the Services, at any time and for any reason IQVIA deems appropriate, at its sole and absolute discretion.',
  warranty: 'Warranty',
  warranty_condition:
    'IQVIA PROVIDES THE SERVICES AND CONTENT INCLUDED THEREIN FOR USE ON AN "AS IS" AND "AS AVAILABLE" BASIS. THEY CANNOT BE CUSTOMIZED TO FULFILL THE NEEDS OF EACH AND EVERY USER. OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY APPLICABLE LAW AND ARE INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION, WE DISCLAIM ALL WARRANTIES AND REPRESENTATIONS, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, FEATURES, QUALITY, NON-INFRINGEMENT, TITLE, COMPATIBILITY, PERFORMANCE, SECURITY OR ACCURACY.',
  warrenty_confirmation:
    'You agree and acknowledge that you assume full, exclusive and sole responsibility for the use of the Services and that the use of the Services is entirely at your own risk.',
  limitation_liability: 'Limitation of Liability',
  limitation_liability_condition:
    'IQVIA, INCLUDING ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS AND AGENTS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY OTHER DAMAGE, AND LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES AND PAYMENTS, EITHER IN TORT, CONTRACTUAL, OR IN ANY OTHER FORM OF LIABILITY, ARISING FROM, OR IN CONNECTION WITH THE USE OF, OR THE INABILITY TO USE THE SERVICE, OR FROM ANY FAILURE, ERROR, OR BREAKDOWN IN THE FUNCTION OF THE SERVICES, OR FROM ANY FAULT, OR ERROR MADE BY OUR STAFF OR ANYONE ACTING ON ITS BEHALF, OR FROM YOUR RELIANCE ON THE CONTENT OF THE SERVICE, INCLUDING, WITHOUT LIMITATION, CONTENT ORIGINATING FROM THIRD PARTIES, OR FROM ANY COMMUNICATION WITH THE SERVICE, OR WITH OTHER USERS ON OR THROUGH THE SERVICE, OR FROM ANY DENIAL OR CANCELLATION OF YOUR USER ACCOUNT, OR FROM RETENTION, DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF YOUR CONTENT ON THE SERVICE.',
  modification: 'Modifications to the Services',
  modification_condition:
    "IQVIA may, either partially or in its entirety and without being obligated to provide prior notice – modify, adapt or change the Service's features, the user interface and design, the extent and availability of the contents in the Services and any other aspect related to the Services. You will have no claim, complaint or demand against IQVIA for applying such changes or for failures incidental to such changes.",
  modification_terms: 'Modifications of These Terms',
  modification_terms_condition:
    "IQVIA may modify these Terms from time to time. If fundamental changes are introduced, a notice will be posted in the updated version of the Services and on the Service's home page on the site. Your continued use of the Services after the Terms have been modified signifies your assent to the updated Terms. If you do not agree to the updated Terms or to any term within them, you must discontinue all further use of the Services.",
  miscellaneous: 'Miscellaneous',
  assignment: 'Assignment.',
  assignment_condition:
    "Neither these Terms nor any license hereunder may be assigned or transferred by you, including to your affiliates and subsidiaries (whether by operation of law or change in the majority of the equity ownership or otherwise) without IQVIA' prior written consent.",
  subcontractors: 'Subcontractors.',
  subcontractors_condition:
    'IQVIA may use subcontractors to perform any portion of its obligations hereunder. IQVIA shall ensure that any subcontractor that has access to any Confidential Information has entered into a written agreement at least as protective of your Confidential Information as this Agreement. IQVIA shall remain primarily liable for the performance of its subcontractors in connection with this Agreement.',
  fees: 'Fees.',
  fees_condition:
    'The current use of the Services is without charge for HCPs. However, IQVIA is at liberty to charge a fee for premium services in the future from HCPs.',
  relief: 'Injunctive Relief.',
  relief_condition:
    "Each Party's obligations under the Confidential Information Section hereof are of a unique character and each agrees that any breach may result in irreparable and continuing damage to the other Party for which there will be no adequate remedy in damages. In the event of such a breach, the damaged party will be entitled to injunctive relief and/or a decree for specific performance and such damages and further relief as may be proper.",
  laws: 'Compliance with Laws.',
  laws_condition:
    'These Terms are subject to any governmental laws, orders or other restrictions on the export of software and related information that may be imposed by governmental authorities. You shall comply with any governmental laws, orders or other restrictions on the export and re-export of software (including technical data and any related information and documentation) which may be imposed from time to time.',
  successors: 'Successors and Assigns.',
  successors_condition:
    'These Terms and the rights, interests and obligations hereunder shall be binding upon, inure to the benefit of and be enforceable by the Parties and their respective successors and permitted assigns.',
  governing_laws: 'Governing Law.',
  governing_laws_condition:
    'These Terms shall be governed by and construed under the laws of Switzerland.',
  notices: 'Notices.',
  notices_condition:
    'All notices, requests, demands, and determinations under these Terms (other than routine operational communications), shall be in writing and shall be deemed duly given (i) when delivered by hand, (ii) one (1) business day after being given to an express courier with a reliable system for tracking delivery, or (iii) six (6) days after the day of mailing, registered or certified mail, return receipt requested, postage prepaid, and addressed as specified in each SOW. In addition to the person(s) designated in each Supplement to receive notice on behalf of IQVIA, a copy of each notice shall be sent to: IMS Health Incorporated, Attention: Vice President, Associate General Counsel, One IMS Drive, Plymouth Meeting PA 19462.',
  entire_agreement: 'Entire Agreement.',
  entire_agreement_condition:
    'These Terms, together with the Annexures and incorporated material, constitute the entire agreement of the Parties and supersede all previous and contemporaneous communications, representations, understandings or agreements related to the subject matter hereof.',
  annexure1: 'Annexure: Additional Terms of Using IQVIA HCP Space',
  agree_that: 'You agree that you will NOT:',
  annex1:
    'Act in an unlawful or unprofessional manner in connection with our Services, other Users including being dishonest, abusive or discriminatory.',
  annex2:
    'Post defamatory obscene, hateful, inaccurate, political, shocking, threatening or otherwise inappropriate content or airing personal grievances or disputes.',
  annex3:
    'Misrepresent your likeness, your identity or employment history or a head-shot photo for your profile.',
  annex4: 'Create a false identity on HCP Space.',
  annex5:
    'Create a Member profile for anyone other than yourself (a real person).',
  annex6: 'Invite HCPs that you do not know to join your network.',
  annex7: 'Do not use or attempt to use another HCPs account',
  annex8:
    'Send or post only user based content or post any unsolicited or unauthorized advertising on HCP Space',
  annex9:
    'Develop, support or use software, devices, scripts, robots, or any other means or processes (including crawlers, browser plugins and add-ons, or any other technology or manual work) to scrape the Services or otherwise copy profiles and other data from the Services',
  annex10:
    'Bypass or circumvent any access controls or Service use limits (such as caps on keyword searches)',
  annex11:
    'Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of the person providing the information',
  annex12:
    'Solicit email addresses or other personal information from Members you don’t know, without authorization',
  annex13:
    'Use, disclose or distribute any data obtained in violation of this policy',
  annex14:
    'Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer))',
  annex15:
    'Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets, or other proprietary rights.',
  annex16:
    'Violate the intellectual property or other rights of HCP Space, including, without limitation, (i) copying or distributing our learning videos or other materials (ii) copying or distributing our technology, unless it is released under open source licenses; (iii) using the word “HCP Space” or our logos in any business name, email, or URL.',
  annex17:
    'Post anything that contains software viruses, worms, or any other harmful code with an intent to damage HCP Space or any functionality.',
  annex18:
    'Manipulate identifiers in order to disguise the origin of any message or post transmitted through the Services.',
  annex19:
    'Create or operate a pyramid scheme, fraud or other similar practice.',
  annex20:
    'Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source.',
  annex21:
    'Imply or state that you are affiliated with or endorsed by HCP Space without our express consent (e.g., representing yourself as an accredited HCP Space trainer or agent or employee).',
  annex22:
    'Rent, lease, loan, trade, sell/re-sell access to the Services or related data.',
  annex23:
    'Sell, sponsor, or otherwise monetize any Service without our consent.',
  annex24: 'Deep-link to our Services for any purpose not envisaged herein.',
  annex25:
    'Remove any copyright, trademark or other proprietary rights notices contained in or on our Service.',
  annex26:
    'Remove, cover or obscure any advertisement included on the Services.',
  annex27:
    'Create profiles or provide content that promotes escort services or prostitution.Create profiles or provide content that promotes escort services or prostitution.',
  annex28:
    'Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages.',
  annex29:
    'Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services.',
  annex30: 'Overlaying or otherwise modifying the Services or their appearance',
  annex31:
    'Access the Services except through the interfaces expressly provided by HCP Space, such as its mobile applications',
  annex32: 'Use a Service for tasks that it is not intended for',
  annex33:
    'Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms) and/or',
  annex34:
    'Violate any terms concerning a specific Service that are provided when you sign up for or start using such Service.',
};
