import { useLazyQuery } from '@apollo/client';
import React, { useState, createContext, useEffect, useContext } from 'react';
import { setCookie } from '../../common/functions';
import { GET_ORGANIZATION_DATA } from '../Auth/query';
import { FeedbackContext } from './FeedbackContext';

export const OrganizationContext = createContext();

const OrganizationContextProvider = (props) => {
  const [organization, setOrganization] = useState({});
  const { setFeedback } = useContext(FeedbackContext);
  const [getOrg] = useLazyQuery(GET_ORGANIZATION_DATA, {
    onCompleted: (data) => {
      setOrganization(data.caseData);
    },
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];
      setFeedback({ message: error.message, variant: 'danger' });
    },
  });
  // const { view } = props;

  const getCSRF = async () => {
    await fetch(`${process.env.REACT_APP_CSRF_URL}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((response) => {
        // setSessionCookie('csrf-token', response.csrf_token);
        setCookie('csrf-token', response.csrf_token);
        getOrg();
      })
      .catch((error) => {
        setFeedback({ message: error.message, variant: 'danger' });
      });
  };

  useEffect(() => {
    getCSRF();
  }, []);

  return (
    <OrganizationContext.Provider value={{ organization, setOrganization }}>
      {props.children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationContextProvider;
