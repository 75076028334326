import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { strings } from '../../assets/strings/all';
import { UserContext } from '../../containers/Context/UserContext';
import { DELETE_COMMENT } from './query';

function DeleteCommentModal(props) {
  const { setFeedback } = useContext(UserContext);

  const { post } = strings;

  const {
    closeModal,
    isReply,
    show,
    updateData,
    variables,
    fetchMoreCommentsAfterDelete,
  } = props;

  const [deleteComment, { loading }] = useMutation(DELETE_COMMENT, {
    onError: ({ networkError, graphQLErrors }) => {
      const error = graphQLErrors && graphQLErrors[0];

      if (error) {
        setFeedback({ message: error.message, variant: 'danger' });
      }
    },
    update: async (cache, { data }) => {
      window.location.reload();
      let comments = cache.readQuery({
        query: updateData.query,
        variables: updateData.variables,
      });

      comments = comments.commentList;

      let commentList = comments.comments;

      commentList = commentList.filter((comment) => {
        return variables.uuid !== comment.uuid;
      });

      const newCommentList = {
        ...comments,
        comments: commentList,
      };
      cache.writeQuery({
        query: updateData.query,
        variables: updateData.variables,
        data: { commentList: newCommentList },
      });
      if (commentList.length === 0) {
        return;
      }
      fetchMoreCommentsAfterDelete();

      closeModal();
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={closeModal}
        className="confirmation-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isReply ? post.delete_replyy : post.delete_comment}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{post.delete_comment_confirmation}</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeModal}>
            {post.cancel}
          </Button>

          <Button
            variant="danger"
            onClick={() => {
              deleteComment({ variables });
            }}
            disabled={loading}
          >
            {isReply ? post.delete_replyy : post.delete_comment}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteCommentModal;
