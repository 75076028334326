import { getUserToken, getOfficeUuid } from './functions';

export const BASE_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'http://localhost:5021/api';
export const APP_ENV = process.env.REACT_APP_ENV || 'local';
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN || '.cpd.local';
export const HCP_INTEGRATION = process.env.HCP_INTEGRATION || true;
// export const BASE_URL = 'http://10.205.56.103:5000/api';
export const SUBSCRIPTION_URL = process.env.REACT_APP_SUBSCRIPTION_URL
  ? process.env.REACT_APP_SUBSCRIPTION_URL
  : 'ws://localhost:5021/subscriptions';
export const ACCESS_TOKEN = () => getUserToken();
export const OFFICE_UUID = () => getOfficeUuid();
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL
  ? process.env.REACT_APP_UPLOAD_URL
  : 'https://qa-api.hcpspace.app/api/upload';

export const UPLOAD_TOKEN = () => '';
