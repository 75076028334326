import React, { useState, createContext } from 'react';

export const NotificationCountContext = createContext();

const NotificationCountContextProvider = (props) => {
  const [notificationsCount, setNotificationsCount] = useState();

  return (
    <NotificationCountContext.Provider
      value={{ notificationsCount, setNotificationsCount }}
    >
      {props.children}
    </NotificationCountContext.Provider>
  );
};

export default NotificationCountContextProvider;
