import React from 'react';
import Form from 'react-bootstrap/Form';
import {
  getNameFromField,
  getPlaceholderFromField,
} from '../../common/functions';
import HsDateTime from '../Common/HsDatePicker';
import RichTextEditor from '../Editor/Editor';

function HsFormInput(props) {
  const { field, placeholder, name } = props;
  const { fieldType } = field;

  const intialValue = props.value;

  const renderInput = (param) => {
    let comp = <></>;
    switch (param) {
      case 'textarea':
        comp = (
          <RichTextEditor
            onChange={(value, e) => {
              props.onChange(name, value);
            }}
            initValue={intialValue}
            height={props.height || 300}
            disabled={props.disabled}
          />
        );
        break;
      case 'input':
      case 'select':
        comp = (
          <Form.Control
            as={fieldType.slug}
            type="text"
            placeholder={field ? getPlaceholderFromField(field) : placeholder}
            name={getNameFromField(field)}
            {...props}
            onChange={(e) => {
              props.onChange(e.target.name, e.target.value);
            }}
          />
        );
        break;
      case 'datepicker':
        comp = (
          <HsDateTime
            as={fieldType.slug}
            type="text"
            format="dd/MM/yyyy"
            placeholder={field ? getPlaceholderFromField(field) : placeholder}
            name={getNameFromField(field)}
            {...props}
            onChange={(date) => props.onChange(date)}
          />
        );
        break;
      default:
        comp = (
          <Form.Control
            as={fieldType.slug}
            type="text"
            placeholder={field ? getPlaceholderFromField(field) : placeholder}
            name={getNameFromField(field)}
            {...props}
          />
        );
    }
    return comp;
  };

  return renderInput(fieldType.slug);
}

export default HsFormInput;
