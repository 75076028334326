import React from 'react';
import Form from 'react-bootstrap/Form';
import { getTitleFromField } from '../../common/functions';

function HsFormLabel(props) {
  const { field, title } = props;

  return (
    <Form.Label className='formLabel'>
      {field ? getTitleFromField(field) : title}
    </Form.Label>
  );
}

export default HsFormLabel;
