import React, { useState } from 'react';
import { generateUUID } from '../../common/functions';
import HsDropzone from '../../components/Form/HsDropzone';
import { getFileTypeFromUrl } from '../CaseForm/components/functions';
import HsImage from '../Common/HsImage';
import { ReactComponent as closeSvg } from '../../svg/close.svg';

function LicenseWrapper(props) {
  const { viewOnly } = props;
  const [attachments, setAttachments] = useState([]);

  const updateFileObj = (files) => {
    const keys = Object.keys(files);
    const licenseAttachments = keys.map((key) => {
      const file = files[key];

      return {
        url: file.url || file.file.preview,
        uuid: generateUUID(),
        attachmentType: 'license',
        fileName: file.file.name,
      };
    });

    setAttachments(licenseAttachments);
    props.onChange(licenseAttachments);
  };

  const removeFile = (fileUuid) => {
    const removeArray = attachments.filter((obj) => {
      return obj.uuid !== fileUuid;
    });

    setAttachments(removeArray);
    props.onChange(removeArray);
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <div>
          {attachments &&
            attachments.map((fileObj) => (
              <>
                <div className="license-wrapper-container">
                  <div className="remove-attachment">
                    <span
                      className="icon-ico-remove"
                      style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '.8rem',
                        position: 'relative',
                        top: '-1px',
                      }}
                      onClick={() => {
                        removeFile(fileObj.uuid);
                      }}
                    >
                      x
                    </span>
                  </div>
                  <div className="left">
                    {getFileTypeFromUrl(fileObj.fileName) === 'pdf' ? (
                      <div className="pdf">PDF</div>
                    ) : (
                      <>
                        <HsImage className="image" src={fileObj.url} />
                      </>
                    )}
                  </div>
                  <div className="right">
                    <div className="muted-text">{fileObj.fileName}</div>
                  </div>
                </div>
              </>
            ))}
        </div>

        {!viewOnly ? (
          <HsDropzone
            {...props}
            type="small"
            maxFiles={5}
            data={{ attachments }}
            removedFiles={[]}
            updateFileObj={updateFileObj}
            addToRemovedFiles={removeFile}
            acceptedFileFormats="image/jpeg,image/jpg,application/pdf,image/png"
            // removedFiles={removedFiles}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default LicenseWrapper;
