import React from 'react';
import Image from 'react-bootstrap/Image';
import { generateUUID } from '../../common/functions';
import HsDropzone from '../../components/Form/HsDropzone';

function ProfilePictureInputWrapper(props) {
  const { userData, viewOnly, error } = props;

  const updateFileObj = (files) => {
    const keys = Object.keys(files);
    const attachments = keys.map((key) => {
      const file = files[key];
      return {
        url: file.url || file.file.preview,
        attachmentType: 'media',
        file,
        uuid: generateUUID(),
      };
    });

    props.onChange(
      attachments.length > 0 ? attachments[attachments.length - 1].url : null,
    );
  };

  const removeFileObj = (FileUuid) => {
    props.onChange(null);
  };

  return (
    <>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Image
          src={userData.avatar}
          fluid={true}
          style={{ width: '100%', marginBottom: '1rem' }}
        />
        {error ? (
          <p
            style={{ margin: '1rem', fontSize: '12.5px' }}
            className="signup-feedback"
          >
            {error}
          </p>
        ) : (
          <></>
        )}
        {!viewOnly ? (
          <HsDropzone
            {...props}
            type="profilePicture"
            maxFiles={1}
            data={{ attachments: [] }}
            removedFiles={[]}
            updateFileObj={updateFileObj}
            acceptedFileFormats="image/jpeg,image/jpg,image/png"
            addToRemovedFiles={removeFileObj}
            // removedFiles={removedFiles}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ProfilePictureInputWrapper;
