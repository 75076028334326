import React from 'react';
import { components } from 'react-select';
const Menu = (props) => {
  const newProps = {
    ...props,
    className: `hs-dropdown-menu`,
  };
  return (
    <>
      <components.Menu {...newProps}>{props.children}</components.Menu>
    </>
  );
};

export default Menu;
