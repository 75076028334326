import React, { useContext, useEffect } from 'react';
import { OrganizationContext } from '../../../containers/Context/OrganizationContext';
import HsSelect from '../../Form/HsSelect';

function GenderSelect(props) {
  const { organization } = useContext(OrganizationContext);
  const { onChange } = props;
  const { visibilityStatuses = [] } = organization || {};

  const formattedLabel = (option) => {
    const { name, description } = option;
    return (
      <div className="select-option-wrapper">
        <div className="select-option-name">{name}</div>
        <div className="select-option-description">{description}</div>
      </div>
    );
  };

  useEffect(() => {
    if (props.value && !props.value.name) {
      onChange({ name: props.value });
    }
  }, []);
  return (
    <>
      <HsSelect
        label="Gender"
        placeholder="Gender"
        options={[
          {
            label: 'Gender',
            options: [
              {
                name: 'NA',
                slug: 'NA',
              },
              {
                name: 'Male',
                slug: 'male',
              },
              {
                name: 'Female',
                slug: 'female',
              },
            ],
          },
        ]}
        getOptionLabel={(option) => formattedLabel(option)}
        defaultValue={props.value}
        onChange={onChange}
        isClearable={false}
        {...props}
        // value={caseData.visibilityStatus}
      />
    </>
  );
}

export default GenderSelect;
