import React from 'react';
import { Alert, Row } from 'react-bootstrap';
import { strings } from '../../assets/strings/all';

const EmailVerificationErrorComponent = (props) => {
  const { email, sendEmailVerificationLink, data } = props;

  const onSubmit = () => {
    sendEmailVerificationLink({
      variables: {
        email: email,
      },
    });
  };

  return (
    <>
      <Row>
        <Alert variant="warning" className="email-alert">
          {data ? (
            <>
              {strings.formatString(strings.auth.email_verification_sent, {
                email: email,
              })}
            </>
          ) : (
            <>
              {strings.auth.email_verification_sent_2},{' '}
              <span onClick={onSubmit} color="link" className="signin-link">
                {strings.auth.click_here}
              </span>{' '}
              {strings.auth.send_mail}
            </>
          )}
        </Alert>
      </Row>
    </>
  );
};

export default EmailVerificationErrorComponent;
