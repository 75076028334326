import React, { useState } from 'react';
import { strings } from '../../assets/strings/all';
import HsImage from '../../components/Common/HsImage';

const Footer = (props) => {
  const { common } = strings;
  const [more, setMore] = useState(false);
  return (
    <div className="layout-footer fixed-bottom">
      <div className="text">
        {common.footer_text.slice(0, more ? common.footer_text.length + 1 : 45)}{' '}
        <span
          style={{
            color: 'cyan',
            cursor: 'pointer',
          }}
          onClick={() => {
            setMore(!more);
          }}
        >
          {more ? 'Read less' : 'Read more..'}
        </span>
      </div>
      {/* {more && (
        <div style={{ display: 'flex', justifyContent: 'end' }} className="p-3">
          <HsImage
            style={{ maxWidth: 80 }}
            className="header-logo"
            src={'/api/static/pfizer-w-logo.png'}
          />
        </div>
      )} */}
    </div>
  );
};

export default Footer;
