export const strings = {
  home_title: 'Home',
  write_here: 'Write here...',
  about: 'About me',
  about_connections: 'Let your connections know more about your personality',
  add: 'Add',
  education: 'Education',
  education_details: ' Let your connections know where you studied',
  experience: 'Experience',
  experience_details: 'Let your connections know about what you do',
  interest: 'Interests',
  interest_details:
    'Tell us about your interests to help us show relevant information',
  medical_audit: 'Need to log Medical Audit?',
  new_log: 'Add New Log',
  all_log: 'See All Logs',
  profile: 'Profile',
  boost_profile: 'Boost your profile',
  sub_speciality: 'Sub-speciality',
  speciality: 'Speciality',
  optional: '(Optional)',
  add_speciality:
    'Add your sub-speciality to help us show you relevant information',
  profile_picture: 'Profile Picture',
  set_profile: 'Set your profile picture now and get more connection request',
  upload: 'Upload',
  view_profile: 'Profile Views',
  post: 'Posts',
  follower: 'Followers',
  cancel: 'Cancel',
  save: 'Save',
  poll_uuid: 'Poll',
  errors: {
    biography: 'About me is required',
    speciality: 'Speciality is required',
    biography_max_length: 'About me cannot be more than 200 characters',
  },
  complete: 'Complete',
};
